import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useCurrentOrg } from '~/features/orgnisations/hooks/useCurrentOrg'
import { listUserNotifications, markUserNotificationAsRead, UserNotification } from '../service'
import { Box, Button, Flex, Grid, Popover, ScrollArea, Separator, Text } from '@radix-ui/themes'
import { LuBell } from 'react-icons/lu'
import { FaCircle } from 'react-icons/fa'
import { EmptyState } from '~/components/EmptyState'
import { DateTime } from 'luxon'
import { useNavigate } from '@tanstack/react-router'
import NotificationLevelIcon from './NotificationLevelIcon'
import HumanTimestamp from '~/components/HumanTimestamp'

interface NotificationItemProps {
  notification: UserNotification
  onClick: (id: string) => void
}

const NotificationItem = ({ notification, onClick }: NotificationItemProps) => {
  return (
    <Flex
      gap={'2'}
      p="3"
      onClick={() => onClick(notification.id)}
      className="cursor-pointer hover:bg-gray-2 rounded-md"
    >
      <Box>
        <FaCircle color={notification.read ? 'transparent' : 'var(--red-8)'} size={7} className={'mt-2'} />
      </Box>
      <Flex direction={'column'} gap="1" className="grow">
        <Grid className="grid-cols-[12px_auto_1fr] items-baseline gap-2">
          <NotificationLevelIcon size={12} level={notification.level} />
          <Text className="line-clamp-1">{notification.title}</Text>
          <HumanTimestamp
            timestamp={DateTime.fromISO(notification.created_at)}
            size={'1'}
            className="ml-auto text-right"
            color="gray"
          />
        </Grid>
        <Text size={'1'} color="gray">
          {notification.message}
        </Text>
      </Flex>
    </Flex>
  )
}

const NotificationsPopover = () => {
  const { t } = useTranslation('sidenav')
  const { id: orgID } = useCurrentOrg()
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const { data = [] } = useQuery({
    queryKey: ['notifications', orgID],
    queryFn: () => listUserNotifications({ orgID }),
    staleTime: 1000 * 60 * 2, // 5 minutes
    retry: false,
    refetchInterval: 1000 * 60 // 1 minute
  })

  const { mutate: markAsRead } = useMutation({
    mutationFn: (notificationID: string) => markUserNotificationAsRead({ userNotificationID: notificationID }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['notifications', orgID]
      })
    }
  })

  const hasUnred = data.filter((notification) => !notification.read).length > 0

  const onNotificationClick = (notificationID: string) => {
    const userNotification = data.find((n) => n.id === notificationID)
    if (!userNotification) {
      return
    }
    if (!userNotification.read) {
      markAsRead(notificationID)
    }
    if (!userNotification.siteID) {
      return
    }
    const path = userNotification.path || '/alerting'
    navigate({ to: '/sites/$siteID' + path, params: { siteID: userNotification.siteID } })
  }

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Button variant="ghost" color="gray">
          <Box position={'relative'}>
            {hasUnred && <FaCircle color="var(--red-8)" size={6} className="absolute top-[-2px] right-[-6px]" />}
            <LuBell size={16} />
          </Box>
        </Button>
      </Popover.Trigger>
      <Popover.Content width={'400px'} side="left" className="ml-3">
        <ScrollArea className="max-h-[70vh]">
          <Box pr="2">
            <Text mb="2" size={'4'} className="p-3" weight={'bold'}>
              {t('notifications.title')}
            </Text>
            <Separator size={'4'} my="3" />
            {data.length > 0 ? (
              <>
                <Flex direction={'column'} gap="1">
                  {data.map((notification) => (
                    <NotificationItem
                      key={notification.id}
                      notification={notification}
                      onClick={onNotificationClick}
                    />
                  ))}
                </Flex>
              </>
            ) : (
              <EmptyState
                title={t('notifications.emptyState.title')}
                description={t('notifications.emptyState.description')}
              />
            )}
          </Box>
        </ScrollArea>
      </Popover.Content>
    </Popover.Root>
  )
}

export { NotificationsPopover }
