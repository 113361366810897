import { getWillieSupabaseClient } from '../../utils'
import { WaterStationSchema } from '../mapping/schemas'
import { TablesInsert } from '@willie/supabase'
import { CreateSiteSchema, SiteSchema } from './schemas'

export const createSite = async (data: CreateSiteSchema): Promise<void> => {
  const supabase = getWillieSupabaseClient()
  const payload: TablesInsert<'sites'> = {
    name: data.name,
    description: data.description,
    address: data.address,
    org_id: data.orgID
  }
  await supabase.from('sites').insert(payload)
}

export const updateSite = async (data: SiteSchema): Promise<void> => {
  const supabase = getWillieSupabaseClient()
  const payload: TablesInsert<'sites'> = {
    id: data.id,
    name: data.name,
    description: data.description,
    address: data.address,
    org_id: data.orgID,
    default_cost_per_m3: data.cost
  }
  await supabase.from('sites').update(payload).eq('id', data.id)
}

export const getSite = async ({ siteId }: { siteId: string }) => {
  const supabase = getWillieSupabaseClient()
  const { data, error } = await supabase
    .from('sites')
    .select(
      `*,
      stations:water_stations (*)`
    )
    .eq('id', siteId)
    .single()
  if (error || !data) {
    console.log('Error fetching site', error)
    throw new Error(`Site with id ${siteId} not found`)
  }

  const parsedStations = data.stations
    .map((station) => {
      try {
        return WaterStationSchema.parse({ ...station, cost: station.cost_per_m3 })
      } catch (error) {
        console.error('Error parsing station', station.id, error)
        return null
      }
    })
    .filter((station) => !!station)

  return { ...data, cost: data.default_cost_per_m3, stations: parsedStations }
}

export type SiteWithStations = Awaited<ReturnType<typeof getSite>>
export type SiteSummary = Awaited<ReturnType<typeof listSites>>[number]

export const listSites = async ({ orgId }: { orgId: string }) => {
  const supabase = getWillieSupabaseClient()
  const { data, error } = await supabase
    .from('sites')
    .select(
      `*,
      stations:water_stations (*)
    `
    )
    .eq('org_id', orgId)
  if (error) {
    console.error('Error fetching sites', error)
  }
  return data || []
}

export const deleteSite = async (siteId: string): Promise<void> => {
  const supabase = getWillieSupabaseClient()
  await supabase.from('sites').delete().eq('id', siteId)
}
