import * as Collapsible from '@radix-ui/react-collapsible'
import {
  CreatePhysicalMeterSchema,
  createPhysicalMeterSchema,
  PhysicalMeterSchema,
  WaterStation
} from '../schemas'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  Box,
  Button,
  Callout,
  Code,
  Flex,
  Select,
  Spinner,
  Strong,
  Table,
  Text,
  TextField
} from '@radix-ui/themes'
import {
  createOrUpdatePhysicalMeter,
  getUnassignedDevices,
  listDatapointsPayloadByDeviceID
} from '~/features/meters/service'
import { useCurrentOrg } from '~/features/orgnisations/hooks/useCurrentOrg'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { capitalize } from 'lodash'
import { InfoCircledIcon } from '@radix-ui/react-icons'
import { updateWaterStation } from '../service'
import { useTranslation } from 'react-i18next'

type StationMeterFormProps = {
  station: WaterStation
  onCancel: () => void
  meter?: PhysicalMeterSchema
}

const DEVICE_MAKERS = {
  shayp: {
    dataMapping: 'payload -> consumption',
    readingMethod: 'incremental',
    unit: 'l'
  },
  adeunis: {
    dataMapping: 'payload -> decoded -> counterValues -> 0',
    readingMethod: 'cumulative',
    unit: 'm3'
  }
} as const satisfies Record<string, Pick<PhysicalMeterSchema, 'dataMapping' | 'unit' | 'readingMethod'>>

const MeterForm = ({ station, onCancel, meter }: StationMeterFormProps) => {
  const { t } = useTranslation('sites')
  const queryClient = useQueryClient()
  const org = useCurrentOrg()

  const { data: devices = [], isLoading } = useQuery({
    queryKey: ['orphan-devices', org.id],
    queryFn: () => getUnassignedDevices({ orgID: org.id })
  })

  const { mutate: createMeter, isPending } = useMutation({
    mutationFn: async (data: CreatePhysicalMeterSchema) => {
      // Once refactored, this will be a single call to createOrUpdatePhysicalMeter as we will inverse the foreign key
      // relationship between water station and physical meter. Meter will be datasoure and it will have a foreign key to station
      const meter = await createOrUpdatePhysicalMeter(data)
      await updateWaterStation({ ...station, meter_id: meter.id })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate({ queryKey }) {
          const isStationKey = queryKey[0] === 'station' && queryKey[1] === station.id
          const isSiteKey = queryKey[0] === 'sites' && queryKey[1] === station.site_id
          const isMeterKey = queryKey[0] === 'physical_meter'
          const isDataKey = queryKey[0] === 'data-lake'

          return isStationKey || isSiteKey || isMeterKey || isDataKey
        }
      })
    },
    onError: (error) => {
      console.error('Error creating meter', error)
    }
  })

  const {
    handleSubmit,
    setValue,
    formState: { isValid, isDirty },
    watch,
    control
  } = useForm({
    defaultValues: meter ?? {
      name: station.name,
      description: '',
      readingMethod: undefined,
      dataMapping: undefined,
      deviceID: '',
      unit: undefined,
      orgID: org.id,
      maker: undefined
    },

    resolver: zodResolver(createPhysicalMeterSchema)
  })

  const deviceID = watch('deviceID')
  const mappingQuery = watch('dataMapping')

  const {
    data: dataPoints = [],
    error: dataPointsError,
    isLoading: dataPointsLoading
  } = useQuery({
    queryKey: ['data-lake', { deviceID, mappingQuery }],
    retry: false,
    queryFn: () =>
      listDatapointsPayloadByDeviceID({
        orgID: org.id,
        deviceID,
        mappingQuery: mappingQuery || undefined,
        limit: 20
      })
  })

  const onSubmit = handleSubmit((data) => createMeter(data))

  if (isLoading) {
    return (
      <Flex align={'center'} justify={'center'}>
        <Spinner />
      </Flex>
    )
  }

  if (!isLoading && devices.length === 0) {
    return (
      <Callout.Root color="bronze" variant="surface" my={'8'}>
        <Callout.Text>
          <Flex align={'center'} gap="2">
            <div>
              <Flex align={'center'} gap="2">
                <InfoCircledIcon />
                <Text size={'3'} weight={'medium'}>
                  {t('stations.meter.form.noDevicesAvailable')}
                </Text>
              </Flex>
              <Text>{t('stations.meter.form.noDevicesExplanation')}</Text>
            </div>
            <Button size={'2'} variant="soft" onClick={onCancel}>
              {t('stations.meter.form.cancel')}
            </Button>
          </Flex>
        </Callout.Text>
      </Callout.Root>
    )
  }

  return (
    <Flex direction={'column'} gap={'5'}>
      <form onSubmit={onSubmit}>
        <Flex gap={'5'} mt="3">
          <Flex gap="4" direction={'column'} flexGrow={'1'} maxWidth={'50%'}>
            <Flex gap="1" direction={'column'}>
              <Text as="label" htmlFor="deviceId" size="2" weight={'medium'}>
                {t('stations.meter.form.deviceId')}
              </Text>
              <Controller
                control={control}
                name="deviceID"
                render={({ field }) => (
                  <Select.Root
                    {...field}
                    onValueChange={(v) => field.onChange(v)}
                    disabled={isLoading || devices.length === 0 || !!meter}
                  >
                    <Select.Trigger
                      placeholder={
                        devices.length === 0
                          ? t('stations.meter.form.noDevicesAvailable')
                          : t('stations.meter.form.selectDevice')
                      }
                    />
                    <Select.Content variant="soft" position="item-aligned">
                      {devices.map((d) => (
                        <Select.Item value={d} key={d}>
                          <Flex gap="2" align={'center'}>
                            {d}
                          </Flex>
                        </Select.Item>
                      ))}
                    </Select.Content>
                  </Select.Root>
                )}
              />
            </Flex>
            <Flex gap="1" direction={'column'}>
              <Text as="label" htmlFor="dataMapping" size="2" weight={'medium'}>
                {t('stations.meter.form.deviceMaker')}
              </Text>
              <Select.Root
                onValueChange={(v: keyof typeof DEVICE_MAKERS) => {
                  setValue('maker', v, { shouldValidate: true })
                  setValue('readingMethod', DEVICE_MAKERS[v].readingMethod, { shouldValidate: true })
                  setValue('dataMapping', DEVICE_MAKERS[v].dataMapping, { shouldValidate: true })
                  setValue('unit', DEVICE_MAKERS[v].unit, { shouldValidate: true })
                }}
                disabled={isLoading || devices.length === 0 || !!meter}
              >
                <Select.Trigger
                  placeholder={
                    devices.length === 0
                      ? t('stations.meter.form.noDevicesAvailable')
                      : t('stations.meter.form.selectDevice')
                  }
                />
                <Select.Content variant="soft" position="item-aligned">
                  {Object.keys(DEVICE_MAKERS).map((d) => (
                    <Select.Item value={d} key={d}>
                      {capitalize(d)}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Flex>
          </Flex>
          <Flex direction={'column'} gap="4" flexGrow={'1'} maxWidth={'50%'}>
            <Flex gap="1" direction={'column'}>
              <Text size={'2'} as="label" htmlFor="readingMethod" weight={'medium'} color="gray">
                {t('stations.meter.form.readingMethod')}
              </Text>
              <TextField.Root
                variant="surface"
                placeholder={t('stations.meter.form.readingMethodPlaceholder')}
                readOnly
                value={capitalize(watch('readingMethod'))}
              />
            </Flex>
            <Flex gap="1" direction={'column'}>
              <Text size={'2'} as="label" htmlFor="unit" weight={'medium'} color="gray">
                {t('stations.meter.form.measurementUnit')}
              </Text>
              <TextField.Root
                variant="surface"
                placeholder={t('stations.meter.form.measurementUnitPlaceholder')}
                readOnly
                value={watch('unit')}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex justify={'end'} mt={'5'} align={'center'} gap={'5'}>
          <Button size={'3'} variant={'ghost'} color="gray" onClick={onCancel} className="py-2">
            {t('stations.meter.form.cancel')}
          </Button>
          <Button size={'3'} type="submit" loading={isPending} disabled={!isDirty || !isValid}>
            {t('stations.meter.form.addMeter')}
          </Button>
        </Flex>
      </form>
      <Collapsible.Root>
        <Flex justify={'center'}>
          <Collapsible.Trigger disabled={!deviceID}>
            <Button size="2" variant="ghost" disabled={!deviceID}>
              {t('stations.meter.form.viewLatestDatapoints')}
            </Button>
          </Collapsible.Trigger>
        </Flex>
        <Collapsible.Content asChild>
          <Box>
            {!dataPointsLoading && !!dataPointsError && (
              <Callout.Root color="red">
                <Callout.Text>
                  <Text as="p">
                    {dataPointsError.message} - {t('stations.meter.form.datapoints.errorMessage')}
                  </Text>
                </Callout.Text>
              </Callout.Root>
            )}
            {!dataPointsError && (
              <Table.Root>
                <Table.Header>
                  <Table.Row>
                    <Table.ColumnHeaderCell>
                      <Strong>{t('stations.meter.form.datapoints.timestamp')}</Strong>
                    </Table.ColumnHeaderCell>
                    <Table.ColumnHeaderCell>
                      <Strong>{t('stations.meter.form.datapoints.value')}</Strong>
                    </Table.ColumnHeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {dataPoints.map((dp) => (
                    <Table.Row key={`${JSON.stringify(dp)}`}>
                      <Table.Cell>{dp.dateTime?.toRelative()}</Table.Cell>
                      <Table.Cell maxWidth={'500px'}>
                        <Code color="gray">{JSON.stringify(dp.value)}</Code>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Root>
            )}
          </Box>
        </Collapsible.Content>
      </Collapsible.Root>
    </Flex>
  )
}

export { MeterForm }
