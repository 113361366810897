import { Box, Button, Flex, Text, TextField } from '@radix-ui/themes'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImageUpload } from '~/components/ImageUpload'
import { ActionablePageLayout } from '~/components/ActionablePageLayout'
import { SectionCard } from '~/components/SectionCard'
import { updateOrg } from '~/features/orgnisations/service'
import { Tables } from '~/types'
import { toast } from 'sonner'

const pickOrgData = (org: Tables<'org'>) => _.pick(org, ['name', 'icon_url'])

const Page = () => {
  const { t } = useTranslation('settings')
  const { org, queryClient } = Route.useRouteContext()
  const [data, setData] = useState(pickOrgData(org))
  const router = useRouter()

  const isDirty = !_.isEqual(data, pickOrgData(org))

  const { mutate, isPending } = useMutation({
    mutationFn: () => updateOrg(org.id, data),
    onSuccess: () => {
      toast.success(t('general.orgUpdated'))
      router
        .invalidate()
        .then(() => queryClient.invalidateQueries())
        .then(() => router.invalidate())
    }
  })
  return (
    <ActionablePageLayout title={t('pageTitle')}>
      <Flex p={'9'} m={'auto'} gap="5" direction={'column'} className="w-[90%] xl:w-[70%] max-w-[1200px]">
        <SectionCard title={t('general.sectionTitle')}>
          <Box>
            <Text size={'2'}>{t('general.orgLogo')}</Text>
            <ImageUpload
              size={100}
              imageUrl={org.icon_url}
              uploadKey={`${org.id}/org-icon`}
              onUploadSuccess={(icon_url) => {
                setData((d) => ({ ...d, icon_url: icon_url }))
              }}
            />
            <Text size={'1'} color="gray">
              {t('general.logoHint')}
            </Text>
          </Box>
          <Box>
            <Text size={'2'}>{t('general.orgName')}</Text>
            <TextField.Root
              my="3"
              value={data.name}
              onChange={(e) => setData((d) => ({ ...d, name: e.target.value }))}
            />
            <Button disabled={!isDirty} onClick={() => mutate()} loading={isPending}>
              {t('general.saveButton')}
            </Button>
          </Box>
        </SectionCard>
      </Flex>
    </ActionablePageLayout>
  )
}

export const Route = createFileRoute('/settings')({
  component: Page
})

// TODO once we fix members :

// const MemberListItem = ({ member }: { member: OrgMemberWithProfile }) => {
//   const { t } = useTranslation(['settings'])

//   return (
//     <Flex align={'center'} justify={'between'}>
//       <Flex gap="3" align={'center'}>
//         <Avatar
//           variant="solid"
//           radius="full"
//           src={member.profile.picture_url ?? ''}
//           fallback={member.profile.email.slice(0, 2)}
//           size={'1'}
//         />
//         <Flex direction={'column'}>
//           {member.profile.full_name && (
//             <Text size={'1'} className="font-medium">
//               {member.profile.full_name}
//             </Text>
//           )}
//           <Text color="gray" size={'1'}>
//             {member.profile.email}
//           </Text>
//         </Flex>
//       </Flex>
//       <Text size={'2'}>{capitalize(member.role!)}</Text>
//       <DropdownMenu.Root>
//         <DropdownMenu.Trigger>
//           <IconButton color="gray" variant="ghost" size={'1'}>
//             <HiOutlineDotsHorizontal size={22} />
//           </IconButton>
//         </DropdownMenu.Trigger>
//         <DropdownMenu.Content variant="soft" color="gray">
//           <DropdownMenu.Item disabled>{t('members.memberActions.changeRole')}</DropdownMenu.Item>
//           <DropdownMenu.Item color="red">{t('members.memberActions.removeMember')}</DropdownMenu.Item>
//           <DropdownMenu.Group></DropdownMenu.Group>
//         </DropdownMenu.Content>
//       </DropdownMenu.Root>
//     </Flex>
//   )
// }

// const { data: members, error } = useSuspenseQuery({
//   queryKey: ['org-members', org.id],
//   queryFn: () => listOrgMembers(org.id)
// })

/* <SectionCard title={t('members.sectionTitle')}>
          <Box>
            <Text size={'1'} color="gray" as="p">
              {t('members.description')}
            </Text>
            <Flex mt={'5'}>
              <TextField.Root placeholder={t('members.filterPlaceholder')} className="w-[50%]" />
              <Button className="ml-auto" disabled>
                {t('members.addMemberButton')}
              </Button>
            </Flex>

            {error ? (
              <Text color="red">{t('members.errorFetchingMembers')}</Text>
            ) : (
              <Flex direction={'column'} mt="2">
                {members.map((m) => (
                  <MemberListItem member={m} key={m.id} />
                ))}
              </Flex>
            )}
          </Box>
        </SectionCard> */
