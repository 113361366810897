import { Button, Card, Flex, Spinner } from '@radix-ui/themes'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { listSiteBudgetItems } from '../service'
import { EmptyState } from '~/components/EmptyState'
import { CreateBudgetItemForm, UpdateBudgetItemForm } from './BuggetItemForm'
import _ from 'lodash'
import { BudgetItemGrid } from './BudgetItemGrid'

type Props = {
  siteID: string
  siteCost: number | null
}

const ObjectiveSection = ({ siteID, siteCost }: Props) => {
  const { t } = useTranslation('sites')
  const [isAddingBudgetItem, setIsAdding] = useState(false)

  const { data = [], isPending } = useQuery({
    queryKey: ['site-budget-objectives', siteID],
    queryFn: () => listSiteBudgetItems({ siteID, type: 'objective' }),
    throwOnError: true
  })

  const budgetItems = _.orderBy(data, 'endDate', 'asc')

  return (
    <Flex gap="2" direction={'column'}>
      {isPending && (
        <Card>
          <Flex justify={'center'} align={'center'} className="h-20">
            <Spinner />
          </Flex>
        </Card>
      )}
      {budgetItems.length === 0 && !isAddingBudgetItem && !isPending ? (
        <Card className="p-5">
          <EmptyState
            title={t('budget.objectiveSection.emptyState.title')}
            description={t('budget.objectiveSection.emptyState.description')}
            primaryAction={{
              action: () => setIsAdding(true),
              label: t('budget.objectiveSection.emptyState.addObjective')
            }}
          />
        </Card>
      ) : (
        <BudgetItemGrid>
          {budgetItems.map((item) => (
            <UpdateBudgetItemForm key={item.id} budgetItem={item} siteID={siteID} siteCost={siteCost} />
          ))}
          {isAddingBudgetItem ? (
            <CreateBudgetItemForm
              budgetItem={null}
              siteID={siteID}
              onCancel={() => setIsAdding(false)}
              onDone={() => setIsAdding(false)}
              type="objective"
              siteCost={siteCost}
            />
          ) : (
            <Flex className="col-[1/-1] h-10" px="3" pb="3" justify={'center'}>
              <Button variant="ghost" onClick={() => setIsAdding(true)} className="font-medium">
                {t('budget.objectiveSection.addObjective')}
              </Button>
            </Flex>
          )}
        </BudgetItemGrid>
      )}
    </Flex>
  )
}

export { ObjectiveSection }
