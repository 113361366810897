import { useEffect, useState } from 'react'
import { OrgContext } from '../hooks/orgCtx'
import { useQueryClient, useSuspenseQuery } from '@tanstack/react-query'
import { listUserOrgs } from '../service'
import { Session } from '@supabase/supabase-js'
import { Org } from '~/types'
import { useLocalStorage } from 'react-use'

type Props = {
  userSession: Session
  children: React.ReactNode
}

export const OrgProvider = ({ children, userSession }: Props) => {
  const [currentOrg, setCurrentOrg] = useState<Org | null>(null)
  const [lsOrg, setValue] = useLocalStorage('org', currentOrg?.id)
  const queryClient = useQueryClient()

  const { data: orgs } = useSuspenseQuery({
    queryKey: ['orgs', userSession.user.id],
    queryFn: () => listUserOrgs(userSession.user.id)
  })

  // not sure we need this
  useEffect(() => {
    if (!userSession) {
      return
    }
    queryClient.invalidateQueries({ queryKey: ['orgs'] })
  }, [userSession])

  useEffect(() => {
    const org = orgs?.find((o) => o.id === lsOrg) ?? orgs[0]
    setCurrentOrg(org)
  }, [orgs])

  useEffect(() => {
    if (!currentOrg) {
      return
    }
    setValue(currentOrg.id)
    queryClient.resetQueries({
      predicate: (query) => !(query.queryKey?.[0]?.toString() ?? '').includes('org')
    })
  }, [currentOrg])

  if (!currentOrg) {
    return null
  }
  return <OrgContext.Provider value={{ currentOrg, setCurrentOrg }}>{children}</OrgContext.Provider>
}
