import { Callout, Flex, Text } from '@radix-ui/themes'
import { NotificationLevel, SiteNotification as SiteNotificationSchema } from '../service'
import NotificationLevelIcon from './NotificationLevelIcon'
import { CalloutProps } from '@tremor/react'
import { DateTime } from 'luxon'
import HumanTimestamp from '~/components/HumanTimestamp'

type Props = {
  siteNotification: SiteNotificationSchema
}

const COLORS: Record<NotificationLevel, { bg: CalloutProps['color']; icon: string }> = {
  good: {
    bg: 'green',
    icon: 'green-9'
  },
  critical: {
    bg: 'red',
    icon: 'red-11'
  },
  warning: {
    bg: 'amber',
    icon: 'amber-8'
  },
  info: {
    bg: 'blue',
    icon: 'blue-10'
  },
  amazing: {
    bg: 'purple',
    icon: 'purple-9'
  }
}

const SiteNotification = ({ siteNotification }: Props) => {
  const level = siteNotification.level
  const color = COLORS[level]
  return (
    <Callout.Root color={color.bg} variant="soft" className="relative">
      <Callout.Icon className="mt-1">
        <NotificationLevelIcon level={level} size={18} color={`var(--${color.icon})`} />
      </Callout.Icon>
      <Callout.Text>
        <Flex direction={'column'}>
          <Text size={'3'} weight={'medium'} className="text-gray-12">
            {siteNotification.title}
          </Text>
          <Text size={'2'} className="text-gray-11">
            {siteNotification.message}
          </Text>
        </Flex>
        <HumanTimestamp
          timestamp={DateTime.fromISO(siteNotification.created_at)}
          size={'1'}
          className="absolute right-4 top-5 text-gray-11"
        />
      </Callout.Text>
    </Callout.Root>
  )
}

export default SiteNotification
