import { Outlet, createRootRouteWithContext } from '@tanstack/react-router'
import { Session } from '@supabase/supabase-js'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { Box } from '@radix-ui/themes'

import { QueryClient, useQuery } from '@tanstack/react-query'
import { Tables } from '../types'
import { listUserOrgs } from '~/features/orgnisations/service'
import { SideNav } from '~/features/navigation/SideNav'

export type RootRouterContext = {
  session: Session
  queryClient: QueryClient
  org: Tables<'org'>
}

const Root = () => {
  const { session, org } = Route.useRouteContext() as RootRouterContext

  const { data: orgs = [] } = useQuery({
    queryKey: ['orgs', session.user.id],
    queryFn: () => listUserOrgs(session.user.id)
  })

  return (
    <>
      <SideNav user={session.user} org={org} orgs={orgs} />
      <Box flexGrow={'1'}>
        <Outlet />
      </Box>
      {import.meta.env.DEV && <TanStackRouterDevtools position="bottom-right" />}
    </>
  )
}

export const Route = createRootRouteWithContext<RootRouterContext>()({
  component: Root
})
