import { Tables } from '~/types'
import { getWillieSupabaseClient } from '../../utils'

export const listUserOrgs = async (userId: string) => {
  const supabase = getWillieSupabaseClient()

  // List with * is fine because or RLS
  const { data: memberOrgsRes, error: memberError } = await supabase.from('org').select('*')

  if (memberError) {
    throw new Error(`Error fetching member organizations: ${memberError.message}`)
  }

  // Get orgs where user is the owner
  const { data: ownedOrgs, error: ownerError } = await supabase.from('org').select('*').eq('owner_id', userId)

  if (ownerError) {
    throw new Error(`Error fetching owned organizations: ${ownerError.message}`)
  }

  // Combine and deduplicate orgs
  const allOrgs = [...(memberOrgsRes?.flat() ?? []), ...(ownedOrgs ?? [])]
  const uniqueOrgs = Array.from(new Map(allOrgs.map((org) => [org.id, org])).values())

  return uniqueOrgs
}

type UpdateOrgPayload = Partial<Pick<Tables<'org'>, 'name' | 'owner_id' | 'icon_url'>>

export const updateOrg = async (orgId: string, data: UpdateOrgPayload) => {
  const supabase = getWillieSupabaseClient()
  const { error } = await supabase.from('org').update(data).eq('id', orgId)

  if (error) {
    console.error('Error updating org', error)
  }
}

export const listOrgMembers = async (orgId: string) => {
  const supabase = getWillieSupabaseClient()
  const { data, error } = await supabase
    .from('org_member')
    .select(
      `
      id,
      role,
      created_at,
      profile:user_profiles!user_id (
        email,
        full_name,
        picture_url
      )
    `
    )
    .eq('org_id', orgId)

  if (error) {
    throw new Error(`Error fetching organization members: ${error.message}`)
  }

  return data
}

export type OrgMemberWithProfile = Awaited<ReturnType<typeof listOrgMembers>>[number]

// TODO implement thius
// export const inviteOrgMembers = async (orgId: string, emails: string[]) => {
//   // TODO implement this
//   // create users + send invite emails
//   // create org members
// }
