import { z } from 'zod'

export type BudgetItemSchema = z.infer<typeof budgetItemSchema>
export const budgetItemSchema = z.object({
  id: z.string().uuid(),
  siteID: z.string().uuid(),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  consumption: z.number(),
  cost: z.number(),
  type: z.enum(['historical', 'objective'])
})

export type HistoricalInvoiceSchema = z.infer<typeof historicalInvoiceSchema>
export const historicalInvoiceSchema = budgetItemSchema
  .omit({ type: true })
  .extend({ type: z.literal('historical') })

export type ObjectiveBudgetItemSchema = z.infer<typeof objectiveBudgetItemSchema>
export const objectiveBudgetItemSchema = budgetItemSchema
  .omit({ type: true })
  .extend({ type: z.literal('objective') })

export type CreateBudgetItemSchema = z.infer<typeof createBudgetItemSchema>
export const createBudgetItemSchema = budgetItemSchema.omit({ id: true })

export type BudgetItemType = BudgetItemSchema['type']
