import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useMutation, useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { deleteWaterStation, getStation, updateWaterStation } from '~/features/mapping/service'
import { UpdateStationForm } from '~/features/mapping/components/StationForm'
import { getSite } from '~/features/sites/service'
import {
  Badge,
  Button,
  Callout,
  Code,
  DataList,
  Flex,
  HoverCard,
  Spinner,
  Strong,
  Table,
  Text
} from '@radix-ui/themes'
import { SectionCard } from '~/components/SectionCard'
import { getMeterById, listDatapointsPayloadByDeviceID } from '~/features/meters/service'
import { EmptyState } from '~/components/EmptyState'
import { Suspense, useState } from 'react'
import { MeterForm } from '~/features/mapping/components/MeterForm'
import { MdOutlineWifiTethering } from 'react-icons/md'
import { LuArchiveRestore } from 'react-icons/lu'
import { toast } from 'sonner'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useTRPC } from '~/utils/trpc'
import { ConsumptionAndLeaksSection } from '~/features/leaks/components/ConsumptionAndLeakSection'

type MeterFormProps = {
  meterId: number
}

const MeterPreview = ({ meterId }: MeterFormProps) => {
  const { t } = useTranslation('sites')
  const [nDataPoints, setNDataPoints] = useState(5)
  const { data: meter } = useSuspenseQuery({
    queryKey: ['meter', meterId],
    queryFn: () => getMeterById({ meterId })
  })

  const {
    data: dataPoints = [],
    error: dataPointsError,
    isLoading: dataPointsLoading
  } = useQuery({
    queryKey: ['data-lake', { deviceId: meter.deviceID, mappingQuery: meter.dataMapping }],
    retry: false,
    queryFn: () =>
      listDatapointsPayloadByDeviceID({
        orgID: meter.orgID,
        deviceID: meter.deviceID,
        mappingQuery: meter.dataMapping || undefined,
        limit: 100
      })
  })

  if (dataPointsLoading || !meter) {
    return (
      <Flex align={'center'} justify={'center'}>
        <Spinner />
      </Flex>
    )
  }

  return (
    <Suspense>
      <Flex gap={'3'} direction={'column'} mt={'4'}>
        <HoverCard.Root>
          <HoverCard.Trigger>
            <Flex align={'center'} gap={'2'}>
              <MdOutlineWifiTethering size={18} color="var(--mint-11)" />
              <Text size="3" weight={'medium'}>
                {meter.name}
              </Text>
            </Flex>
          </HoverCard.Trigger>
          <HoverCard.Content>
            <DataList.Root>
              <DataList.Item>
                <DataList.Label>{t('stations.meter.details.deviceId')}</DataList.Label>
                <DataList.Value>
                  <Badge>{meter.deviceID}</Badge>
                </DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>Device Maker</DataList.Label>
                <DataList.Value>{meter.maker}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>{t('stations.meter.details.readingMethod')}</DataList.Label>
                <DataList.Value>{meter.readingMethod}</DataList.Value>
              </DataList.Item>
              <DataList.Item>
                <DataList.Label>{t('stations.meter.details.measurementUnit')}</DataList.Label>
                <DataList.Value>{meter.unit}</DataList.Value>
              </DataList.Item>
            </DataList.Root>
          </HoverCard.Content>
        </HoverCard.Root>
        {!dataPointsLoading && !!dataPointsError && (
          <Callout.Root color="red">
            <Callout.Text>
              <Text as="p">
                {dataPointsError.message} - {t('stations.meter.form.datapoints.errorMessage')}
              </Text>
            </Callout.Text>
          </Callout.Root>
        )}
        {!dataPointsError && (
          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>
                  <Strong>{t('stations.meter.form.datapoints.timestamp')}</Strong>
                </Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>
                  <Strong>{t('stations.meter.form.datapoints.value')}</Strong>
                </Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {dataPoints.slice(0, nDataPoints).map((dp) => (
                <Table.Row key={`${JSON.stringify(dp)}`}>
                  <Table.Cell>{dp.dateTime?.toRelative()}</Table.Cell>
                  <Table.Cell maxWidth={'500px'}>
                    <Code color="gray">{JSON.stringify(dp.value)}</Code>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Root>
        )}
        <Flex justify={'center'}>
          <Button
            variant="ghost"
            onClick={() => setNDataPoints(nDataPoints + 10)}
            disabled={nDataPoints >= dataPoints.length}
          >
            {t('stations.meter.buttons.viewMore')}
          </Button>
        </Flex>
      </Flex>
    </Suspense>
  )
}

const EditStationPage = () => {
  const { t } = useTranslation('sites')
  const { siteID, stationID } = Route.useParams()
  const { queryClient } = Route.useRouteContext()
  const [isAddingMeter, setIsAddingMeter] = useState(false)
  const navigate = useNavigate()

  const { data: site } = useSuspenseQuery({
    queryKey: ['site', siteID],
    queryFn: () => getSite({ siteId: siteID }),
    refetchOnWindowFocus: false
  })

  const { data: station } = useSuspenseQuery({
    queryKey: ['station', stationID],
    queryFn: () => getStation({ stationID }),
    refetchOnWindowFocus: false
  })

  const trpc = useTRPC()

  const { mutate: updateStation, isPending } = useMutation({
    mutationFn: updateWaterStation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          const isStationsOrSite = ['stations', 'site'].includes(`${queryKey[0]}`) && queryKey[1] === siteID
          const isStation = queryKey[0] === 'station' && queryKey[1] === stationID
          return isStationsOrSite || isStation
        }
      })
      queryClient.invalidateQueries({ queryKey: trpc.getStationLeakInfo.queryKey({ stationID }) })
      toast.success(t('stations.form.messages.updateSuccess'))
    },
    onError: (error) => {
      console.error('Error updating station', error)
      toast.error(t('stations.form.messages.updateError'))
    }
  })

  const { mutate: deleteStation, isPending: isDeleting } = useMutation({
    mutationFn: () => deleteWaterStation({ stationID }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['stations', siteID]
      })
      navigate({ to: '/sites/$siteID/config/stations', params: { siteID } })
      toast.success(t('stations.form.messages.deleteSuccess'))
    },
    onError: (error) => {
      console.error('Error deleting station', error)
      toast.error(t('stations.form.messages.deleteError'))
    }
  })

  const handleDeleteClick = () => {
    if (window.confirm(t('stations.dangerZone.confirmDelete'))) {
      deleteStation()
    }
  }

  return (
    <Flex direction="column" gap="4">
      <UpdateStationForm station={station} onSubmit={updateStation} isPending={isPending} site={site} />
      <SectionCard title={t('stations.meter.dataSectionTitle')}>
        <Text size="2" color="gray">
          {t('stations.meter.dataSectionDescription')}
        </Text>
        {station.meter_id ? (
          <MeterPreview meterId={station.meter_id} />
        ) : isAddingMeter ? (
          <MeterForm station={station} onCancel={() => setIsAddingMeter(false)} />
        ) : (
          <EmptyState
            title={t('stations.meter.emptyState.title')}
            description={t('stations.meter.emptyState.description')}
            className="h-64"
            afterBody={
              <Flex gap={'4'}>
                <Button size={'2'} variant={'soft'} onClick={() => setIsAddingMeter(true)}>
                  <MdOutlineWifiTethering />
                  {t('stations.meter.buttons.assignMeter')}
                </Button>
                <Button size={'2'} variant={'soft'} disabled>
                  <LuArchiveRestore />
                  {t('stations.meter.buttons.importData')}
                </Button>
              </Flex>
            }
          />
        )}
      </SectionCard>
      <ConsumptionAndLeaksSection station={station} />

      <SectionCard title={t('stations.dangerZone.title')}>
        <Callout.Root color="red" variant="surface" className="flex items-center justify-between">
          <Callout.Text>{t('stations.dangerZone.deleteWarning')}</Callout.Text>
          <Button variant="soft" onClick={handleDeleteClick} disabled={isDeleting}>
            {t('stations.dangerZone.removeStation')}
          </Button>
        </Callout.Root>
      </SectionCard>
    </Flex>
  )
}

export const Route = createFileRoute('/sites/$siteID/config/stations/$stationID')({
  component: EditStationPage
})
