import { z } from 'zod'

export type CreateOrUpdateSiteSchema = z.infer<typeof createOrUpdateSiteSchema>
export const createOrUpdateSiteSchema = z.object({
  name: z.string().min(3),
  description: z.string().nullable(),
  address: z.string()
})

export type SiteSchema = z.infer<typeof siteSchema>
export const siteSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  address: z.string(),
  orgID: z.string(),
  cost: z.number().nullable()
})

export type CreateSiteSchema = z.infer<typeof createSiteSchema>
export const createSiteSchema = siteSchema.omit({ id: true, cost: true })
