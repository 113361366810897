import { ArrowRightIcon } from '@radix-ui/react-icons'
import { Button, Card, Flex, Skeleton, Spinner, Strong, Text } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import _, { isNumber } from 'lodash'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IoWaterOutline } from 'react-icons/io5'
import { LuPercent } from 'react-icons/lu'
import { MdOutlineWifiTethering } from 'react-icons/md'
import { EmptyState } from '~/components/EmptyState'
import LinkButton from '~/components/LinkButton'
import { ScrollablePageLayout } from '~/components/ScrollablePageLayout'
import { SectionCard } from '~/components/SectionCard'
import { BudgetChart } from '~/features/budget/components/HistoricalBudgetChart'
import { NULL_CATEGORY, useStationsChartData } from '~/features/dashboarding/hooks/useStationsChartData'
import { ConsumptionAndLeaksSection } from '~/features/leaks/components/ConsumptionAndLeakSection'
import { getSite } from '~/features/sites/service'
import { formatNumber } from '~/utils/formating'

const PERIOD = 7 //days

const Component = () => {
  const { t } = useTranslation(['sites', 'common', 'sidenav'])
  const { siteID } = Route.useParams()

  const { data: site, isPending } = useQuery({
    queryFn: () => getSite({ siteId: siteID }),
    queryKey: ['site', siteID]
  })

  const entryStations = site?.stations.filter((s) => s.station_type === 'entry') ?? []
  const now = DateTime.now()

  const { chartData, isLoading: consumptionLoading } = useStationsChartData({
    title: '',
    stations: entryStations,
    partitionKey: null,
    partitionsOptions: [{ key: NULL_CATEGORY, label: 'value' }],
    startDate: now.minus({ days: 2 * PERIOD - 1 }).startOf('day'),
    endDate: now
  })

  // Calculate total volume and variation compared to previous period
  const { totalVolume, variation } = useMemo(() => {
    if (consumptionLoading || !chartData || chartData.length === 0) {
      return { totalVolume: null, variation: null }
    }

    const periodAgo = now.minus({ days: PERIOD - 1 }).startOf('day') // half way throuh datapoints

    const currentPeriodData = chartData.filter((point) => DateTime.fromISO(point.isoDate) >= periodAgo)
    const previousPeriodData = chartData.filter((point) => DateTime.fromISO(point.isoDate) < periodAgo)

    const currentTotal = _.sumBy(currentPeriodData, 'value')
    const previousTotal = _.sumBy(previousPeriodData, 'value')

    // Calculate variation as percentage
    const variationPercent =
      previousTotal > 0 ? Math.round(((currentTotal - previousTotal) / previousTotal) * 100) : null

    return {
      totalVolume: _.round(currentTotal, 2),
      variation: variationPercent
    }
  }, [chartData, consumptionLoading])

  return (
    <ScrollablePageLayout title={t('dashboard.title')}>
      {isPending || !site ? (
        <Spinner />
      ) : entryStations.length === 0 ? (
        <SectionCard title="" className="h-[100%]">
          <EmptyState
            title={t('dashboard.emptyState.title')}
            description={t('dashboard.emptyState.description')}
            afterBody={
              <Link to={'/sites/$siteID/config/stations/new'} params={{ siteID }}>
                <Button size={'2'}>{t('stations.mapping.emptyState.addStation')}</Button>
              </Link>
            }
          />
        </SectionCard>
      ) : (
        <Flex direction={'column'} gap="5" mt="2">
          <Flex gap="5" justify={'between'} className="[&>*]:w-[33%]">
            <Card className="p-4">
              <Flex align={'center'} justify={'between'}>
                <Text size={'3'} weight={'medium'}>
                  {t('dashboard.cards.activeStations.title')}
                </Text>
                <MdOutlineWifiTethering size={18} color="var(--mint-11)" />
              </Flex>
              <Text size={'1'} color="gray" as="p">
                {t('dashboard.cards.activeStations.total', { count: site.stations.length })}
              </Text>
              <Flex gap={'2'} align={'center'}>
                <Text size={'5'} weight={'bold'}>
                  {site.stations.filter((s) => s.meter_id !== null).length}
                </Text>
              </Flex>
            </Card>
            <Card>
              <Flex align={'center'} justify={'between'}>
                <Text size={'3'} weight={'medium'}>
                  {t('dashboard.cards.volume.title')}
                </Text>
                <IoWaterOutline size={18} color="var(--mint-11)" />
              </Flex>
              <Text size={'1'} color="gray" as="p">
                {t('dashboard.cards.volume.period', { days: PERIOD })}
              </Text>
              <Flex gap={'2'} align={'center'}>
                <Skeleton loading={consumptionLoading}>
                  <Text size={'5'} weight={'bold'}>
                    {consumptionLoading && 'Some value'}
                    {totalVolume === null ? (
                      '-'
                    ) : (
                      <>
                        {formatNumber(totalVolume)} m<sup>3</sup>
                      </>
                    )}
                  </Text>
                </Skeleton>
              </Flex>
            </Card>
            <Card>
              <Flex align={'center'} justify={'between'}>
                <Text size={'3'} weight={'medium'}>
                  {t('dashboard.cards.variation.title')}
                </Text>
                <LuPercent size={18} color="var(--mint-11)" />
              </Flex>
              <Text size={'1'} color="gray" as="p">
                {t('dashboard.cards.variation.period', { days: PERIOD })}
              </Text>
              <Flex gap={'2'} align={'center'}>
                <Skeleton loading={consumptionLoading}>
                  <Text size={'5'} color={(variation ?? 0 < 0) ? 'green' : 'red'}>
                    {consumptionLoading && 'Some value'}
                    {isNumber(variation) ? (
                      <>
                        {variation > 0 ? '+' : '-'}
                        <Strong>{Math.abs(variation)}</Strong> %
                      </>
                    ) : (
                      '-'
                    )}
                  </Text>
                </Skeleton>
              </Flex>
            </Card>
          </Flex>
          <ConsumptionAndLeaksSection station={entryStations[0]} />
          <SectionCard
            title={t('dashboard.budgetPlanning')}
            className="min-h[400px]"
            secondaryActions={
              <LinkButton
                to="/sites/$siteID/budget-planning"
                params={{ siteID }}
                text={t('goTo', { ns: 'common' }) + ' ' + t('navigation.budgetPlanning', { ns: 'sidenav' })}
                trailingIcon={<ArrowRightIcon />}
              />
            }
          >
            <BudgetChart site={site} />
          </SectionCard>
        </Flex>
      )}
    </ScrollablePageLayout>
  )
}

export const Route = createFileRoute('/sites/$siteID/dashboard')({
  component: Component
})
