import { DateTime } from 'luxon'
import { useCurrentLanguage } from '~/hooks/useCurrentLanguage'
import { Text, TextProps, Tooltip } from '@radix-ui/themes'

type Props = {
  timestamp: DateTime
  withTooltip?: boolean
} & TextProps

const florAbs = (value: number) => Math.floor(Math.abs(value))

const HumanTimestamp = ({ timestamp, withTooltip = true, ...textProps }: Props) => {
  const locale = useCurrentLanguage()
  let formatted = timestamp.toRelative({ locale })
  if (florAbs(timestamp.diffNow('days').days) > 9) {
    formatted = timestamp.toLocaleString({ month: 'short', day: 'numeric' }, { locale })
  }

  if (florAbs(timestamp.diffNow('years').years) > 0) {
    formatted = timestamp.toLocaleString(DateTime.DATE_MED, { locale })
  }
  return (
    <Tooltip
      content={timestamp.toLocaleString(DateTime.DATETIME_MED, { locale })}
      open={!withTooltip || undefined}
    >
      <Text {...textProps}>{formatted}</Text>
    </Tooltip>
  )
}

export default HumanTimestamp
