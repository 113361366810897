import { Card, Flex, FlexProps, Text } from '@radix-ui/themes'
import { PropsWithChildren } from 'react'

type SectionCardProps = PropsWithChildren<
  {
    title: string
    secondaryActions?: React.ReactNode
  } & FlexProps
>

const SectionCard = ({ title, secondaryActions, children, ...flexProps }: SectionCardProps) => {
  return (
    <Card asChild variant="surface" className="flex p-5">
      <Flex gap={'2'} direction={'column'} {...flexProps} width={'100%'}>
        <Flex align={'center'} justify={'between'}>
          <Text size={'4'} weight={'medium'}>
            {title}
          </Text>
          <Flex gap={'3'} align={'center'}>
            {secondaryActions}
          </Flex>
        </Flex>
        {children}
      </Flex>
    </Card>
  )
}

export { SectionCard }
