import { Button, Flex, Text } from '@radix-ui/themes'

interface EmptyStateProps {
  title: string
  description: string
  primaryAction?: {
    label: string
    action: (() => void) | (() => Promise<void>)
    loading?: boolean
  }
  afterBody?: React.ReactNode
  className?: string
}

const EmptyState = ({ title, description, primaryAction, afterBody, className = '' }: EmptyStateProps) => {
  return (
    <Flex
      gap={'3'}
      maxWidth={'400px'}
      justify={'center'}
      direction={'column'}
      align={'center'}
      m={'auto'}
      className={className}
    >
      <Text size={'4'}>{title}</Text>
      <Text size={'2'} color="gray" className="text-center">
        {description}
      </Text>
      {primaryAction && (
        <Button variant="soft" onClick={primaryAction.action}>
          {primaryAction.label}
        </Button>
      )}
      {afterBody}
    </Flex>
  )
}

export { EmptyState }
