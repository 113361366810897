import { z } from 'zod'

export type MetricOperation = z.infer<typeof metricOperation>
export const metricOperation = z.enum(['add', 'subtract'])

export const partitionKey = z.enum(['name', 'label'])

export type ChartType = z.infer<typeof chartType>
export const chartType = z.enum(['area', 'bar', 'table'])

export type Period = z.infer<typeof chartType>
export const period = z.enum(['week', 'month', 'quarter', 'year'])

export type MetricSchema = z.infer<typeof metricSchema>
export const metricSchema = z.object({
  id: z.string(),
  name: z.string().min(3).max(50),
  description: z.string().max(500).optional(),
  siteID: z.string().nullable().default(null),
  orgID: z.string(),
  chartType,
  partitionKey: partitionKey.optional(),
  stationOperations: z
    .array(
      z.object({
        stationId: z.string(),
        operation: metricOperation
      })
    )
    .min(1, 'At least one station operation is required'),
  summaryCardConfig: z.object({
    schemaVersion: z.literal(1).default(1),
    showOnSiteSummary: z.boolean().default(false),
    period: period.default('month')
  })
})

export type SiteMetric = z.infer<typeof siteMetricSchema>
export const siteMetricSchema = metricSchema.merge(z.object({ siteID: z.string() }))

export type CreateMetricSchema = z.infer<typeof createMetricSchema>
export const createMetricSchema = metricSchema.omit({ id: true })
