import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend' // Load JSON files dynamically
import LanguageDetector from 'i18next-browser-languagedetector'

const namespaces = ['common', 'settings', 'sites', 'sidenav']

i18n
  .use(Backend) // Load translations from files
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: import.meta.env.DEV,
    ns: namespaces,
    defaultNS: 'common',
    interpolation: {
      escapeValue: false // React already escapes by default
    },
    pluralSeparator: '::',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json' // Load files dynamically
    }
  })

const savedLanguage = localStorage.getItem('language')

if (savedLanguage) {
  i18n.changeLanguage(savedLanguage)
}

export default i18n
