import { Tables } from '@willie/supabase'
import { z } from 'zod'
import { getWillieSupabaseClient } from '~/utils'

export const getCurrentUserProfile = async ({ userID }: { userID: string }) => {
  const supabase = getWillieSupabaseClient()
  const { data, error } = await supabase.from('user_profiles').select('*').eq('user_id', userID).single()
  if (error || !data) {
    console.error('Error fetching user profile', error)
    throw error
  }
  return data
}

export type SimpleUserProfile = Pick<Tables<'user_profiles'>, 'full_name' | 'picture_url'>

export const updateUserProfile = async (userId: string, profileData: SimpleUserProfile) => {
  const supabase = getWillieSupabaseClient()
  const { error } = await supabase
    .from('user_profiles')
    .update({ ...profileData })
    .eq('user_id', userId)

  if (error) {
    console.error('Error updating user profile', error)
    throw error
  }
}

export const updateUserPassword = async (newPassword: string) => {
  const supabase = getWillieSupabaseClient()
  const { data, error } = await supabase.auth.updateUser({
    password: newPassword
  })
  if (error) {
    console.error('Error updating user password', error)
    throw error
  }

  await supabase
    .from('user_profiles')
    .update({
      has_logged_in: true
    })
    .eq('user_id', data.user?.id)

  return data
}

export const notificationPreferencesSchema = z.object({
  showRead: z.boolean(),
  emailEnabled: z.boolean()
})

export type NotificationPreferences = z.infer<typeof notificationPreferencesSchema>

export const userPreferencesSchema = z.object({
  notifications: notificationPreferencesSchema.default({ showRead: true, emailEnabled: false })
  // TODO add language
  // TODO add theme
  // TODO add other preferences
})

export type UserPreferences = z.infer<typeof userPreferencesSchema>

export const updateUserPreferences = async (userId: string, preferences: UserPreferences) => {
  const supabase = getWillieSupabaseClient()
  const { error } = await supabase.from('user_profiles').update({ preferences }).eq('user_id', userId)
  if (error) {
    console.error('Error updating user preferences', error)
    throw error
  }
}
