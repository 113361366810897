import { StationChartDataProps, useStationsChartData } from '../hooks/useStationsChartData'
import { Flex, FlexProps, Spinner, Strong, Text } from '@radix-ui/themes'
import { AreaChart, BarChart } from '@tremor/react'
import _ from 'lodash'
import { ChartType } from '../schemas'
import { WILLIE_BRAND_COLOR } from '~/constants'
import { useTranslation } from 'react-i18next'

const COLORS = [
  WILLIE_BRAND_COLOR,
  'emerald-400',
  'amber-300',
  'fuchsia-500',
  'blue-600',
  'indigo-400',
  'lime-300',
  'pink-300',
  'zinc-600',
  'orange-500',
  'rose-500',
  'slate-800',
  'purple-600',
  'green-700',
  'stone-800',
  'red-400'
]

// TODO add a notice when station isn't mapped to a meter (data source)
export type StationsConsumptionChartProps = {
  title: string
  chartType: ChartType
  showLegend?: boolean
  showGridLines?: boolean
  showAnimation?: boolean
  showYAxis?: boolean
  yAxisLabel?: string
} & FlexProps &
  StationChartDataProps

const StationsConsumptionChart = ({
  title,
  chartType,
  className,
  stations,
  partitionKey: groupByKey,
  chartKey,
  startDate,
  endDate,
  showAnimation = true,
  showYAxis = true,
  showGridLines = true,
  showLegend = true,
  yAxisLabel = '',
  partitionsOptions,
  ...flexProps
}: StationsConsumptionChartProps) => {
  const { t } = useTranslation('sites')
  const { chartData, isLoading, categories } = useStationsChartData({
    title,
    stations,
    partitionKey: groupByKey,
    chartKey,
    partitionsOptions,
    startDate,
    endDate
  })

  const displayLegend = showLegend && !!groupByKey

  if (isLoading) {
    return (
      <div>
        {title && (
          <Text size={'2'} color="gray">
            <Strong>{title}</Strong>
          </Text>
        )}
        <Flex width={'100%'} align={'center'} justify={'center'} className="h-80">
          <Spinner size={'3'} />
        </Flex>
      </div>
    )
  }

  if (!chartData) {
    return <div>{t('stations.charts.noData')}</div>
  }

  const chartProps = {
    data: chartData,
    categories: categories,
    index: 'date',
    colors: COLORS.slice(0, categories.length),
    showGridLines,
    showLegend: displayLegend,
    showAnimation,
    showYAxis,
    className,
    noDataText: t('stations.charts.noDataPeriod'),
    yAxisLabel
  }

  const chart =
    chartType === 'area' ? (
      <AreaChart {...chartProps} />
    ) : chartType === 'bar' ? (
      <BarChart {...chartProps} stack />
    ) : (
      // TODO add table chart
      <>{t('stations.charts.unsupportedChartType')}</>
    )
  return (
    <Flex {...flexProps} direction={'column'} gap={'3'}>
      {title && (
        <Text size={'2'} color="gray">
          <Strong>{title}</Strong>
        </Text>
      )}
      {chart}
    </Flex>
  )
}

export { StationsConsumptionChart }
