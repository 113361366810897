import { z } from 'zod'
import { getWillieSupabaseClient } from '~/utils'

const notificationLevel = z.enum(['info', 'warning', 'critical', 'good', 'amazing'])
export type NotificationLevel = z.infer<typeof notificationLevel>

const userNotificationSchema = z.object({
  id: z.string(),
  read: z.boolean(),
  title: z.string(),
  message: z.string(),
  created_at: z.string(),
  siteID: z.string().nullish(),
  path: z.string().nullish(),
  level: notificationLevel
})

export type UserNotification = z.infer<typeof userNotificationSchema>

type ListNotificationOptions = {
  orgID: string
}

export const listUserNotifications = async ({ orgID }: ListNotificationOptions) => {
  const supabase = getWillieSupabaseClient()
  const { data } = await supabase
    .from('user_notifications')
    .select(
      `
      *,
      notification:notifications!inner(
        title,
        message_body,
        org_id,
        app_link,
        site_id,
        level
      )
      `
    )
    .eq('notification.org_id', orgID)
    .order('created_at', { ascending: false })

  return (data ?? []).map<UserNotification>((n) =>
    userNotificationSchema.parse({
      id: n.id,
      read: n.is_read,
      title: n.notification?.title,
      message: n.notification?.message_body,
      created_at: n.created_at,
      siteID: n.notification?.site_id,
      path: n.notification?.app_link,
      level: n.notification?.level
    })
  )
}

export const markUserNotificationAsRead = async ({ userNotificationID }: { userNotificationID: string }) => {
  const supabase = getWillieSupabaseClient()
  const { data } = await supabase
    .from('user_notifications')
    .update({
      is_read: true
    })
    .eq('id', userNotificationID)
    .select()
  return data
}

// TODO replace all this with incidents

const siteNotificationSchema = z.object({
  id: z.string(),
  siteID: z.string(),
  title: z.string(),
  message: z.string(),
  level: notificationLevel,
  created_at: z.string()
})

export type SiteNotification = z.infer<typeof siteNotificationSchema>

export const listSiteNotifications = async ({ siteID }: { siteID: string }) => {
  const supabase = getWillieSupabaseClient()
  const { data = [] } = await supabase
    .from('notifications')
    .select('*')
    .eq('site_id', siteID)
    .order('created_at', { ascending: false })

  return (data ?? []).map<SiteNotification>((n) =>
    siteNotificationSchema.parse({
      id: n.id,
      siteID: n.site_id,
      title: n.title,
      message: n.message_body,
      level: n.level,
      created_at: n.created_at
    })
  )
}
