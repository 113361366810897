import { Card, Callout, Flex, Select, Text } from '@radix-ui/themes'
import { useTranslation } from 'react-i18next'
import { StationsPicker } from '~/features/dashboarding/components/StationPicker'
import { EmptyState } from '~/components/EmptyState'
import LinkButton from '~/components/LinkButton'
import { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { SiteWithStations } from '~/features/sites/service'
import { WaterStation } from '~/features/mapping/schemas'

export type ActionForOverridenStations = 'updateCost' | 'keepCost'

interface BudgetStationsSectionProps {
  site: SiteWithStations
  newCost: number | null
  onStationsWithCostChange: (updatedStations: WaterStation[]) => void
}

export const BudgetStationsSection = ({ site, newCost, onStationsWithCostChange }: BudgetStationsSectionProps) => {
  const { t } = useTranslation('sites')

  const [overridenCostAction, setOverridenCostAction] = useState<ActionForOverridenStations>('keepCost')

  const initialStationsWithCost = useMemo(
    () => site.stations.filter((station) => station.cost !== null).map((station) => station.id),
    [site.stations]
  )
  const [stationsWithCost, setStationsWithCost] = useState(initialStationsWithCost)

  const someStationHaveOverridenCost = useMemo(() => {
    return site.stations.some(
      (station) => stationsWithCost.includes(station.id) && station.cost !== null && station.cost !== site.cost
    )
  }, [site, stationsWithCost])

  // Calculate the updated stations based on current selections and settings
  const updatedStations = useMemo(() => {
    return _([...initialStationsWithCost, ...stationsWithCost])
      .uniq()
      .map((stationID) => {
        const originalStation = site.stations.find((station) => station.id === stationID)!
        // station was added
        if (originalStation.cost === null) {
          return { ...originalStation, cost: newCost } satisfies WaterStation
        }
        // station was removed
        if (!stationsWithCost.includes(stationID)) {
          return { ...originalStation, cost: null } satisfies WaterStation
        }
        // station needs to be updated
        if (overridenCostAction === 'updateCost') {
          return { ...originalStation, cost: newCost } satisfies WaterStation
        }
        return originalStation
      })
      .value()
  }, [site.stations, stationsWithCost, initialStationsWithCost, overridenCostAction])

  // Call the callback whenever the updated stations change
  useEffect(() => {
    onStationsWithCostChange(updatedStations)
  }, [updatedStations, onStationsWithCostChange])

  return (
    <Flex direction={'column'} gap="2" flexGrow={'1'} maxWidth={'45%'}>
      <Text size={'2'} weight={'medium'}>
        {t('budget.stationsSection.title')}
      </Text>
      {site.stations.length === 0 ? (
        <Card asChild>
          <Flex height={'100%'} align={'center'} className="*:mt-20">
            <EmptyState
              title={t('budget.stationsSection.emptyState.title')}
              description={t('budget.stationsSection.emptyState.description')}
              afterBody={
                <LinkButton
                  to={`/sites/$siteID/config/stations/new`}
                  params={{ siteID: site.id }}
                  size="2"
                  variant="soft"
                  text={t('budget.stationsSection.emptyState.addStation')}
                />
              }
            />
          </Flex>
        </Card>
      ) : (
        <>
          <Text size="2" as="p" color={'gray'}>
            {t('budget.stationsSection.description')}
          </Text>
          <StationsPicker
            showLabel={false}
            stationTypeFilter={['entry']}
            stations={site.stations}
            defaultValue={stationsWithCost}
            onValueChange={setStationsWithCost}
          />
          {someStationHaveOverridenCost && newCost !== site.cost && (
            <Callout.Root color="amber" variant="surface" mt="2">
              <Callout.Text size={'2'}>
                <Flex direction={'column'} gap="2">
                  <Text as="p">{t('budget.stationsSection.stationCostWarning')}</Text>
                  <Select.Root
                    value={overridenCostAction}
                    onValueChange={(v: ActionForOverridenStations) => setOverridenCostAction(v)}
                  >
                    <Select.Trigger color="amber" variant="soft" className="w-fit" />
                    <Select.Content color="amber" variant="soft">
                      <Select.Item value="updateCost">{t('budget.stationsSection.updateCost')}</Select.Item>
                      <Select.Item value="keepCost">{t('budget.stationsSection.keepCost')}</Select.Item>
                    </Select.Content>
                  </Select.Root>
                </Flex>
              </Callout.Text>
            </Callout.Root>
          )}
        </>
      )}
    </Flex>
  )
}
