import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import _ from 'lodash'
import { SectionCard } from '~/components/SectionCard'
import { getSite } from '~/features/sites/service'
import { Button, Flex } from '@radix-ui/themes'
import {
  StationsConsumptionChart,
  StationsConsumptionChartProps
} from '~/features/dashboarding/components/StationsConsumptionChart'
import { EmptyState } from '~/components/EmptyState'
import { listSiteMetrics } from '~/features/dashboarding/service'
import { useTranslation } from 'react-i18next'
import { ScrollablePageLayout } from '~/components/ScrollablePageLayout'
import {
  PeriodSelection,
  PeriodSelector,
  periodSearchParamsSchema,
  usePeriodSearchParams
} from '~/components/PeriodSelector'
import { DateRange } from '~/types'

// TODO put visibility settings in a local storage and use it to set the default visibility of the legend, y axis, gridlines
// TODO show/hide some metrics only for a user in their local storage as a preference

const Page = () => {
  const { t } = useTranslation(['sites', 'sidenav'])
  const { siteID } = Route.useParams()
  const searchQ = Route.useSearch()
  const navigate = Route.useNavigate()

  const { fromSearchParams, toSearchParams } = usePeriodSearchParams()

  // Convert from search params to state
  const { dateRange, periodSelection } = fromSearchParams(searchQ)

  const { data: site } = useSuspenseQuery({
    queryKey: ['site', siteID],
    queryFn: () => getSite({ siteId: siteID }),
    refetchOnWindowFocus: false
  })

  const { data: metrics } = useSuspenseQuery({
    queryKey: ['site-metrics', siteID],
    queryFn: () => listSiteMetrics(siteID)
  })

  // todo shoot this to LS along with other options
  // const [showLegend, setShowLegend] = useState(true)

  const handlePeriodChange = (newDateRange: DateRange, newPeriodSelection: PeriodSelection) => {
    // Convert to search params and navigate
    const searchParams = toSearchParams(newDateRange, newPeriodSelection)
    navigate({ search: searchParams })
  }

  const commonChartProps: Partial<StationsConsumptionChartProps> = {
    yAxisLabel: t('stations.leakSection.chart.yAxisLabel'),
    showLegend: true,
    showAnimation: true,
    showGridLines: false,
    showYAxis: true,
    startDate: dateRange.startDate,
    endDate: dateRange.endDate
  }

  const dashboard = (
    <SectionCard
      title={t('dashboard.charts.consumptionOverview')}
      gap={'8'}
      px={'5'}
      secondaryActions={
        <PeriodSelector
          periodSelection={periodSelection}
          dateRange={dateRange}
          onPeriodChange={handlePeriodChange}
        />
      }
    >
      <StationsConsumptionChart
        className="mt-[-1.5rem]"
        title={''}
        stations={site.stations}
        chartType="area"
        partitionKey="station_type"
        partitionsOptions={[
          { key: 'entry', label: t('stations.types.entry', { count: 10 }) },
          { key: 'usage', label: t('stations.types.usage', { count: 10 }) },
          { key: 'discharge', label: t('stations.types.discharge', { count: 10 }) }
        ]}
        {...commonChartProps}
      />
      <Flex gap="3">
        <StationsConsumptionChart
          width={'100%'}
          {...commonChartProps}
          showLegend={false}
          title={t('stations.types.entry', { count: 10 })}
          stations={site.stations.filter((s) => s.station_type === 'entry')}
          chartType="bar"
          partitionKey="label"
        />
        <StationsConsumptionChart
          width={'100%'}
          {...commonChartProps}
          showLegend={false}
          title={t('stations.types.usage', { count: 10 })}
          stations={site.stations.filter((s) => s.station_type === 'usage')}
          partitionKey="name"
          chartType="bar"
        />
        <StationsConsumptionChart
          width={'100%'}
          {...commonChartProps}
          showLegend={false}
          title={t('stations.types.discharge', { count: 10 })}
          stations={site.stations.filter((s) => s.station_type === 'discharge')}
          partitionKey="name"
          chartType="bar"
        />
      </Flex>
      {metrics.map((metric) => (
        <div key={metric.id}>
          <StationsConsumptionChart
            {...commonChartProps}
            chartKey={metric.id}
            title={metric.name}
            stations={site.stations.filter((s) =>
              metric.stationOperations.map((op) => op.stationId).includes(s.id)
            )}
            chartType={metric.chartType}
            partitionKey={null}
          />
        </div>
      ))}
      <Flex wrap={'wrap'} justify={'between'} gapY={'5'}>
        {site.stations.map((station) => (
          <StationsConsumptionChart
            width={site.stations.length > 1 ? '48%' : '100%'}
            key={station.id}
            chartKey={station.id}
            title={station.name}
            stations={[station]}
            chartType="area"
            partitionKey={null}
            {...commonChartProps}
            showLegend={false}
            height="250px"
          />
        ))}
      </Flex>
    </SectionCard>
  )

  return (
    <ScrollablePageLayout title={t('navigation.readings', { ns: 'sidenav' })}>
      {site.stations.length > 0 ? (
        dashboard
      ) : (
        <SectionCard title={t('dashboard.title')} height={'100%'}>
          <EmptyState
            title={t('dashboard.emptyState.title')}
            description={t('dashboard.emptyState.description')}
            afterBody={
              <Link to={`/sites/$siteID/config/stations/new`} params={{ siteID }}>
                <Button size={'2'}>{t('dashboard.emptyState.addEntryStation')}</Button>
              </Link>
            }
          />
        </SectionCard>
      )}
    </ScrollablePageLayout>
  )
}
export const Route = createFileRoute('/sites/$siteID/readings')({
  component: Page,
  validateSearch: periodSearchParamsSchema
})
