import { Badge, Button, Flex, Inset, Popover, Text } from '@radix-ui/themes'
import { PropsWithChildren } from 'react'
import { WaterStation } from '../schemas'
import { useTranslation } from 'react-i18next'
import { Link } from '@tanstack/react-router'
import { ArrowRightIcon } from '@radix-ui/react-icons'

interface MeterPreviewPopoverProps {
  station: WaterStation
}

const StationPreviewPopover = ({ children, station }: PropsWithChildren<MeterPreviewPopoverProps>) => {
  const { t } = useTranslation('sites')

  return (
    <Popover.Root>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content side="top">
        <Flex direction="column" gap="1" minWidth={'150px'}>
          <Flex gap={'4'} justify={'between'} align={'center'}>
            <Text>{station.name}</Text>
            {station.label && <Badge>{station.label}</Badge>}
          </Flex>
          <Text color="gray" size={'1'}>
            {station.description}
          </Text>
          <Inset>
            <Flex justify={'end'} mt="6" pt="1" pb="3" px="4" className="border-t-[1px] border-gray-3">
              <Link
                to={'/sites/$siteID/config/stations/$stationID'}
                params={{
                  siteID: station.site_id,
                  stationID: station.id
                }}
              >
                <Button variant="ghost" mt={'2'} size={'1'} autoFocus>
                  {t('stations.preview.editStation')} <ArrowRightIcon />
                </Button>
              </Link>
            </Flex>
          </Inset>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  )
}

export { StationPreviewPopover }
