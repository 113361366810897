import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { CreateStationForm } from '~/features/mapping/components/StationForm'
import { createWaterStation } from '~/features/mapping/service'
import { getSite } from '~/features/sites/service'
import { toast } from 'sonner'
import { useTranslation } from 'react-i18next'

const NewStationPage = () => {
  const { t } = useTranslation('sites')
  const { siteID } = Route.useParams()
  const { queryClient } = Route.useRouteContext()
  const navigate = Route.useNavigate()

  const { data: site } = useSuspenseQuery({
    queryKey: ['site', siteID],
    queryFn: () => getSite({ siteId: siteID }),
    refetchOnWindowFocus: false
  })

  const { mutate: createStation, isPending } = useMutation({
    mutationFn: createWaterStation,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['stations', siteID]
      })
      navigate({ to: '/sites/$siteID/config/stations', params: { siteID } })
      toast.success(t('stations.form.messages.createSuccess'))
    },
    onError: (error) => {
      console.error('Error creating station', error)
      toast.error(t('stations.form.messages.createError'))
    }
  })

  return <CreateStationForm station={null} onSubmit={createStation} isPending={isPending} site={site} />
}

export const Route = createFileRoute('/sites/$siteID/config/stations/new')({
  component: NewStationPage
})
