/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SettingsImport } from './routes/settings'
import { Route as ProfileImport } from './routes/profile'
import { Route as IndexImport } from './routes/index'
import { Route as SitesIndexImport } from './routes/sites/index'
import { Route as SitesSiteIDIndexImport } from './routes/sites/$siteID/index'
import { Route as GroupsGroupIDIndexImport } from './routes/groups/$groupID/index'
import { Route as SitesSiteIDReportingImport } from './routes/sites/$siteID/reporting'
import { Route as SitesSiteIDReadingsImport } from './routes/sites/$siteID/readings'
import { Route as SitesSiteIDLeaksImport } from './routes/sites/$siteID/leaks'
import { Route as SitesSiteIDDashboardImport } from './routes/sites/$siteID/dashboard'
import { Route as SitesSiteIDConfigImport } from './routes/sites/$siteID/config'
import { Route as SitesSiteIDBudgetPlanningImport } from './routes/sites/$siteID/budget-planning'
import { Route as SitesSiteIDAlertingImport } from './routes/sites/$siteID/alerting'
import { Route as GroupsGroupIDReportingImport } from './routes/groups/$groupID/reporting'
import { Route as GroupsGroupIDReadingsImport } from './routes/groups/$groupID/readings'
import { Route as GroupsGroupIDDashboardImport } from './routes/groups/$groupID/dashboard'
import { Route as GroupsGroupIDConfigImport } from './routes/groups/$groupID/config'
import { Route as GroupsGroupIDBudgetPlanningImport } from './routes/groups/$groupID/budget-planning'
import { Route as GroupsGroupIDAlertingImport } from './routes/groups/$groupID/alerting'
import { Route as SitesSiteIDConfigIndexImport } from './routes/sites/$siteID/config/index'
import { Route as SitesSiteIDConfigOverviewImport } from './routes/sites/$siteID/config/overview'
import { Route as SitesSiteIDConfigBudgetImport } from './routes/sites/$siteID/config/budget'
import { Route as SitesSiteIDConfigStationsIndexImport } from './routes/sites/$siteID/config/stations/index'
import { Route as SitesSiteIDConfigMetricsIndexImport } from './routes/sites/$siteID/config/metrics/index'
import { Route as SitesSiteIDConfigStationsNewImport } from './routes/sites/$siteID/config/stations/new'
import { Route as SitesSiteIDConfigStationsStationIDImport } from './routes/sites/$siteID/config/stations/$stationID'
import { Route as SitesSiteIDConfigMetricsNewImport } from './routes/sites/$siteID/config/metrics/new'
import { Route as SitesSiteIDConfigMetricsMetricIDImport } from './routes/sites/$siteID/config/metrics/$metricID'

// Create/Update Routes

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const ProfileRoute = ProfileImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const SitesIndexRoute = SitesIndexImport.update({
  id: '/sites/',
  path: '/sites/',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDIndexRoute = SitesSiteIDIndexImport.update({
  id: '/sites/$siteID/',
  path: '/sites/$siteID/',
  getParentRoute: () => rootRoute,
} as any)

const GroupsGroupIDIndexRoute = GroupsGroupIDIndexImport.update({
  id: '/groups/$groupID/',
  path: '/groups/$groupID/',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDReportingRoute = SitesSiteIDReportingImport.update({
  id: '/sites/$siteID/reporting',
  path: '/sites/$siteID/reporting',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDReadingsRoute = SitesSiteIDReadingsImport.update({
  id: '/sites/$siteID/readings',
  path: '/sites/$siteID/readings',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDLeaksRoute = SitesSiteIDLeaksImport.update({
  id: '/sites/$siteID/leaks',
  path: '/sites/$siteID/leaks',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDDashboardRoute = SitesSiteIDDashboardImport.update({
  id: '/sites/$siteID/dashboard',
  path: '/sites/$siteID/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDConfigRoute = SitesSiteIDConfigImport.update({
  id: '/sites/$siteID/config',
  path: '/sites/$siteID/config',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDBudgetPlanningRoute = SitesSiteIDBudgetPlanningImport.update({
  id: '/sites/$siteID/budget-planning',
  path: '/sites/$siteID/budget-planning',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDAlertingRoute = SitesSiteIDAlertingImport.update({
  id: '/sites/$siteID/alerting',
  path: '/sites/$siteID/alerting',
  getParentRoute: () => rootRoute,
} as any)

const GroupsGroupIDReportingRoute = GroupsGroupIDReportingImport.update({
  id: '/groups/$groupID/reporting',
  path: '/groups/$groupID/reporting',
  getParentRoute: () => rootRoute,
} as any)

const GroupsGroupIDReadingsRoute = GroupsGroupIDReadingsImport.update({
  id: '/groups/$groupID/readings',
  path: '/groups/$groupID/readings',
  getParentRoute: () => rootRoute,
} as any)

const GroupsGroupIDDashboardRoute = GroupsGroupIDDashboardImport.update({
  id: '/groups/$groupID/dashboard',
  path: '/groups/$groupID/dashboard',
  getParentRoute: () => rootRoute,
} as any)

const GroupsGroupIDConfigRoute = GroupsGroupIDConfigImport.update({
  id: '/groups/$groupID/config',
  path: '/groups/$groupID/config',
  getParentRoute: () => rootRoute,
} as any)

const GroupsGroupIDBudgetPlanningRoute =
  GroupsGroupIDBudgetPlanningImport.update({
    id: '/groups/$groupID/budget-planning',
    path: '/groups/$groupID/budget-planning',
    getParentRoute: () => rootRoute,
  } as any)

const GroupsGroupIDAlertingRoute = GroupsGroupIDAlertingImport.update({
  id: '/groups/$groupID/alerting',
  path: '/groups/$groupID/alerting',
  getParentRoute: () => rootRoute,
} as any)

const SitesSiteIDConfigIndexRoute = SitesSiteIDConfigIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => SitesSiteIDConfigRoute,
} as any)

const SitesSiteIDConfigOverviewRoute = SitesSiteIDConfigOverviewImport.update({
  id: '/overview',
  path: '/overview',
  getParentRoute: () => SitesSiteIDConfigRoute,
} as any)

const SitesSiteIDConfigBudgetRoute = SitesSiteIDConfigBudgetImport.update({
  id: '/budget',
  path: '/budget',
  getParentRoute: () => SitesSiteIDConfigRoute,
} as any)

const SitesSiteIDConfigStationsIndexRoute =
  SitesSiteIDConfigStationsIndexImport.update({
    id: '/stations/',
    path: '/stations/',
    getParentRoute: () => SitesSiteIDConfigRoute,
  } as any)

const SitesSiteIDConfigMetricsIndexRoute =
  SitesSiteIDConfigMetricsIndexImport.update({
    id: '/metrics/',
    path: '/metrics/',
    getParentRoute: () => SitesSiteIDConfigRoute,
  } as any)

const SitesSiteIDConfigStationsNewRoute =
  SitesSiteIDConfigStationsNewImport.update({
    id: '/stations/new',
    path: '/stations/new',
    getParentRoute: () => SitesSiteIDConfigRoute,
  } as any)

const SitesSiteIDConfigStationsStationIDRoute =
  SitesSiteIDConfigStationsStationIDImport.update({
    id: '/stations/$stationID',
    path: '/stations/$stationID',
    getParentRoute: () => SitesSiteIDConfigRoute,
  } as any)

const SitesSiteIDConfigMetricsNewRoute =
  SitesSiteIDConfigMetricsNewImport.update({
    id: '/metrics/new',
    path: '/metrics/new',
    getParentRoute: () => SitesSiteIDConfigRoute,
  } as any)

const SitesSiteIDConfigMetricsMetricIDRoute =
  SitesSiteIDConfigMetricsMetricIDImport.update({
    id: '/metrics/$metricID',
    path: '/metrics/$metricID',
    getParentRoute: () => SitesSiteIDConfigRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/profile': {
      id: '/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof ProfileImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/sites/': {
      id: '/sites/'
      path: '/sites'
      fullPath: '/sites'
      preLoaderRoute: typeof SitesIndexImport
      parentRoute: typeof rootRoute
    }
    '/groups/$groupID/alerting': {
      id: '/groups/$groupID/alerting'
      path: '/groups/$groupID/alerting'
      fullPath: '/groups/$groupID/alerting'
      preLoaderRoute: typeof GroupsGroupIDAlertingImport
      parentRoute: typeof rootRoute
    }
    '/groups/$groupID/budget-planning': {
      id: '/groups/$groupID/budget-planning'
      path: '/groups/$groupID/budget-planning'
      fullPath: '/groups/$groupID/budget-planning'
      preLoaderRoute: typeof GroupsGroupIDBudgetPlanningImport
      parentRoute: typeof rootRoute
    }
    '/groups/$groupID/config': {
      id: '/groups/$groupID/config'
      path: '/groups/$groupID/config'
      fullPath: '/groups/$groupID/config'
      preLoaderRoute: typeof GroupsGroupIDConfigImport
      parentRoute: typeof rootRoute
    }
    '/groups/$groupID/dashboard': {
      id: '/groups/$groupID/dashboard'
      path: '/groups/$groupID/dashboard'
      fullPath: '/groups/$groupID/dashboard'
      preLoaderRoute: typeof GroupsGroupIDDashboardImport
      parentRoute: typeof rootRoute
    }
    '/groups/$groupID/readings': {
      id: '/groups/$groupID/readings'
      path: '/groups/$groupID/readings'
      fullPath: '/groups/$groupID/readings'
      preLoaderRoute: typeof GroupsGroupIDReadingsImport
      parentRoute: typeof rootRoute
    }
    '/groups/$groupID/reporting': {
      id: '/groups/$groupID/reporting'
      path: '/groups/$groupID/reporting'
      fullPath: '/groups/$groupID/reporting'
      preLoaderRoute: typeof GroupsGroupIDReportingImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/alerting': {
      id: '/sites/$siteID/alerting'
      path: '/sites/$siteID/alerting'
      fullPath: '/sites/$siteID/alerting'
      preLoaderRoute: typeof SitesSiteIDAlertingImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/budget-planning': {
      id: '/sites/$siteID/budget-planning'
      path: '/sites/$siteID/budget-planning'
      fullPath: '/sites/$siteID/budget-planning'
      preLoaderRoute: typeof SitesSiteIDBudgetPlanningImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/config': {
      id: '/sites/$siteID/config'
      path: '/sites/$siteID/config'
      fullPath: '/sites/$siteID/config'
      preLoaderRoute: typeof SitesSiteIDConfigImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/dashboard': {
      id: '/sites/$siteID/dashboard'
      path: '/sites/$siteID/dashboard'
      fullPath: '/sites/$siteID/dashboard'
      preLoaderRoute: typeof SitesSiteIDDashboardImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/leaks': {
      id: '/sites/$siteID/leaks'
      path: '/sites/$siteID/leaks'
      fullPath: '/sites/$siteID/leaks'
      preLoaderRoute: typeof SitesSiteIDLeaksImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/readings': {
      id: '/sites/$siteID/readings'
      path: '/sites/$siteID/readings'
      fullPath: '/sites/$siteID/readings'
      preLoaderRoute: typeof SitesSiteIDReadingsImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/reporting': {
      id: '/sites/$siteID/reporting'
      path: '/sites/$siteID/reporting'
      fullPath: '/sites/$siteID/reporting'
      preLoaderRoute: typeof SitesSiteIDReportingImport
      parentRoute: typeof rootRoute
    }
    '/groups/$groupID/': {
      id: '/groups/$groupID/'
      path: '/groups/$groupID'
      fullPath: '/groups/$groupID'
      preLoaderRoute: typeof GroupsGroupIDIndexImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/': {
      id: '/sites/$siteID/'
      path: '/sites/$siteID'
      fullPath: '/sites/$siteID'
      preLoaderRoute: typeof SitesSiteIDIndexImport
      parentRoute: typeof rootRoute
    }
    '/sites/$siteID/config/budget': {
      id: '/sites/$siteID/config/budget'
      path: '/budget'
      fullPath: '/sites/$siteID/config/budget'
      preLoaderRoute: typeof SitesSiteIDConfigBudgetImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/overview': {
      id: '/sites/$siteID/config/overview'
      path: '/overview'
      fullPath: '/sites/$siteID/config/overview'
      preLoaderRoute: typeof SitesSiteIDConfigOverviewImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/': {
      id: '/sites/$siteID/config/'
      path: '/'
      fullPath: '/sites/$siteID/config/'
      preLoaderRoute: typeof SitesSiteIDConfigIndexImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/metrics/$metricID': {
      id: '/sites/$siteID/config/metrics/$metricID'
      path: '/metrics/$metricID'
      fullPath: '/sites/$siteID/config/metrics/$metricID'
      preLoaderRoute: typeof SitesSiteIDConfigMetricsMetricIDImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/metrics/new': {
      id: '/sites/$siteID/config/metrics/new'
      path: '/metrics/new'
      fullPath: '/sites/$siteID/config/metrics/new'
      preLoaderRoute: typeof SitesSiteIDConfigMetricsNewImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/stations/$stationID': {
      id: '/sites/$siteID/config/stations/$stationID'
      path: '/stations/$stationID'
      fullPath: '/sites/$siteID/config/stations/$stationID'
      preLoaderRoute: typeof SitesSiteIDConfigStationsStationIDImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/stations/new': {
      id: '/sites/$siteID/config/stations/new'
      path: '/stations/new'
      fullPath: '/sites/$siteID/config/stations/new'
      preLoaderRoute: typeof SitesSiteIDConfigStationsNewImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/metrics/': {
      id: '/sites/$siteID/config/metrics/'
      path: '/metrics'
      fullPath: '/sites/$siteID/config/metrics'
      preLoaderRoute: typeof SitesSiteIDConfigMetricsIndexImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
    '/sites/$siteID/config/stations/': {
      id: '/sites/$siteID/config/stations/'
      path: '/stations'
      fullPath: '/sites/$siteID/config/stations'
      preLoaderRoute: typeof SitesSiteIDConfigStationsIndexImport
      parentRoute: typeof SitesSiteIDConfigImport
    }
  }
}

// Create and export the route tree

interface SitesSiteIDConfigRouteChildren {
  SitesSiteIDConfigBudgetRoute: typeof SitesSiteIDConfigBudgetRoute
  SitesSiteIDConfigOverviewRoute: typeof SitesSiteIDConfigOverviewRoute
  SitesSiteIDConfigIndexRoute: typeof SitesSiteIDConfigIndexRoute
  SitesSiteIDConfigMetricsMetricIDRoute: typeof SitesSiteIDConfigMetricsMetricIDRoute
  SitesSiteIDConfigMetricsNewRoute: typeof SitesSiteIDConfigMetricsNewRoute
  SitesSiteIDConfigStationsStationIDRoute: typeof SitesSiteIDConfigStationsStationIDRoute
  SitesSiteIDConfigStationsNewRoute: typeof SitesSiteIDConfigStationsNewRoute
  SitesSiteIDConfigMetricsIndexRoute: typeof SitesSiteIDConfigMetricsIndexRoute
  SitesSiteIDConfigStationsIndexRoute: typeof SitesSiteIDConfigStationsIndexRoute
}

const SitesSiteIDConfigRouteChildren: SitesSiteIDConfigRouteChildren = {
  SitesSiteIDConfigBudgetRoute: SitesSiteIDConfigBudgetRoute,
  SitesSiteIDConfigOverviewRoute: SitesSiteIDConfigOverviewRoute,
  SitesSiteIDConfigIndexRoute: SitesSiteIDConfigIndexRoute,
  SitesSiteIDConfigMetricsMetricIDRoute: SitesSiteIDConfigMetricsMetricIDRoute,
  SitesSiteIDConfigMetricsNewRoute: SitesSiteIDConfigMetricsNewRoute,
  SitesSiteIDConfigStationsStationIDRoute:
    SitesSiteIDConfigStationsStationIDRoute,
  SitesSiteIDConfigStationsNewRoute: SitesSiteIDConfigStationsNewRoute,
  SitesSiteIDConfigMetricsIndexRoute: SitesSiteIDConfigMetricsIndexRoute,
  SitesSiteIDConfigStationsIndexRoute: SitesSiteIDConfigStationsIndexRoute,
}

const SitesSiteIDConfigRouteWithChildren =
  SitesSiteIDConfigRoute._addFileChildren(SitesSiteIDConfigRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/profile': typeof ProfileRoute
  '/settings': typeof SettingsRoute
  '/sites': typeof SitesIndexRoute
  '/groups/$groupID/alerting': typeof GroupsGroupIDAlertingRoute
  '/groups/$groupID/budget-planning': typeof GroupsGroupIDBudgetPlanningRoute
  '/groups/$groupID/config': typeof GroupsGroupIDConfigRoute
  '/groups/$groupID/dashboard': typeof GroupsGroupIDDashboardRoute
  '/groups/$groupID/readings': typeof GroupsGroupIDReadingsRoute
  '/groups/$groupID/reporting': typeof GroupsGroupIDReportingRoute
  '/sites/$siteID/alerting': typeof SitesSiteIDAlertingRoute
  '/sites/$siteID/budget-planning': typeof SitesSiteIDBudgetPlanningRoute
  '/sites/$siteID/config': typeof SitesSiteIDConfigRouteWithChildren
  '/sites/$siteID/dashboard': typeof SitesSiteIDDashboardRoute
  '/sites/$siteID/leaks': typeof SitesSiteIDLeaksRoute
  '/sites/$siteID/readings': typeof SitesSiteIDReadingsRoute
  '/sites/$siteID/reporting': typeof SitesSiteIDReportingRoute
  '/groups/$groupID': typeof GroupsGroupIDIndexRoute
  '/sites/$siteID': typeof SitesSiteIDIndexRoute
  '/sites/$siteID/config/budget': typeof SitesSiteIDConfigBudgetRoute
  '/sites/$siteID/config/overview': typeof SitesSiteIDConfigOverviewRoute
  '/sites/$siteID/config/': typeof SitesSiteIDConfigIndexRoute
  '/sites/$siteID/config/metrics/$metricID': typeof SitesSiteIDConfigMetricsMetricIDRoute
  '/sites/$siteID/config/metrics/new': typeof SitesSiteIDConfigMetricsNewRoute
  '/sites/$siteID/config/stations/$stationID': typeof SitesSiteIDConfigStationsStationIDRoute
  '/sites/$siteID/config/stations/new': typeof SitesSiteIDConfigStationsNewRoute
  '/sites/$siteID/config/metrics': typeof SitesSiteIDConfigMetricsIndexRoute
  '/sites/$siteID/config/stations': typeof SitesSiteIDConfigStationsIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/profile': typeof ProfileRoute
  '/settings': typeof SettingsRoute
  '/sites': typeof SitesIndexRoute
  '/groups/$groupID/alerting': typeof GroupsGroupIDAlertingRoute
  '/groups/$groupID/budget-planning': typeof GroupsGroupIDBudgetPlanningRoute
  '/groups/$groupID/config': typeof GroupsGroupIDConfigRoute
  '/groups/$groupID/dashboard': typeof GroupsGroupIDDashboardRoute
  '/groups/$groupID/readings': typeof GroupsGroupIDReadingsRoute
  '/groups/$groupID/reporting': typeof GroupsGroupIDReportingRoute
  '/sites/$siteID/alerting': typeof SitesSiteIDAlertingRoute
  '/sites/$siteID/budget-planning': typeof SitesSiteIDBudgetPlanningRoute
  '/sites/$siteID/dashboard': typeof SitesSiteIDDashboardRoute
  '/sites/$siteID/leaks': typeof SitesSiteIDLeaksRoute
  '/sites/$siteID/readings': typeof SitesSiteIDReadingsRoute
  '/sites/$siteID/reporting': typeof SitesSiteIDReportingRoute
  '/groups/$groupID': typeof GroupsGroupIDIndexRoute
  '/sites/$siteID': typeof SitesSiteIDIndexRoute
  '/sites/$siteID/config/budget': typeof SitesSiteIDConfigBudgetRoute
  '/sites/$siteID/config/overview': typeof SitesSiteIDConfigOverviewRoute
  '/sites/$siteID/config': typeof SitesSiteIDConfigIndexRoute
  '/sites/$siteID/config/metrics/$metricID': typeof SitesSiteIDConfigMetricsMetricIDRoute
  '/sites/$siteID/config/metrics/new': typeof SitesSiteIDConfigMetricsNewRoute
  '/sites/$siteID/config/stations/$stationID': typeof SitesSiteIDConfigStationsStationIDRoute
  '/sites/$siteID/config/stations/new': typeof SitesSiteIDConfigStationsNewRoute
  '/sites/$siteID/config/metrics': typeof SitesSiteIDConfigMetricsIndexRoute
  '/sites/$siteID/config/stations': typeof SitesSiteIDConfigStationsIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/profile': typeof ProfileRoute
  '/settings': typeof SettingsRoute
  '/sites/': typeof SitesIndexRoute
  '/groups/$groupID/alerting': typeof GroupsGroupIDAlertingRoute
  '/groups/$groupID/budget-planning': typeof GroupsGroupIDBudgetPlanningRoute
  '/groups/$groupID/config': typeof GroupsGroupIDConfigRoute
  '/groups/$groupID/dashboard': typeof GroupsGroupIDDashboardRoute
  '/groups/$groupID/readings': typeof GroupsGroupIDReadingsRoute
  '/groups/$groupID/reporting': typeof GroupsGroupIDReportingRoute
  '/sites/$siteID/alerting': typeof SitesSiteIDAlertingRoute
  '/sites/$siteID/budget-planning': typeof SitesSiteIDBudgetPlanningRoute
  '/sites/$siteID/config': typeof SitesSiteIDConfigRouteWithChildren
  '/sites/$siteID/dashboard': typeof SitesSiteIDDashboardRoute
  '/sites/$siteID/leaks': typeof SitesSiteIDLeaksRoute
  '/sites/$siteID/readings': typeof SitesSiteIDReadingsRoute
  '/sites/$siteID/reporting': typeof SitesSiteIDReportingRoute
  '/groups/$groupID/': typeof GroupsGroupIDIndexRoute
  '/sites/$siteID/': typeof SitesSiteIDIndexRoute
  '/sites/$siteID/config/budget': typeof SitesSiteIDConfigBudgetRoute
  '/sites/$siteID/config/overview': typeof SitesSiteIDConfigOverviewRoute
  '/sites/$siteID/config/': typeof SitesSiteIDConfigIndexRoute
  '/sites/$siteID/config/metrics/$metricID': typeof SitesSiteIDConfigMetricsMetricIDRoute
  '/sites/$siteID/config/metrics/new': typeof SitesSiteIDConfigMetricsNewRoute
  '/sites/$siteID/config/stations/$stationID': typeof SitesSiteIDConfigStationsStationIDRoute
  '/sites/$siteID/config/stations/new': typeof SitesSiteIDConfigStationsNewRoute
  '/sites/$siteID/config/metrics/': typeof SitesSiteIDConfigMetricsIndexRoute
  '/sites/$siteID/config/stations/': typeof SitesSiteIDConfigStationsIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/profile'
    | '/settings'
    | '/sites'
    | '/groups/$groupID/alerting'
    | '/groups/$groupID/budget-planning'
    | '/groups/$groupID/config'
    | '/groups/$groupID/dashboard'
    | '/groups/$groupID/readings'
    | '/groups/$groupID/reporting'
    | '/sites/$siteID/alerting'
    | '/sites/$siteID/budget-planning'
    | '/sites/$siteID/config'
    | '/sites/$siteID/dashboard'
    | '/sites/$siteID/leaks'
    | '/sites/$siteID/readings'
    | '/sites/$siteID/reporting'
    | '/groups/$groupID'
    | '/sites/$siteID'
    | '/sites/$siteID/config/budget'
    | '/sites/$siteID/config/overview'
    | '/sites/$siteID/config/'
    | '/sites/$siteID/config/metrics/$metricID'
    | '/sites/$siteID/config/metrics/new'
    | '/sites/$siteID/config/stations/$stationID'
    | '/sites/$siteID/config/stations/new'
    | '/sites/$siteID/config/metrics'
    | '/sites/$siteID/config/stations'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/profile'
    | '/settings'
    | '/sites'
    | '/groups/$groupID/alerting'
    | '/groups/$groupID/budget-planning'
    | '/groups/$groupID/config'
    | '/groups/$groupID/dashboard'
    | '/groups/$groupID/readings'
    | '/groups/$groupID/reporting'
    | '/sites/$siteID/alerting'
    | '/sites/$siteID/budget-planning'
    | '/sites/$siteID/dashboard'
    | '/sites/$siteID/leaks'
    | '/sites/$siteID/readings'
    | '/sites/$siteID/reporting'
    | '/groups/$groupID'
    | '/sites/$siteID'
    | '/sites/$siteID/config/budget'
    | '/sites/$siteID/config/overview'
    | '/sites/$siteID/config'
    | '/sites/$siteID/config/metrics/$metricID'
    | '/sites/$siteID/config/metrics/new'
    | '/sites/$siteID/config/stations/$stationID'
    | '/sites/$siteID/config/stations/new'
    | '/sites/$siteID/config/metrics'
    | '/sites/$siteID/config/stations'
  id:
    | '__root__'
    | '/'
    | '/profile'
    | '/settings'
    | '/sites/'
    | '/groups/$groupID/alerting'
    | '/groups/$groupID/budget-planning'
    | '/groups/$groupID/config'
    | '/groups/$groupID/dashboard'
    | '/groups/$groupID/readings'
    | '/groups/$groupID/reporting'
    | '/sites/$siteID/alerting'
    | '/sites/$siteID/budget-planning'
    | '/sites/$siteID/config'
    | '/sites/$siteID/dashboard'
    | '/sites/$siteID/leaks'
    | '/sites/$siteID/readings'
    | '/sites/$siteID/reporting'
    | '/groups/$groupID/'
    | '/sites/$siteID/'
    | '/sites/$siteID/config/budget'
    | '/sites/$siteID/config/overview'
    | '/sites/$siteID/config/'
    | '/sites/$siteID/config/metrics/$metricID'
    | '/sites/$siteID/config/metrics/new'
    | '/sites/$siteID/config/stations/$stationID'
    | '/sites/$siteID/config/stations/new'
    | '/sites/$siteID/config/metrics/'
    | '/sites/$siteID/config/stations/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  ProfileRoute: typeof ProfileRoute
  SettingsRoute: typeof SettingsRoute
  SitesIndexRoute: typeof SitesIndexRoute
  GroupsGroupIDAlertingRoute: typeof GroupsGroupIDAlertingRoute
  GroupsGroupIDBudgetPlanningRoute: typeof GroupsGroupIDBudgetPlanningRoute
  GroupsGroupIDConfigRoute: typeof GroupsGroupIDConfigRoute
  GroupsGroupIDDashboardRoute: typeof GroupsGroupIDDashboardRoute
  GroupsGroupIDReadingsRoute: typeof GroupsGroupIDReadingsRoute
  GroupsGroupIDReportingRoute: typeof GroupsGroupIDReportingRoute
  SitesSiteIDAlertingRoute: typeof SitesSiteIDAlertingRoute
  SitesSiteIDBudgetPlanningRoute: typeof SitesSiteIDBudgetPlanningRoute
  SitesSiteIDConfigRoute: typeof SitesSiteIDConfigRouteWithChildren
  SitesSiteIDDashboardRoute: typeof SitesSiteIDDashboardRoute
  SitesSiteIDLeaksRoute: typeof SitesSiteIDLeaksRoute
  SitesSiteIDReadingsRoute: typeof SitesSiteIDReadingsRoute
  SitesSiteIDReportingRoute: typeof SitesSiteIDReportingRoute
  GroupsGroupIDIndexRoute: typeof GroupsGroupIDIndexRoute
  SitesSiteIDIndexRoute: typeof SitesSiteIDIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  ProfileRoute: ProfileRoute,
  SettingsRoute: SettingsRoute,
  SitesIndexRoute: SitesIndexRoute,
  GroupsGroupIDAlertingRoute: GroupsGroupIDAlertingRoute,
  GroupsGroupIDBudgetPlanningRoute: GroupsGroupIDBudgetPlanningRoute,
  GroupsGroupIDConfigRoute: GroupsGroupIDConfigRoute,
  GroupsGroupIDDashboardRoute: GroupsGroupIDDashboardRoute,
  GroupsGroupIDReadingsRoute: GroupsGroupIDReadingsRoute,
  GroupsGroupIDReportingRoute: GroupsGroupIDReportingRoute,
  SitesSiteIDAlertingRoute: SitesSiteIDAlertingRoute,
  SitesSiteIDBudgetPlanningRoute: SitesSiteIDBudgetPlanningRoute,
  SitesSiteIDConfigRoute: SitesSiteIDConfigRouteWithChildren,
  SitesSiteIDDashboardRoute: SitesSiteIDDashboardRoute,
  SitesSiteIDLeaksRoute: SitesSiteIDLeaksRoute,
  SitesSiteIDReadingsRoute: SitesSiteIDReadingsRoute,
  SitesSiteIDReportingRoute: SitesSiteIDReportingRoute,
  GroupsGroupIDIndexRoute: GroupsGroupIDIndexRoute,
  SitesSiteIDIndexRoute: SitesSiteIDIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/profile",
        "/settings",
        "/sites/",
        "/groups/$groupID/alerting",
        "/groups/$groupID/budget-planning",
        "/groups/$groupID/config",
        "/groups/$groupID/dashboard",
        "/groups/$groupID/readings",
        "/groups/$groupID/reporting",
        "/sites/$siteID/alerting",
        "/sites/$siteID/budget-planning",
        "/sites/$siteID/config",
        "/sites/$siteID/dashboard",
        "/sites/$siteID/leaks",
        "/sites/$siteID/readings",
        "/sites/$siteID/reporting",
        "/groups/$groupID/",
        "/sites/$siteID/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/profile": {
      "filePath": "profile.tsx"
    },
    "/settings": {
      "filePath": "settings.tsx"
    },
    "/sites/": {
      "filePath": "sites/index.tsx"
    },
    "/groups/$groupID/alerting": {
      "filePath": "groups/$groupID/alerting.tsx"
    },
    "/groups/$groupID/budget-planning": {
      "filePath": "groups/$groupID/budget-planning.tsx"
    },
    "/groups/$groupID/config": {
      "filePath": "groups/$groupID/config.tsx"
    },
    "/groups/$groupID/dashboard": {
      "filePath": "groups/$groupID/dashboard.tsx"
    },
    "/groups/$groupID/readings": {
      "filePath": "groups/$groupID/readings.tsx"
    },
    "/groups/$groupID/reporting": {
      "filePath": "groups/$groupID/reporting.tsx"
    },
    "/sites/$siteID/alerting": {
      "filePath": "sites/$siteID/alerting.tsx"
    },
    "/sites/$siteID/budget-planning": {
      "filePath": "sites/$siteID/budget-planning.tsx"
    },
    "/sites/$siteID/config": {
      "filePath": "sites/$siteID/config.tsx",
      "children": [
        "/sites/$siteID/config/budget",
        "/sites/$siteID/config/overview",
        "/sites/$siteID/config/",
        "/sites/$siteID/config/metrics/$metricID",
        "/sites/$siteID/config/metrics/new",
        "/sites/$siteID/config/stations/$stationID",
        "/sites/$siteID/config/stations/new",
        "/sites/$siteID/config/metrics/",
        "/sites/$siteID/config/stations/"
      ]
    },
    "/sites/$siteID/dashboard": {
      "filePath": "sites/$siteID/dashboard.tsx"
    },
    "/sites/$siteID/leaks": {
      "filePath": "sites/$siteID/leaks.tsx"
    },
    "/sites/$siteID/readings": {
      "filePath": "sites/$siteID/readings.tsx"
    },
    "/sites/$siteID/reporting": {
      "filePath": "sites/$siteID/reporting.tsx"
    },
    "/groups/$groupID/": {
      "filePath": "groups/$groupID/index.tsx"
    },
    "/sites/$siteID/": {
      "filePath": "sites/$siteID/index.tsx"
    },
    "/sites/$siteID/config/budget": {
      "filePath": "sites/$siteID/config/budget.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/overview": {
      "filePath": "sites/$siteID/config/overview.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/": {
      "filePath": "sites/$siteID/config/index.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/metrics/$metricID": {
      "filePath": "sites/$siteID/config/metrics/$metricID.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/metrics/new": {
      "filePath": "sites/$siteID/config/metrics/new.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/stations/$stationID": {
      "filePath": "sites/$siteID/config/stations/$stationID.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/stations/new": {
      "filePath": "sites/$siteID/config/stations/new.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/metrics/": {
      "filePath": "sites/$siteID/config/metrics/index.tsx",
      "parent": "/sites/$siteID/config"
    },
    "/sites/$siteID/config/stations/": {
      "filePath": "sites/$siteID/config/stations/index.tsx",
      "parent": "/sites/$siteID/config"
    }
  }
}
ROUTE_MANIFEST_END */
