import { ArrowRightIcon } from '@radix-ui/react-icons'
import { Button, Flex, Separator, Spinner, Text } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute, Link } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '~/components/EmptyState'
import { SectionCard } from '~/components/SectionCard'
import { StationsConsumptionChart } from '~/features/dashboarding/components/StationsConsumptionChart'
import { listSiteMetrics } from '~/features/dashboarding/service'
import { listSiteWaterStations } from '~/features/mapping/service'

const PageComponent = () => {
  const { t } = useTranslation('sites')
  const { siteID } = Route.useParams()

  const { data: metrics = [], isLoading: metricsLoading } = useQuery({
    queryKey: ['site-metrics', siteID],
    queryFn: () => listSiteMetrics(siteID)
  })

  const { data: waterStations = [], isLoading: stationsLoading } = useQuery({
    queryKey: ['stations', siteID],
    queryFn: () => listSiteWaterStations({ siteId: siteID })
  })

  const isLoading = metricsLoading || stationsLoading

  return (
    <SectionCard title={t('metrics.index.title')} className={metrics?.length === 0 ? 'h-full' : ''}>
      <Separator size="4" my="2" />
      <Text size="2" color="gray" mb={'3'}>
        {t('metrics.index.description')}
      </Text>
      {isLoading ? (
        <Spinner size="2" />
      ) : metrics?.length === 0 ? (
        <EmptyState
          title={t('metrics.index.emptyState.title')}
          description={t('metrics.index.emptyState.description')}
          afterBody={
            <Link to={'/sites/$siteID/config/metrics/new'} params={{ siteID }}>
              <Button size={'2'}>{t('metrics.index.emptyState.createButton')}</Button>
            </Link>
          }
        />
      ) : (
        <Flex direction={'column'} gap="4">
          {metrics.map((metric, idx) => (
            <div key={metric.id} className="group">
              <Flex justify={'end'} align={'center'}>
                <Link
                  className="mt-1 group-hover:visible invisible mr-2"
                  to="/sites/$siteID/config/metrics/$metricID"
                  params={{ siteID, metricID: metric.id }}
                >
                  <Button variant="ghost">
                    {t('dashboard.actions.editMetric')} <ArrowRightIcon />{' '}
                  </Button>
                </Link>
              </Flex>
              <StationsConsumptionChart
                {...{
                  yAxisLabel: t('stations.leakSection.chart.yAxisLabel'),
                  showLegend: true,
                  showAnimation: true,
                  showGridLines: false,
                  showYAxis: true,
                  startDate: DateTime.now().minus({ months: 1 }).startOf('day'),
                  endDate: DateTime.now()
                }}
                chartKey={metric.id}
                title={metric.name}
                stations={waterStations.filter((s) =>
                  metric.stationOperations.map((op) => op.stationId).includes(s.id)
                )}
                chartType={metric.chartType}
                partitionKey={null}
              />
              {idx < metrics.length - 1 && <Separator size="4" mt="6" />}
            </div>
          ))}
        </Flex>
      )}
    </SectionCard>
  )
}

export const Route = createFileRoute('/sites/$siteID/config/metrics/')({
  component: PageComponent
})
