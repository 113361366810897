import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Flex, Grid, Strong, Text, TextField } from '@radix-ui/themes'
import { DateRangePicker, DateRangePickerProps } from '@tremor/react'
import { useForm } from 'react-hook-form'
import { DateTime } from 'luxon'
import {
  createBudgetItemSchema,
  CreateBudgetItemSchema,
  BudgetItemSchema,
  BudgetItemType,
  budgetItemSchema
} from '../schemas'
import { useState } from 'react'
import { createBudgetItem, deleteBudgetItem, updateBudgetItem } from '../service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

type CommonBudgetItemFormProps = {
  onSubmit: (data: CreateBudgetItemSchema | BudgetItemSchema) => void
  isPending: boolean
  siteID: string
  siteCost: number | null
  type: BudgetItemType
}

type BudgetItemFormProps = CommonBudgetItemFormProps &
  (CreateBudgetItemFormProps | UpdateBudgetItemFormProps) & {
    onCancel: () => void
  }

const BudgetItemForm = ({
  budgetItem,
  isPending,
  siteID,
  onSubmit,
  onCancel,
  type,
  siteCost
}: BudgetItemFormProps) => {
  const { t } = useTranslation('sites')
  const schema = budgetItem ? budgetItemSchema : createBudgetItemSchema
  const allowFutureDates = type === 'objective'
  const bindCostAndConsumption = type === 'objective' && !!siteCost

  const {
    register,
    handleSubmit,
    formState: { isValid },
    watch,
    setValue
  } = useForm<BudgetItemSchema>({
    resolver: zodResolver(schema),
    defaultValues: budgetItem || {
      siteID,
      type
    },
    mode: 'onChange'
  })

  const [startDate, endDate] = watch(['startDate', 'endDate'])
  const handleDateRangePickerChanged: DateRangePickerProps['onValueChange'] = ({ from, to }) => {
    from && setValue('startDate', from, { shouldValidate: true })
    to && setValue('endDate', to, { shouldValidate: true })
  }

  const handleCostChange = (cost: number) => {
    if (bindCostAndConsumption) {
      const consumption = Math.round(cost / (siteCost || 1))
      setValue('consumption', consumption, { shouldValidate: true })
    }
  }

  const handleVolumeChange = (volume: number) => {
    if (bindCostAndConsumption) {
      const cost = _.round(volume * siteCost, 2)
      setValue('cost', cost, { shouldValidate: true })
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="grid px-2 py-1 h-10 col-[1/-1] grid-cols-subgrid items-center"
    >
      <DateRangePicker
        enableClear={false}
        enableSelect={false}
        placeholder={t('budget.invoiceSection.form.periodPlaceholder')}
        maxDate={!allowFutureDates ? DateTime.now().toJSDate() : undefined}
        className="[&_button]:p-[6px] [&_button]:bg-white basis-0 grow"
        onValueChange={handleDateRangePickerChanged}
        value={{ from: startDate, to: endDate }}
      />
      <TextField.Root
        placeholder={t('budget.invoiceSection.form.volumePlaceholder')}
        type="number"
        min={0}
        {...register('consumption', { valueAsNumber: true })}
        onChange={(e) => handleVolumeChange(e.target.valueAsNumber)}
        className="basis-0 grow"
      >
        <TextField.Slot className="text-gray-8">
          <Strong>
            m<sup>3</sup>
          </Strong>
        </TextField.Slot>
      </TextField.Root>
      <TextField.Root
        placeholder={t('budget.invoiceSection.form.costPlaceholder')}
        type="number"
        min={0}
        step={0.1}
        {...register('cost', { valueAsNumber: true })}
        onChange={(e) => handleCostChange(e.target.valueAsNumber)}
        className="basis-0 grow"
      >
        <TextField.Slot className="text-gray-8">
          <Strong>€</Strong>
        </TextField.Slot>
      </TextField.Root>
      <Flex gap="2">
        <Button size={'1'} variant="soft" color="gray" onClick={onCancel}>
          {t('budget.invoiceSection.form.cancel')}
        </Button>
        <Button size={'1'} type="submit" disabled={!isValid} className="w-fit" loading={isPending}>
          {t('budget.invoiceSection.form.submit')}
        </Button>
      </Flex>
    </form>
  )
}

type CreateBudgetItemFormProps = {
  budgetItem: null
  onCancel: () => void
  onDone: () => void
}

export const CreateBudgetItemForm = ({
  ...props
}: CreateBudgetItemFormProps & Omit<CommonBudgetItemFormProps, 'onSubmit' | 'isPending'>) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation('sites')

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: createBudgetItem,
    onSuccess: () => {
      toast.success(t('budget.invoiceSection.messages.createSuccess'))
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          const isBudgetItemsQuery = queryKey[0] === 'site-invoices' || queryKey[0] === 'site-budget-objectives'
          return isBudgetItemsQuery && queryKey[1] === props.siteID
        }
      })
      props.onDone()
    }
  })

  return <BudgetItemForm {...props} onSubmit={onSubmit} isPending={isPending} />
}

type UpdateBudgetItemFormProps = {
  budgetItem: BudgetItemSchema
}

export const UpdateBudgetItemForm = (
  props: UpdateBudgetItemFormProps & Omit<CommonBudgetItemFormProps, 'onSubmit' | 'isPending' | 'type'>
) => {
  const [isEditing, setIsEditing] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation('sites')

  const { mutate: onSubmit, isPending } = useMutation({
    mutationFn: updateBudgetItem,
    onSuccess: () => {
      toast.success(t('budget.invoiceSection.messages.updateSuccess'))
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          const isBudgetItemsQuery = queryKey[0] === 'site-invoices' || queryKey[0] === 'site-budget-objectives'
          return isBudgetItemsQuery && queryKey[1] === props.siteID
        }
      })
      setIsEditing(false)
    }
  })

  const { mutate: deleteItem, isPending: isDeleting } = useMutation({
    mutationFn: deleteBudgetItem,
    onSuccess: () => {
      toast.success(t('budget.invoiceSection.messages.deleteSuccess'))
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          const isBudgetItemsQuery = queryKey[0] === 'site-invoices' || queryKey[0] === 'site-budget-objectives'
          return isBudgetItemsQuery && queryKey[1] === props.siteID
        }
      })
    }
  })

  const handleDelete = () => {
    window.confirm(t('budget.invoiceSection.form.confirmDelete')) && deleteItem(props.budgetItem.id)
  }

  return isEditing ? (
    <BudgetItemForm
      {...props}
      onSubmit={(data) => onSubmit({ ...data, id: props.budgetItem.id })}
      isPending={isPending}
      onCancel={() => setIsEditing(false)}
      type={props.budgetItem.type}
    />
  ) : (
    <BudgetItem
      budgetItem={props.budgetItem}
      actionButtons={
        <>
          <Button size="1" color="gray" variant="soft" onClick={handleDelete} loading={isDeleting}>
            {t('budget.invoiceSection.form.deleteButton')}
          </Button>
          <Button size="1" variant="soft" onClick={() => setIsEditing(true)}>
            {t('budget.invoiceSection.form.editButton')}
          </Button>
        </>
      }
    />
  )
}

type BudgetItemProps = {
  budgetItem: BudgetItemSchema
  actionButtons?: React.ReactNode
}

export const BudgetItem = (props: BudgetItemProps) => (
  <Grid className="p-2 h-10 col-[1/-1] grid-cols-subgrid text-center">
    <Text size={'2'}>
      {DateTime.fromJSDate(props.budgetItem.startDate).toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })}{' '}
      -{' '}
      {DateTime.fromJSDate(props.budgetItem.endDate).toLocaleString({
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      })}
    </Text>
    <Text size={'2'}>{props.budgetItem.consumption.toLocaleString()}</Text>
    <Text size={'2'}>{props.budgetItem.cost.toLocaleString()}</Text>
    <Flex gap={'2'}>{props.actionButtons ?? null}</Flex>
  </Grid>
)
