import { createClient, SupabaseClient, SupabaseClientOptions } from '@supabase/supabase-js'
import { Database } from './types.gen'

export type WillieSupabaseClient = SupabaseClient<Database>

export type SupabaseConfig = {
  supabaseUrl?: string
  projectId?: string
  anonKey?: string
  options?: SupabaseClientOptions<'public'>
}

export const makeSupabaseClient = ({
  projectId = process.env.SUPABASE_PROJECT_ID ?? '',
  anonKey = process.env.SUPABASE_ANON_KEY ?? '',
  supabaseUrl,
  options
}: SupabaseConfig) => {
  if (!projectId && !supabaseUrl) {
    throw new Error('Supabase project ID or URL is required')
  }

  const url = supabaseUrl || `https://${projectId}.supabase.co`
  return createClient<Database>(url, anonKey, options)
}

export * from './types.gen'
