import { SectionCard } from './SectionCard'
import { EmptyState } from './EmptyState'

const GroupEmptyState = () => {
  return (
    <SectionCard title="" className="h-full">
      <EmptyState
        title="Vue de groupe non disponible"
        description="La vue de groupe n'est disponible que pour les organisations ayant au moins un compteur télérelevé dans 5 sites ou plus."
      />
    </SectionCard>
  )
}

export default GroupEmptyState
