import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useRouteContext } from '@tanstack/react-router'
import { cloneElement, useState } from 'react'
import { useForm } from 'react-hook-form'
import { createSite } from '../service'
import { Button, Dialog, Flex, Text, TextArea, TextField } from '@radix-ui/themes'
import { CreateOrUpdateSiteSchema, createSiteSchema, CreateSiteSchema } from '../schemas'
import { useTranslation } from 'react-i18next'

interface CreateSiteDialogProps {
  children: React.ReactElement
}

const CreateSiteDialog = ({ children }: CreateSiteDialogProps) => {
  const { t } = useTranslation('sites')
  const [open, setOpen] = useState(false)
  const { queryClient, org } = useRouteContext({
    from: '/sites/'
  })
  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty }
  } = useForm<CreateSiteSchema>({
    resolver: zodResolver(createSiteSchema),
    defaultValues: {
      orgID: org.id
    }
  })

  const { mutate, isPending } = useMutation({
    mutationFn: createSite,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sites'] })
      setOpen(false)

      // TODO close the dialog
    },
    onError: (error) => {
      console.error('Error creating site', error)
      // TODO set erros in the form
    }
  })

  const onSubmit = async (data: CreateOrUpdateSiteSchema) => {
    mutate({ ...data, orgID: org.id })
  }

  const trigger = cloneElement(children, { onClick: () => setOpen(true) })

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger>{trigger}</Dialog.Trigger>
      <Dialog.Content size={'4'}>
        <Dialog.Title>{t('sites.form.title')}</Dialog.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap="3" direction="column">
            <div>
              <Text color="gray">{t('sites.form.fields.name.label')}</Text>
              <TextField.Root
                variant="surface"
                placeholder={t('sites.form.fields.name.placeholder')}
                {...register('name')}
              />
            </div>
            <div>
              <Text color="gray">{t('sites.form.fields.description.label')}</Text>
              <TextArea
                variant="surface"
                placeholder={t('sites.form.fields.description.placeholder')}
                {...register('description')}
              />
            </div>
            {/* TODO use mapbox or google geocoding autocomplete box */}
            <div>
              <Text color="gray">{t('sites.form.fields.address.label')}</Text>
              <TextField.Root
                variant="surface"
                placeholder={t('sites.form.fields.address.placeholder')}
                {...register('address')}
              />
            </div>

            <Button type="submit" size={'3'} loading={isPending} disabled={!isDirty || !isValid}>
              {t('sites.form.buttons.create')}
            </Button>
          </Flex>
        </form>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export { CreateSiteDialog, type CreateSiteDialogProps }
