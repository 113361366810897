import { Badge, Card, Flex, Spinner, Text, Tooltip } from '@radix-ui/themes'
import { SparkAreaChart } from '@tremor/react'
import { MetricSchema } from '../schemas'
import { L_TO_M3, WILLIE_BRAND_COLOR } from '~/constants'
import { DateTime } from 'luxon'
import { SiteSummary } from '~/features/sites/service'
import { WaterStation } from '~/features/mapping/schemas'
import { listDataPointsForMeters } from '~/features/meters/service'
import { useQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

type MetricSummaryCardProps = {
  metric: MetricSchema
  site: SiteSummary
}

type StationWithMeter = Omit<WaterStation, 'meter_id'> & { meter_id: number }

// TODO account for operation station operation (add, subtract)
export const MetricSummaryCard = ({ metric, site }: MetricSummaryCardProps) => {
  const { t } = useTranslation('sites')
  const period = metric.summaryCardConfig.period

  const startPreviousPeriod = DateTime.now()
    .startOf(period)
    .minus({ [period]: 1 })
  const endPreviousPeriod = DateTime.now()
    .endOf(period)
    .minus({ [period]: 1 })
  const startCurrentPeriod = DateTime.now().startOf(period)
  const endCurrentPeriod = DateTime.now().endOf(period)

  // @ts-ignore
  const concernedStations = metric.stationOperations
    .map(({ stationId }) => site.stations.find(({ id }) => id === stationId))
    .filter((s) => !!s && s.meter_id) as StationWithMeter[]

  const meterIds = concernedStations.map(({ meter_id }) => meter_id)
  const { data: metersData, isLoading } = useQuery({
    queryKey: [metric.id, { startDate: startPreviousPeriod.toISODate(), endDate: endCurrentPeriod.toISODate() }],
    queryFn: () => listDataPointsForMeters({ meterIds, startDate: startPreviousPeriod, endDate: endCurrentPeriod })
  })

  // compute daily data for each meter (station) given the reading method of each meter
  // this is almost the same thing we do in the hook, and it should be refactored in the service instead
  const dailyData = (metersData || [])
    .map((m) => {
      const station = concernedStations.find((s) => s.meter_id === m.id)
      if (!station) {
        return []
      }

      return _(m.datapoints)
        .groupBy((d) => d.dateTime.toISODate())
        .mapValues((points) => {
          const value =
            m.reading_method === 'incremental'
              ? _.sumBy(points, 'value')
              : (_.first(points)?.value ?? 0) - (_.last(points)?.value ?? 0)

          const conversion = m.unit === 'l' ? value * L_TO_M3 : value
          return conversion
        })
        .toPairs()
        .map(([date, value]) => ({
          date,
          value,
          stationID: station.id
        }))
        .value()
    })
    .flat()

  const currentPeriodData = dailyData
    .filter(({ date }) => DateTime.fromISO(date) >= startCurrentPeriod)
    .map((d) => ({ ...d, label: 'current' }))

  // shift the previous period data to match the current period (so the dates overlap on the chart for comparison and for the sums)
  const shiftedPreviousPeriodData = dailyData
    .filter(({ date }) => DateTime.fromISO(date) < endPreviousPeriod)
    .map(({ date, ...d }) => ({
      ...d,
      date: DateTime.fromISO(date)
        .plus({ [period]: 1 })
        .toISODate()!,
      label: 'previous'
    }))

  // cutoff sum is the sum of the previous data for the same completed time as the current period
  // ex: 4 days out of 7 in the current period, the cutoff sum is the sum of the first 4 days in the previous period
  const cutoffPreviousSum = _.sumBy(
    shiftedPreviousPeriodData.filter(({ date }) => DateTime.fromISO(date) <= DateTime.now().endOf('day')),
    'value'
  )

  const currentPeriodSum = _.sumBy(currentPeriodData, 'value')
  const percentageChange = Math.round(((currentPeriodSum - cutoffPreviousSum) / cutoffPreviousSum) * 100)

  // prepare the data for the chart (this is the same thing as what we have in the useStationsChartData hook)
  // there must be a better way to do this, let's combine the code
  const chartData = _([...shiftedPreviousPeriodData, ...currentPeriodData])
    .groupBy('date')
    .mapValues(
      (d) =>
        _(d)
          .groupBy('label')
          .mapValues((d) => _.sumBy(d, 'value'))
          .value() satisfies Record<string, number>
    )
    .toPairs()
    .orderBy(0)
    .map(([date, value]) => ({
      date,
      ...value
    }))
    .value()

  const categories = ['previous', 'current']

  return (
    <Card className="sm:flex-grow md:w-[30%]" size={'2'}>
      <Flex direction={'column'} p="1" gap={'1'}>
        <Flex justify={'between'}>
          <Text color="gray" size={'2'}>
            {metric.name}
          </Text>
          {_.isFinite(percentageChange) && (
            <Tooltip
              content={t('metrics.form.summaryCard.tooltips.periodDifference', { period })}
              delayDuration={500}
            >
              <Badge color="sky" className="ml-auto2">
                {percentageChange === 0 ? '' : percentageChange > 0 ? '+' : '-'}&nbsp;{Math.abs(percentageChange)}%
              </Badge>
            </Tooltip>
          )}
        </Flex>
        <span>
          <Tooltip
            content={t('metrics.form.summaryCard.tooltips.totalConsumption', { period })}
            delayDuration={500}
          >
            <Text size={'6'} weight={'bold'}>
              {currentPeriodSum.toLocaleString('Fr')}
            </Text>
          </Tooltip>
          <Text size={'5'} ml={'1'}>
            m<sup>3</sup>
          </Text>
        </span>
      </Flex>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <SparkAreaChart
            data={chartData ?? []}
            index="date"
            categories={categories}
            showGradient={false}
            colors={['gray-200', WILLIE_BRAND_COLOR]}
            className="mt-4 h-16 w-full"
            noDataText={t('metrics.form.summaryCard.noData')}
          />
          <Flex justify={'between'} mt={'1'}>
            <Text color="gray" size={'1'}>
              {startCurrentPeriod.toLocaleString({ month: 'short', day: 'numeric' })}
            </Text>
            <Text color="gray" size={'1'}>
              {endCurrentPeriod.toLocaleString({ month: 'short', day: 'numeric' })}
            </Text>
          </Flex>
        </>
      )}
    </Card>
  )
}
