import { useTranslation } from 'react-i18next'
import { NotificationLevel } from '../service'
import { LuFlame, LuInfo, LuRocket, LuThumbsUp, LuTriangleAlert } from 'react-icons/lu'
import { IconBaseProps } from 'react-icons/lib'
import { Tooltip } from '@radix-ui/themes'
import { cloneElement } from 'react'

type Props = {
  level: NotificationLevel
} & IconBaseProps

const ICONS: Record<NotificationLevel, JSX.Element> = {
  good: <LuThumbsUp />,
  critical: <LuFlame />,
  warning: <LuTriangleAlert />,
  info: <LuInfo />,
  amazing: <LuRocket />
}

const NotificationLevelIcon = ({ level, ...props }: Props) => {
  const { t } = useTranslation('sidenav')

  return (
    <Tooltip content={t(`notifications.levels.${level}`)}>
      <div>
        {cloneElement(ICONS[level], {
          size: 16,
          color: 'var(--colors-gray-12)',
          className: 'text-gray-12',
          ...props
        })}
      </div>
    </Tooltip>
  )
}

export default NotificationLevelIcon
