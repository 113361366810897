import { RouterProvider, createRouter } from '@tanstack/react-router'
import type { RootRouterContext } from './routes/__root'

import { routeTree } from './routeTree.gen'
import { useOrgContext } from './features/orgnisations/hooks/orgCtx'
import { Spinner } from '@radix-ui/themes'

const getRouter = (ctx: RootRouterContext) => {
  return createRouter({ routeTree, context: ctx })
}

declare module '@tanstack/react-router' {
  interface Register {
    router: ReturnType<typeof getRouter>
  }
}

export const Router = ({ ctx }: { ctx: Omit<RootRouterContext, 'org'> }) => {
  const { currentOrg } = useOrgContext() // Get the current organization

  if (!currentOrg) {
    return <Spinner />
  }

  const router = createRouter({ routeTree, context: { ...ctx, org: currentOrg } })
  return <RouterProvider router={router} context={ctx} />
}
