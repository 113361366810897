import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/sites/$siteID/config/')({
  beforeLoad: (context) => {
    throw redirect({
      to: '/sites/$siteID/config/stations',
      params: { siteID: context.params.siteID }
    })
  }
})
