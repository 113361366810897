import { Box, Button, CheckboxGroup, Flex, Spinner, Text, TextField, Tooltip } from '@radix-ui/themes'
import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Tables } from '@willie/supabase'
import { isEqual, pick } from 'lodash'
import { Suspense, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { ActionablePageLayout } from '~/components/ActionablePageLayout'
import { ImageUpload } from '~/components/ImageUpload'
import { SectionCard } from '~/components/SectionCard'
import {
  getCurrentUserProfile,
  SimpleUserProfile,
  updateUserPassword,
  updateUserPreferences,
  updateUserProfile,
  userPreferencesSchema
} from '~/features/auth/service'

const MIN_PASSWORD_LENGTH = 8

const pickProfileData = (profile: Tables<'user_profiles'>) => pick(profile, ['full_name', 'picture_url'])

const NotificationSection = ({ userProfile }: { userProfile: Tables<'user_profiles'> }) => {
  const { t } = useTranslation('settings')
  const { session, queryClient } = Route.useRouteContext()

  const initialPreferences = useMemo(
    () => userPreferencesSchema.parse(userProfile.preferences).notifications,
    [userProfile]
  )

  const [emailEnabled, setEmailEnabled] = useState(initialPreferences.emailEnabled ?? false)

  const { mutate: updatePrefs, isPending: updatePefPending } = useMutation({
    mutationFn: async () =>
      updateUserPreferences(session.user.id, { notifications: { ...initialPreferences, emailEnabled } }),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile']
      })
      toast.success(t('notifications.notificationUpdated'))
    },
    onError: () => {
      toast.error(t('notifications.notificationUpdateError'))
    }
  })

  return (
    <SectionCard title={t('notifications.sectionTitle')}>
      <Flex direction={'column'} gap="4">
        <Text size={'1'} color="gray">
          {t('notifications.sectionDescription')}
        </Text>
        <CheckboxGroup.Root
          defaultValue={initialPreferences.emailEnabled ? ['in-app', 'email'] : ['in-app']}
          onValueChange={(value) => setEmailEnabled(value.includes('email'))}
        >
          <CheckboxGroup.Item value="in-app" disabled>
            {t('notifications.appNotification')}
          </CheckboxGroup.Item>
          <CheckboxGroup.Item value="email">{t('notifications.emailNotifications')}</CheckboxGroup.Item>
        </CheckboxGroup.Root>
        <Box>
          <Button
            disabled={emailEnabled === initialPreferences.emailEnabled}
            onClick={() => updatePrefs()}
            loading={updatePefPending}
          >
            {t('notifications.updateNotificationPreference')}
          </Button>
        </Box>
      </Flex>
    </SectionCard>
  )
}

const Page = () => {
  const { t } = useTranslation('settings')
  const { session, queryClient } = Route.useRouteContext()

  const [pwdState, setPwdState] = useState({
    reveal: false,
    password: '',
    confirmPassword: ''
  })

  const canUpdatePwd = useMemo(() => {
    return (
      pwdState.password.length >= MIN_PASSWORD_LENGTH &&
      /[a-z]/.test(pwdState.password) &&
      /[A-Z]/.test(pwdState.password) &&
      /[0-9]/.test(pwdState.password) &&
      /[^A-Za-z0-9]/.test(pwdState.password) &&
      pwdState.password === pwdState.confirmPassword
    )
  }, [pwdState.password, pwdState.confirmPassword])

  const { mutate: updatePwd, isPending: updatePwdPending } = useMutation({
    mutationFn: () => updateUserPassword(pwdState.password),
    onSuccess: () => {
      setPwdState({ reveal: false, password: '', confirmPassword: '' })
      queryClient.invalidateQueries({
        queryKey: ['profile']
      })
      toast.success(t('profile.passwordUpdated'))
    },
    onError: () => {
      toast.error(t('profile.passwordUpdateError'))
    }
  })

  const [profileData, setProfileData] = useState<SimpleUserProfile | null>(null)

  const { data: userProfile } = useSuspenseQuery({
    queryFn: () => getCurrentUserProfile({ userID: session.user.id }),
    queryKey: ['profile']
  })

  useEffect(() => {
    if (userProfile) {
      setProfileData(pickProfileData(userProfile))
    }
  }, [userProfile])

  const isProfileDirty = useMemo(() => {
    if (!userProfile || !profileData) {
      return false
    }
    return !isEqual(profileData, pickProfileData(userProfile))
  }, [userProfile, profileData])

  const { mutate: updateProfile, isPending: updateProfilePending } = useMutation({
    mutationFn: async () => !!profileData && updateUserProfile(session.user.id, profileData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['profile']
      })
      toast.success(t('profile.profileUpdated'))
    },
    onError: () => {
      toast.error(t('profile.profileUpdateError'))
    }
  })

  return (
    <ActionablePageLayout title={t('profile.pageTitle')}>
      <Flex p={'9'} m={'auto'} gap="5" direction={'column'} className="w-[90%] xl:w-[70%] max-w-[1200px]">
        <Suspense
          fallback={
            <SectionCard title="" className="min-h-[300px]">
              <Flex align={'center'} justify={'center'} height={'full'} width={'full'}>
                <Spinner />
              </Flex>
            </SectionCard>
          }
        >
          <SectionCard title={t('profile.profileInfo')}>
            <Box>
              <Text size={'2'}>{t('profile.profilePicture')}</Text>
              <ImageUpload
                size={100}
                imageUrl={userProfile?.picture_url}
                uploadKey={`${session.user.id}/profile-picture/${Date.now()}`}
                onUploadSuccess={(picture_url) => {
                  setProfileData((d) => (d === null ? null : { ...d, picture_url }))
                }}
              />
              <Text size={'1'} color="gray">
                {t('profile.profilePictureHint')}
              </Text>
            </Box>
            <Box>
              <Text size={'2'}>{t('profile.fullName')}</Text>
              <TextField.Root
                my="3"
                value={profileData?.full_name ?? ''}
                onChange={(e) => setProfileData((d) => (d === null ? null : { ...d, full_name: e.target.value }))}
              />
              <Button disabled={!isProfileDirty} onClick={() => updateProfile()} loading={updateProfilePending}>
                {t('general.saveButton')}
              </Button>
            </Box>
          </SectionCard>
          <NotificationSection userProfile={userProfile} />
        </Suspense>
        <SectionCard title={t('profile.loginInfo')}>
          <Flex direction={'column'} gap="4">
            <Flex direction={'column'} gap="1">
              <Text size={'2'}>{t('profile.email')}</Text>
              <Tooltip content={t('profile.emailNotEditable')}>
                <TextField.Root disabled my="3" value={session.user.email} />
              </Tooltip>
            </Flex>
            <Flex direction={'column'} gap={'3'}>
              <Text size={'2'}>{t('profile.password')}</Text>
              <Text size={'1'} color="gray">
                {t('profile.passwordHint', { minLength: MIN_PASSWORD_LENGTH })}
              </Text>

              <TextField.Root
                placeholder={t('profile.passwordPlaceholder')}
                type={pwdState.reveal ? 'text' : 'password'}
                value={pwdState.password}
                onChange={(e) => {
                  setPwdState((s) => ({ ...s, password: e.target.value }))
                }}
              >
                <TextField.Slot side="right">
                  <Button
                    variant="soft"
                    size="1"
                    color="gray"
                    onClick={() => {
                      setPwdState((s) => ({ ...s, reveal: !s.reveal }))
                    }}
                  >
                    {pwdState.reveal ? t('profile.hidePassword') : t('profile.showPassword')}
                  </Button>
                </TextField.Slot>
              </TextField.Root>
              <TextField.Root
                placeholder={t('profile.confirmPassword')}
                value={pwdState.confirmPassword}
                type={pwdState.reveal ? 'text' : 'password'}
                onChange={(e) => {
                  setPwdState((s) => ({ ...s, confirmPassword: e.target.value }))
                }}
                disabled={!pwdState.password}
              />
              <Box mt="3">
                <Button disabled={!canUpdatePwd} onClick={() => updatePwd()} loading={updatePwdPending}>
                  {t('profile.updatePassword')}
                </Button>
              </Box>
            </Flex>
          </Flex>
        </SectionCard>
      </Flex>
    </ActionablePageLayout>
  )
}

export const Route = createFileRoute('/profile')({
  component: Page
})
