import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { createMetric } from '~/features/dashboarding/service'
import { Flex } from '@radix-ui/themes'
import { getSite } from '~/features/sites/service'
import _ from 'lodash'
import { CreateMetricForm } from '~/features/dashboarding/components/MetricForm'
import { toast } from 'sonner'
import { useTranslation } from 'react-i18next'

const Component = () => {
  const { queryClient, org } = Route.useRouteContext()
  const { siteID } = Route.useParams()
  const navigate = Route.useNavigate()
  const { t } = useTranslation('sites')

  const { data: site } = useSuspenseQuery({
    queryKey: ['site', siteID],
    queryFn: () => getSite({ siteId: siteID }),
    refetchOnWindowFocus: false
  })

  const { mutate: createSiteMetric, isPending } = useMutation({
    mutationFn: createMetric,
    onSuccess: () => {
      toast.success(t('metrics.form.messages.createSuccess'))
      navigate({
        to: '/sites/$siteID/config/metrics',
        params: { siteID },
        search: { period: 'week' }
      })
      queryClient.invalidateQueries({
        queryKey: ['site-metrics', siteID]
      })
    },
    onError: (error) => {
      console.error('Error creating metric', error)
      toast.error(t('metrics.form.messages.createError'))
    }
  })

  return (
    <Flex gap={'4'} direction={'column'}>
      <CreateMetricForm
        metric={null}
        onSubmit={createSiteMetric}
        isPending={isPending}
        site={site}
        orgID={org.id}
      />
    </Flex>
  )
}

export const Route = createFileRoute('/sites/$siteID/config/metrics/new')({
  component: Component
})
