import { Flex, Heading, Container, ScrollArea } from '@radix-ui/themes'
import cn from 'classnames'
import { useThemeAppearance } from '~/features/layout/hooks'

interface Props {
  title: string
  children: React.ReactNode
  className?: string
}

const ScrollablePageLayout = ({ title, children }: Props) => {
  const [theme] = useThemeAppearance()

  return (
    <ScrollArea className="h-[100vh] pb-6">
      <Flex
        align={'center'}
        width="full"
        className={cn('bg-[var(--gray-1)] border-b-[1px] border-[var(--gray-4)] h-[90px]', {
          'bg-[var(--gray-2)]': theme === 'dark',
          'bg-[var(--gray-1)]': theme === 'light'
        })}
      >
        <Container px={'6'}>
          <Flex align={'center'} justify={'between'}>
            <Heading size={'6'}>{title}</Heading>
          </Flex>
        </Container>
      </Flex>
      <Container flexGrow={'1'} p={'4'} height={'100%'} className="[&>*]:h-[calc(100vh-150px)]">
        {children}
      </Container>
    </ScrollArea>
  )
}

export { ScrollablePageLayout }
