import { useLocalStorage } from 'react-use'
import { ThemeProps } from '@radix-ui/themes'
import { useEffect } from 'react'

type Appearance = ThemeProps['appearance']

export const useThemeAppearance = () => {
  const osTheme: Appearance =
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'

  const [mode, setMode] = useLocalStorage<Appearance>('appearance')

  useEffect(() => {
    if (!mode) {
      setMode(osTheme)
    }
    document.body.classList.toggle('dark', mode === 'dark')
  }, [mode])

  return [mode || osTheme, setMode] as const
}
