import { Button, Card, Flex, Spinner, Text } from '@radix-ui/themes'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { listSiteBudgetItems } from '../service'
import { EmptyState } from '~/components/EmptyState'
import { CreateBudgetItemForm, UpdateBudgetItemForm } from './BuggetItemForm'
import _ from 'lodash'
import { BudgetItemGrid } from './BudgetItemGrid'

type Props = {
  siteID: string
  onComputedCostChange: (cost: number) => void
}

const HistoricalInvoicesSection = ({ siteID, onComputedCostChange }: Props) => {
  const { t } = useTranslation('sites')
  const [isAddingBudgetItem, setIsAdding] = useState(false)

  const { data = [], isPending } = useQuery({
    queryKey: ['site-invoices', siteID],
    queryFn: () => listSiteBudgetItems({ siteID, type: 'historical' }),
    throwOnError: true
  })

  const budgetItems = _.orderBy(data, 'endDate', 'asc')
  const latestInvoice = _.last(budgetItems)
  const computedCost = _.round((latestInvoice?.cost ?? 0) / (latestInvoice?.consumption ?? 1), 2)

  useEffect(() => {
    if (latestInvoice) {
      onComputedCostChange(computedCost)
    }
  }, [computedCost])

  return (
    <Flex gap="2" direction={'column'}>
      <Text size={'2'} weight="medium">
        {t('budget.invoiceSection.title')}
      </Text>
      {isPending && (
        <Card>
          <Flex justify={'center'} align={'center'} className="h-20">
            <Spinner aria-label={t('budget.invoiceSection.loading')} />
          </Flex>
        </Card>
      )}
      {budgetItems.length === 0 && !isAddingBudgetItem && !isPending ? (
        <Card className="p-5">
          <EmptyState
            title={t('budget.invoiceSection.emptyState.title')}
            description={t('budget.invoiceSection.emptyState.description')}
            primaryAction={{
              action: () => setIsAdding(true),
              label: t('budget.invoiceSection.emptyState.addFirst')
            }}
          />
        </Card>
      ) : (
        <BudgetItemGrid>
          {budgetItems.map((item) => (
            <UpdateBudgetItemForm key={item.id} budgetItem={item} siteID={siteID} siteCost={null} />
          ))}
          {isAddingBudgetItem ? (
            <CreateBudgetItemForm
              type="historical"
              siteCost={null}
              budgetItem={null}
              siteID={siteID}
              onCancel={() => setIsAdding(false)}
              onDone={() => setIsAdding(false)}
            />
          ) : (
            <Flex className="col-[1/-1] h-10" px="3" pb="3" justify={'center'}>
              <Button variant="ghost" onClick={() => setIsAdding(true)} className="font-medium">
                {t('budget.invoiceSection.addInvoice')}
              </Button>
            </Flex>
          )}
        </BudgetItemGrid>
      )}
    </Flex>
  )
}

export { HistoricalInvoicesSection }
