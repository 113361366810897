import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import GroupEmptyState from '~/components/GroupEmptyState'
import { ScrollablePageLayout } from '~/components/ScrollablePageLayout'

const Component = () => {
  const { t } = useTranslation(['sites', 'sidenav'])
  return (
    <ScrollablePageLayout title={t('navigation.reporting', { ns: 'sidenav' })}>
      <GroupEmptyState />
    </ScrollablePageLayout>
  )
}

export const Route = createFileRoute('/groups/$groupID/reporting')({
  component: Component
})
