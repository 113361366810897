import { z } from 'zod'

export type WaterStationType = z.infer<typeof stationTypeSchema>
export const stationTypeSchema = z.enum(['entry', 'usage', 'discharge'])

export type WaterStation = z.infer<typeof WaterStationSchema>
export const WaterStationSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(2),
  description: z.string(),
  station_type: stationTypeSchema,
  label: z.string(),
  site_id: z.string(),
  meter_id: z.number().nullable(),
  cost: z.number().nullable()
})

export type CreateWaterStationSchema = z.infer<typeof createWaterStationSchema>
export const createWaterStationSchema = WaterStationSchema.omit({
  id: true,
  meter_id: true
}).strict()

export type PhysicalMeterSchema = z.infer<typeof physicalMeterSchema>
// TODO replace this by datasource schema once we've refactored it
export const physicalMeterSchema = z.object({
  id: z.number(),
  orgID: z.string(),
  deviceID: z.string(),
  name: z.string().min(2),
  description: z.string(),
  dataMapping: z.string(),
  readingMethod: z.enum(['cumulative', 'incremental']),
  unit: z.enum(['m3', 'l']),
  maker: z.string().nullable()
})

export type CreatePhysicalMeterSchema = z.infer<typeof createPhysicalMeterSchema>
export const createPhysicalMeterSchema = physicalMeterSchema.omit({ id: true })
