import { Button, TabNav } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, Link, Outlet, useMatchRoute } from '@tanstack/react-router'
import { ActionablePageLayout } from '~/components/ActionablePageLayout'
import { getSite } from '~/features/sites/service'
import { useTranslation } from 'react-i18next'

const Component = () => {
  const { t } = useTranslation('sites')
  const matchRoute = useMatchRoute()
  const { siteID } = Route.useParams()
  const { data: site } = useSuspenseQuery({
    queryKey: ['site', siteID],
    queryFn: () => getSite({ siteId: siteID })
  })

  const isMetricExact = !!matchRoute({ to: '/sites/$siteID/config/metrics' })
  const isMetricFuzzy = !!matchRoute({ to: '/sites/$siteID/config/metrics', fuzzy: true })

  const isStations = !!matchRoute({ to: '/sites/$siteID/config/stations' })
  const isStationsFuzzy = !!matchRoute({ to: '/sites/$siteID/config/stations', fuzzy: true })
  const isBudget = !!matchRoute({ to: '/sites/$siteID/config/budget' })
  const isSettings = !!matchRoute({ to: '/sites/$siteID/config/overview' })

  const primaryAction = isMetricExact ? (
    <Link to="/sites/$siteID/config/metrics/new" params={{ siteID }}>
      <Button size={'3'}>{t('page.site.actions.addMetric')}</Button>
    </Link>
  ) : isStations ? (
    <Link to="/sites/$siteID/config/stations/new" params={{ siteID }}>
      <Button size={'3'}>{t('page.site.actions.addStation')}</Button>
    </Link>
  ) : null

  return (
    <ActionablePageLayout
      title={site.name}
      subtitleElement={
        <TabNav.Root className="shadow-none mb-[-15px]" size={'2'}>
          <Link to="/sites/$siteID/config/stations" params={{ siteID }}>
            <TabNav.Link active={isStationsFuzzy}>{t('navigation.waterStations')}</TabNav.Link>
          </Link>
          <Link to="/sites/$siteID/config/metrics" params={{ siteID }}>
            <TabNav.Link active={isMetricFuzzy}>{t('navigation.metrics')}</TabNav.Link>
          </Link>
          <Link to="/sites/$siteID/config/budget" params={{ siteID }}>
            <TabNav.Link active={isBudget}>{t('navigation.budgetPlanning')}</TabNav.Link>
          </Link>
          <Link to="/sites/$siteID/config/overview" params={{ siteID }}>
            <TabNav.Link active={isSettings}>{t('navigation.generalSettings')}</TabNav.Link>
          </Link>
        </TabNav.Root>
      }
      primaryAction={site.stations.length === 0 ? null : primaryAction}
    >
      <Outlet />
    </ActionablePageLayout>
  )
}

export const Route = createFileRoute('/sites/$siteID/config')({
  component: Component
})
