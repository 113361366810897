import { Controls, Edge, OnConnect, ReactFlow, useEdgesState, useNodesState } from '@xyflow/react'

import '@xyflow/react/dist/style.css'
import { WaterStation } from '../schemas'
import { useEffect } from 'react'
import _ from 'lodash'
import { CanvasNodes, CanvasNodeTypes } from './CanvasNodes'
import { Connection } from '~/types'
import { useThemeAppearance } from '~/features/layout/hooks'

const makeNodes = (stations: WaterStation[]) => {
  const [entries, usages, discharges] = stations.reduce(
    (acc, station) => {
      if (station.station_type === 'entry') {
        acc[0].push(station)
      } else if (station.station_type === 'usage') {
        acc[1].push(station)
      } else {
        acc[2].push(station)
      }
      return acc
    },
    [[], [], []] as WaterStation[][]
  )
  const entryNodes = entries.map<CanvasNodeTypes['source']>((station, i) => ({
    type: 'source',
    id: station.id,
    position: { x: 0, y: i * 100 },
    data: {
      station: station
    }
  }))
  const usageNodes = usages.map<CanvasNodeTypes['station']>((station, i) => ({
    type: 'station',
    id: station.id,
    position: { x: 300, y: i * 100 },
    data: {
      station: station
    }
  }))
  const dischargeNodes = discharges.map<CanvasNodeTypes['discharge']>((station, i) => ({
    type: 'discharge',
    id: station.id,
    position: { x: 600, y: i * 100 },
    data: {
      station: station
    }
  }))
  return [...entryNodes, ...usageNodes, ...dischargeNodes]
}

const makeEdges = (connections: Connection[]): Edge[] => {
  return connections.map(({ id, source_station_id: source, target_station_id: target }) => ({
    id: `${id}`,
    source,
    target,
    style: {
      strokeWidth: 2
    }
  }))
}

type MapLayoutCanvasProps = {
  stations: WaterStation[]
  connections: Connection[]
  handleConnect: ({ source, target }: { source: WaterStation; target: WaterStation }) => void
  handleDisconnect: (connectionId: string) => void
}

const MapLayoutCanvas = ({ stations, connections, handleDisconnect, handleConnect }: MapLayoutCanvasProps) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(makeNodes(stations))
  const [edges, setEdges, onEdgesChange] = useEdgesState(makeEdges(connections))
  const [theme] = useThemeAppearance()

  useEffect(() => {
    setNodes(makeNodes(stations))
    setEdges(makeEdges(connections))
  }, [stations, connections])

  const onNodesConnect: OnConnect = ({ source, target }) => {
    const sourceStation = stations.find(
      (s) => s.id === (nodes.find((n) => n.id === source)?.data.station.id ?? 'sourceId')
    )
    const targetStation = stations.find(
      (s) => s.id === (nodes.find((n) => n.id === target)?.data.station.id ?? 'targetId')
    )
    if (!sourceStation || !targetStation) {
      return
    }
    // this is proactive update to avoid ui glitch
    setEdges((edges) => [...edges, { id: `${_.uniqueId()}`, source, target }])
    // this is the actual update
    // todo rollback if the mutation fails
    handleConnect({ source: sourceStation, target: targetStation })
  }

  return (
    <ReactFlow
      colorMode={theme !== 'inherit' ? theme : 'system'}
      nodeTypes={CanvasNodes}
      nodes={nodes}
      onNodesChange={onNodesChange}
      edges={edges}
      fitView
      fitViewOptions={{
        padding: 0.1,
        maxZoom: 1
      }}
      draggable={false}
      onConnect={onNodesConnect}
      onEdgesDelete={(edgesToDelete) => {
        handleDisconnect(edgesToDelete[0].id)
      }}
      onEdgesChange={onEdgesChange}
      nodesDraggable={false}
      nodesConnectable={true}
      panOnDrag={false}
      zoomOnScroll={false}
    >
      <Controls />
    </ReactFlow>
  )
}

export { MapLayoutCanvas }
