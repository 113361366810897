import { createContext, useContext, useEffect, useState, ReactNode } from 'react'
import { Session } from '@supabase/supabase-js'
import { getWillieSupabaseClient } from '~/utils'

const supabase = getWillieSupabaseClient()

interface AuthContext {
  session: Session | null
  logout: () => Promise<void>
}

const AuthContext = createContext<AuthContext>({
  session: null,
  logout: async () => {}
})

type AuthProviderProps = {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [session, setSession] = useState<Session | null>(null)

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
    })

    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((_event, newSession) => {
      setSession((prevSession) => {
        if (prevSession?.access_token !== newSession?.access_token) {
          return newSession
        }
        return prevSession
      })
    })

    return () => subscription.unsubscribe()
  }, [])

  const logout = async () => {
    await supabase.auth.signOut()
    setSession(null)
  }

  return <AuthContext.Provider value={{ session, logout }}>{children}</AuthContext.Provider>
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
