import { Flex, Grid, Strong, Text } from '@radix-ui/themes'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { HiCalendar } from 'react-icons/hi'

export const BudgetItemGrid = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation('sites')
  return (
    <Grid
      columns={'4'}
      gap={'2'}
      className="border-[1px] border-gray-4 rounded-md grid-cols-[1.8fr_repeat(2,1fr)_160px]"
    >
      <Grid className="p-2 col-[1/-1] grid-cols-subgrid bg-gray-2 dark:bg-gray-3  text-center font-medium">
        <Flex align={'center'} gap={'1'} m="auto">
          <HiCalendar className="text-gray-10" />
          <Text size={'2'}>&nbsp;{t('budget.invoiceSection.headers.period')}</Text>
        </Flex>
        <Text size={'2'}>
          <Strong className="text-gray-10">
            m<sup>3</sup>
          </Strong>
          &nbsp; {t('budget.invoiceSection.headers.volume')}
        </Text>
        <Text size={'2'}>
          <Strong className="text-gray-10">€</Strong> {t('budget.invoiceSection.headers.cost')}
        </Text>
        <div />
      </Grid>
      {children}
    </Grid>
  )
}
