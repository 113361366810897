import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/groups/$groupID/')({
  beforeLoad: (ctx) => {
    throw redirect({
      to: '/groups/$groupID/dashboard',
      params: { groupID: ctx.params.groupID }
    })
  }
})
