import { createFileRoute, Link } from '@tanstack/react-router'
import { listSites, SiteSummary } from '../../features/sites/service'
import { Button, DropdownMenu, Flex, IconButton } from '@radix-ui/themes'
import { EmptyState } from '../../components/EmptyState'
import { ArrowRightIcon, DotsVerticalIcon } from '@radix-ui/react-icons'
import { ActionablePageLayout } from '../../components/ActionablePageLayout'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { SectionCard } from '~/components/SectionCard'
import { WaterStation, WaterStationType } from '~/features/mapping/schemas'
import _ from 'lodash'
import { CreateSiteDialog } from '~/features/sites/components/CreateSiteDialog'
import { MetricSummaryCard } from '~/features/dashboarding/components/MetricSummaryCard'
import { listSiteMetrics } from '~/features/dashboarding/service'
import { useMemo } from 'react'
import { MetricSchema } from '~/features/dashboarding/schemas'
import LinkButton from '~/components/LinkButton'
import { useTranslation } from 'react-i18next'

type SiteSummaryItemProps = {
  site: SiteSummary
}

const SiteSummaryItem = ({ site }: SiteSummaryItemProps) => {
  const { t } = useTranslation('sites')
  const { data: metrics } = useQuery({
    queryKey: ['site-metrics', site.id],
    queryFn: () => listSiteMetrics(site.id)
  })

  const summaryMetrics = useMemo(() => {
    const stationGroups: Record<WaterStationType, WaterStation[]> = {
      entry: [],
      usage: [],
      discharge: [],
      ..._.groupBy(site.stations, 'station_type')
    }

    const defaultMetrics = Object.entries(stationGroups).map<MetricSchema>(([type, stations]) => ({
      id: `${site.id}-${type}`,
      name: t(`stations.types.${type}`, { count: 2 }),
      chartType: 'bar',
      stationOperations: stations.map((s) => ({
        stationId: s.id,
        operation: 'add'
      })),
      orgID: site.org_id,
      siteID: site.id,
      summaryCardConfig: {
        schemaVersion: 1,
        showOnSiteSummary: true,
        period: 'week'
      }
    }))

    const configuredMetrics = metrics?.filter((m) => m.summaryCardConfig.showOnSiteSummary).slice(0, 3) ?? []

    return [...configuredMetrics, ...defaultMetrics.slice(0, 3 - configuredMetrics.length)]
  }, [metrics, site, t])

  return (
    <SectionCard
      className="group"
      title={site.name}
      secondaryActions={
        <>
          <LinkButton
            className="opacity-0 group-hover:opacity-100"
            to={'/sites/$siteID/dashboard'}
            params={{ siteID: site.id }}
            search={{ period: 'week' }}
            size={'2'}
            variant="ghost"
            text={t('page.sites.actions.goToDashboard')}
            trailingIcon={<ArrowRightIcon />}
          />
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <IconButton size={'2'} variant="ghost" color="gray">
                <DotsVerticalIcon />
              </IconButton>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content variant="soft" color="gray">
              <Link to={'/sites/$siteID/config'} params={{ siteID: site.id }}>
                <DropdownMenu.Item>{t('page.sites.actions.configureSite')}</DropdownMenu.Item>
              </Link>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </>
      }
    >
      <Flex px="1" py="3" gap="4" className="flex-wrap">
        {/* TODO loading state to prevent flicker */}
        {summaryMetrics.map((metric) => (
          <MetricSummaryCard key={metric.id} metric={metric} site={site} />
        ))}
      </Flex>
    </SectionCard>
  )
}

const SitesPage = () => {
  const { t } = useTranslation('sites')
  const { org } = Route.useRouteContext()
  const { data: sites } = useSuspenseQuery({
    queryKey: ['sites'],
    queryFn: () => listSites({ orgId: org.id })
  })

  return (
    <ActionablePageLayout
      title={t('page.sites.title')}
      primaryAction={
        sites.length > 0 ? (
          <CreateSiteDialog>
            <Button size={'3'}>{t('page.sites.addSite')}</Button>
          </CreateSiteDialog>
        ) : null
      }
    >
      <Flex py="3" gap="5" wrap={'wrap'}>
        {sites.map((site) => (
          <SiteSummaryItem key={site.id} site={site} />
        ))}
      </Flex>
      {sites.length === 0 && (
        <EmptyState
          title={t('page.sites.emptyState.title')}
          description={t('page.sites.emptyState.description', { orgName: org.name })}
          afterBody={
            <CreateSiteDialog>
              <Button>{t('page.sites.emptyState.createButton')}</Button>
            </CreateSiteDialog>
          }
        />
      )}
    </ActionablePageLayout>
  )
}

export const Route = createFileRoute('/sites/')({
  beforeLoad: async ({ context }) => {
    return { org: context.org }
  },
  component: SitesPage
})
