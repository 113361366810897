import { TablesInsert } from '@willie/supabase'
import { getWillieSupabaseClient } from '~/utils'
import { CreateMetricSchema, MetricSchema, SiteMetric, siteMetricSchema } from './schemas'
import _ from 'lodash'

export const listOrgMetrics = async (orgID: string): Promise<MetricSchema[]> => {
  const client = getWillieSupabaseClient()
  const { data, error } = await client.from('metrics').select().eq('org_id', orgID)
  if (!data || error) {
    console.error('Error fetching metrics', error)
    return []
  }

  return data.map<MetricSchema>((metric) =>
    siteMetricSchema.parse({
      id: metric.id,
      name: metric.name,
      description: metric.description,
      siteID: metric.site_id,
      orgID: metric.org_id,
      chartType: metric.chart_type,
      stationOperations: metric.station_operations,
      summaryCardConfig: metric.summary_card_config
    })
  )
}

export const listSiteMetrics = async (siteID: string): Promise<SiteMetric[]> => {
  const client = getWillieSupabaseClient()
  const { data, error } = await client
    .from('metrics')
    .select()
    .eq('site_id', siteID)
    .order('created_at', { ascending: true })
  if (!data || error) {
    console.error('Error fetching metrics', error)
    throw new Error('Error fetching metrics')
  }

  return data.map<SiteMetric>((metric) =>
    siteMetricSchema.parse({
      id: metric.id,
      name: metric.name,
      description: metric.description,
      siteID,
      orgID: metric.org_id,
      chartType: metric.chart_type,
      stationOperations: metric.station_operations,
      summaryCardConfig: metric.summary_card_config
    })
  )
}

export const getMetric = async ({ metricID }: { metricID: string }): Promise<MetricSchema> => {
  const client = getWillieSupabaseClient()
  const { data: metric, error } = await client.from('metrics').select().eq('id', metricID).single()
  if (!metric || error) {
    console.error('Error fetching metric', error)
    throw new Error('Error fetching metric')
  }

  return siteMetricSchema.parse({
    id: metric.id,
    name: metric.name,
    description: metric.description,
    siteID: metric.site_id,
    orgID: metric.org_id,
    chartType: metric.chart_type,
    stationOperations: metric.station_operations,
    summaryCardConfig: metric.summary_card_config
  })
}

export const createMetric = async (metricDef: CreateMetricSchema) => {
  const supabase = getWillieSupabaseClient()

  const mappedMetric: TablesInsert<'metrics'> = {
    name: metricDef.name,
    description: metricDef.description,
    site_id: metricDef.siteID,
    org_id: metricDef.orgID,
    chart_type: metricDef.chartType,
    station_operations: metricDef.stationOperations,
    summary_card_config: metricDef.summaryCardConfig
  }
  const { error } = await supabase.from('metrics').insert(mappedMetric)
  if (error) {
    console.error('Error creating metric', error)
    return null
  }
}

export const updateMetric = async (metricDef: MetricSchema) => {
  const supabase = getWillieSupabaseClient()
  const { error } = await supabase
    .from('metrics')
    .update({
      name: metricDef.name,
      description: metricDef.description,
      chart_type: metricDef.chartType,
      station_operations: metricDef.stationOperations,
      summary_card_config: metricDef.summaryCardConfig
    })
    .eq('id', metricDef.id)
  if (error) {
    console.error('Error updating metric', error)
  }
}

export const deleteMetric = async (metricID: string) => {
  const supabase = getWillieSupabaseClient()
  const { error } = await supabase.from('metrics').delete().eq('id', metricID)
  if (error) {
    console.error('Error deleting metric', error)
  }
}
