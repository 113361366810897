import { Meter } from '~/types'
import { getWillieSupabaseClient } from '../../utils'
import { WaterStation, CreateWaterStationSchema, WaterStationSchema } from './schemas'
import { TablesInsert, TablesUpdate } from '@willie/supabase'

export const getStation = async ({ stationID }: { stationID: string }) => {
  const supabase = getWillieSupabaseClient()
  const { data, error } = await supabase.from('water_stations').select('*').eq('id', stationID).single()

  if (error) {
    console.error(`Error getting station ${stationID}`, error)
    throw new Error(`Error getting station ${stationID}`)
  }

  return WaterStationSchema.parse({ ...data, cost: data.cost_per_m3 })
}

export const createWaterStation = async (data: CreateWaterStationSchema) => {
  const supabase = getWillieSupabaseClient()
  const payload: TablesInsert<'water_stations'> = {
    label: data.label,
    name: data.name,
    description: data.description,
    site_id: data.site_id,
    station_type: data.station_type,
    cost_per_m3: data.cost
  }
  await supabase.from('water_stations').insert([payload])
}

export const updateWaterStation = async (data: WaterStation) => {
  const supabase = getWillieSupabaseClient()
  const payload: TablesUpdate<'water_stations'> = {
    label: data.label,
    name: data.name,
    description: data.description,
    site_id: data.site_id,
    station_type: data.station_type,
    cost_per_m3: data.cost,
    meter_id: data.meter_id
  }
  await supabase.from('water_stations').update(payload).eq('id', data.id)
}

export const deleteWaterStation = async ({ stationID }: { stationID: WaterStation['id'] }) => {
  const supabase = getWillieSupabaseClient()
  const station = await getStation({ stationID })
  const { error } = await supabase.from('water_stations').delete().eq('id', stationID)

  if (error) {
    console.error(`Error deleting station ${stationID}`, error)
    return null
  }
  if (station.meter_id) {
    await supabase.from('connected_meter').delete().eq('id', station.meter_id)
  }

  return stationID
}

export const listSiteWaterStations = async ({ siteId }: { siteId: string }) => {
  const supabase = getWillieSupabaseClient()

  const { data, error } = await supabase.from('water_stations').select('*').eq('site_id', siteId)

  if (error) {
    console.error(`Error getting water stations for site ${siteId}`, error)
    return []
  }

  return data
    .map((s) => WaterStationSchema.parse({ ...s, cost: s.cost_per_m3 }))
    .sort((a, b) => {
      if (a.station_type === b.station_type) {
        return a.label.localeCompare(b.label)
      }
      return b.station_type.localeCompare(a.station_type)
    })
}

export const attachMeterToStation = async ({
  stationId,
  meterId
}: {
  stationId: string
  meterId: Meter['id']
}) => {
  const supabase = getWillieSupabaseClient()
  await supabase.from('water_stations').update({ meter_id: meterId }).eq('id', stationId)
}

export const listSiteConnections = async ({ siteId }: { siteId: string }) => {
  const supabase = getWillieSupabaseClient()
  const { data } = await supabase.from('site_stations_mapping').select('*').eq('site_id', siteId)

  return data
}

const allowedConnectionTypes = [
  'entry-usage',
  'entry-discharge',
  'usage-usage',
  'usage-discharge',
  'discharge-entry'
]
export const addConnection = async ({
  source,
  target,
  siteID
}: {
  source: WaterStation
  target: WaterStation
  siteID: string
}) => {
  const connectionType = `${source.station_type}-${target.station_type}`
  if (!allowedConnectionTypes.includes(connectionType)) {
    throw new Error(`Invalid connection type: ${connectionType}`)
  }

  const supabase = getWillieSupabaseClient()
  const { data } = await supabase
    .from('site_stations_mapping')
    .insert({
      source_station_id: source.id,
      target_station_id: target.id,
      site_id: siteID
    })
    .select('*')
    .single()

  return data
}

export const removeConnection = async ({ connectionId, siteID }: { connectionId: string; siteID: string }) => {
  const supabase = getWillieSupabaseClient()
  await supabase.from('site_stations_mapping').delete().eq('id', connectionId).eq('site_id', siteID)
}
