import { createFileRoute } from '@tanstack/react-router'
import GroupEmptyState from '~/components/GroupEmptyState'
import { ScrollablePageLayout } from '~/components/ScrollablePageLayout'

const Component = () => {
  return (
    <ScrollablePageLayout title="Configuration de Mon groupe">
      <GroupEmptyState />
    </ScrollablePageLayout>
  )
}

export const Route = createFileRoute('/groups/$groupID/config')({
  component: Component
})
