import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '~/components/EmptyState'
import { ScrollablePageLayout } from '~/components/ScrollablePageLayout'
import { SectionCard } from '~/components/SectionCard'

const Component = () => {
  const { t } = useTranslation(['sites', 'sidenav'])
  return (
    <ScrollablePageLayout title={t('navigation.reporting', { ns: 'sidenav' })}>
      <SectionCard title="" className="h-full">
        <EmptyState
          title="Aucun Rapport Configuré"
          description="La génération de rapports n'est pas encore disponible pour ce site. Un minimum de 6 mois de collecte de donnée est nécessaire."
        />
      </SectionCard>
    </ScrollablePageLayout>
  )
}

export const Route = createFileRoute('/sites/$siteID/reporting')({
  component: Component
})
