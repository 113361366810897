import { Link, useMatchRoute, useNavigate } from '@tanstack/react-router'
import { Session } from '@supabase/supabase-js'
import { Avatar, Button, DropdownMenu, Flex, Text, Strong, Box } from '@radix-ui/themes'

import { TbReport } from 'react-icons/tb'
import { RxCaretLeft, RxExit, RxGear } from 'react-icons/rx'
import { useQuery } from '@tanstack/react-query'
import { Org } from '~/types'
import cn from 'classnames'
import { cloneElement, PropsWithChildren, ReactElement, ReactNode } from 'react'
import { CaretDownIcon, CheckIcon, GearIcon, MoonIcon, PersonIcon, SunIcon } from '@radix-ui/react-icons'
import { useThemeAppearance } from '~/features/layout/hooks'
import { useOrgContext } from '~/features/orgnisations/hooks/orgCtx'
import { useTranslation } from 'react-i18next'
import {
  LuChartColumnBig as LuBarChart3,
  LuBell,
  LuBuilding,
  LuComponent,
  LuLayoutDashboard
} from 'react-icons/lu'
import { IoWaterOutline } from 'react-icons/io5'

import { listSites } from '~/features/sites/service'
import { TypeRoutePaths } from '~/types/routes'
import { makeSideNavItemProps, SideNavItem, TypedNavItem } from './SideNavItem'
import { useAuth } from '~/features/auth/AuthProvider'
import { getCurrentUserProfile } from '~/features/auth/service'
import PromptChangePwd from '../PromptChangePwd'
import { NotificationsPopover } from '~/features/notifications/components/NotificationsPopover'

type SideNavProps = {
  orgs: Org[]
  org: Org
  user: Session['user']
}

const LOCALES = ['en', 'fr']

type SideNavGroupProps = PropsWithChildren<{
  label: string
  icon?: ReactNode
}>
const SideNavGroup = ({ label, icon = null, children }: SideNavGroupProps) => {
  const iconEl = cloneElement(icon as ReactElement, { size: 16, className: 'text-[var(--gray-10)]' })
  return (
    <Flex direction={'column'} gap={'1'}>
      <Flex gap={'1'} pl="4" mb={'1'} align={'end'}>
        {iconEl}
        <Text size={'1'} weight={'bold'} className="text-[var(--gray-10)]">
          {label}
        </Text>
      </Flex>
      {children}
    </Flex>
  )
}

const SideNav = ({ user, org, orgs }: SideNavProps) => {
  const { t, i18n } = useTranslation('sidenav')
  const { setCurrentOrg } = useOrgContext()
  const navigate = useNavigate()
  const matchRoute = useMatchRoute()

  const [appearance, setAppearance] = useThemeAppearance()
  const { logout } = useAuth()

  const isSettings =
    !!matchRoute({ to: '/settings', fuzzy: true }) || !!matchRoute({ to: '/profile', fuzzy: true })

  const { data: sites = [] } = useQuery({
    queryKey: ['sites', org.id],
    queryFn: () => listSites({ orgId: org.id })
  })

  const { data: userProfile } = useQuery({
    queryKey: ['profile', user.id],
    queryFn: () => getCurrentUserProfile({ userID: user.id })
  })

  const navItems = [
    makeSideNavItemProps('/sites', {
      label: t('navigation.allSites')
    }),
    ...sites.map((site) => ({
      label: site.name,
      subItems: [
        makeSideNavItemProps('/sites/$siteID/dashboard', {
          icon: <LuLayoutDashboard />,
          label: t('navigation.dashboard'),
          isSubItem: true,
          params: { siteID: site.id }
        }),
        makeSideNavItemProps('/sites/$siteID/readings', {
          icon: <IoWaterOutline />,
          label: t('navigation.readings'),
          isSubItem: true,
          params: { siteID: site.id }
        }),
        makeSideNavItemProps('/sites/$siteID/budget-planning', {
          icon: <LuBarChart3 />,
          label: t('navigation.budgetPlanning'),
          isSubItem: true,
          params: { siteID: site.id }
        }),
        makeSideNavItemProps('/sites/$siteID/alerting', {
          icon: <LuBell />,
          label: t('navigation.alerting'),
          isSubItem: true,
          params: { siteID: site.id }
        }),
        makeSideNavItemProps('/sites/$siteID/reporting', {
          icon: <TbReport />,
          label: t('navigation.reporting'),
          isSubItem: true,
          params: { siteID: site.id }
        }),
        makeSideNavItemProps('/sites/$siteID/config', {
          icon: <RxGear />,
          label: t('navigation.configuration'),
          isSubItem: true,
          params: { siteID: site.id }
        })
      ]
    }))
  ]

  const group = {
    label: 'Mon groupe',
    subItems: [
      makeSideNavItemProps('/groups/$groupID/dashboard', {
        icon: <LuLayoutDashboard />,
        label: t('navigation.dashboard'),
        isSubItem: true,
        params: { groupID: sites[0]?.id }
      }),
      makeSideNavItemProps('/groups/$groupID/readings', {
        icon: <IoWaterOutline />,
        label: t('navigation.readings'),
        isSubItem: true,
        params: { groupID: sites[0]?.id }
      }),
      makeSideNavItemProps('/groups/$groupID/budget-planning', {
        icon: <LuBarChart3 />,
        label: t('navigation.budgetPlanning'),
        isSubItem: true,
        params: { groupID: sites[0]?.id }
      }),
      makeSideNavItemProps('/groups/$groupID/alerting', {
        icon: <LuBell />,
        label: t('navigation.alerting'),
        isSubItem: true,
        params: { groupID: sites[0]?.id }
      }),
      makeSideNavItemProps('/groups/$groupID/reporting', {
        icon: <TbReport />,
        label: t('navigation.reporting'),
        isSubItem: true,
        params: { groupID: sites[0]?.id }
      }),
      makeSideNavItemProps('/groups/$groupID/config', {
        icon: <RxGear />,
        label: t('navigation.configuration'),
        isSubItem: true,
        params: { groupID: sites[0]?.id }
      })
    ]
  }

  const settingsNavItems: TypedNavItem<TypeRoutePaths>[] = [
    { route: '/settings', label: t('navigation.settings'), icon: <GearIcon /> },
    { route: '/profile', label: t('navigation.profile'), icon: <PersonIcon /> }
  ]

  const handleChangeOrg = (o: Org) => {
    navigate({
      to: '/sites'
    })
    setCurrentOrg(o)
  }

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang)
    localStorage.setItem('language', lang)
  }

  return (
    <Flex
      direction={'column'}
      height={'full'}
      gap={'1'}
      width={'18%'}
      className={cn('max-w-[400px] min-w-0 border-r-[1px] border-[var(--gray-4)]', {
        'bg-[var(--gray-2)]': appearance === 'dark',
        'bg-[var(--gray-1)]': appearance === 'light'
      })}
    >
      <PromptChangePwd userProfile={userProfile} />
      <Flex width={'full'} height={'90px'} mb={'3'} p={'4'} justify={'between'} align={'center'}>
        {isSettings ? (
          <Link to="/">
            <Button variant="ghost" color="gray">
              <RxCaretLeft size={22} />
              <Text size={'3'}>
                <Strong>{t('navigation.back')}</Strong>
              </Text>
            </Button>
          </Link>
        ) : (
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button variant="ghost" color="gray" size={'3'} ml={'1'}>
                <Avatar
                  radius="full"
                  src={org.icon_url ?? ''}
                  fallback={org.name[0]}
                  size={'2'}
                  mr={'2'}
                  color="sky"
                />
                <Text size={'4'} weight={'bold'}>
                  {org.name}
                </Text>
                <CaretDownIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content variant="soft" color="gray">
              <Box px="3" mb="1">
                <Text size={'3'}>
                  <Strong>{org.name}</Strong>
                </Text>
              </Box>
              <Link to="/settings">
                <DropdownMenu.Item>
                  <GearIcon />
                  {t('organization.settings')}
                </DropdownMenu.Item>
                {/* <DropdownMenu.Item>
                  <GoPeople />
                  {t('organization.manageMembers')}
                </DropdownMenu.Item> */}
              </Link>
              {orgs.length > 1 && (
                <DropdownMenu.Sub>
                  <DropdownMenu.Separator />
                  <DropdownMenu.SubTrigger>{t('organization.switchOrganization')}</DropdownMenu.SubTrigger>
                  <DropdownMenu.SubContent>
                    {orgs.map((o) => (
                      <DropdownMenu.Item key={o.id} onClick={() => handleChangeOrg(o)}>
                        <Avatar
                          radius="full"
                          src={o.icon_url ?? ''}
                          fallback={o.name[0]}
                          size={'1'}
                          mr={'2'}
                          color="sky"
                        />
                        <Text>{o.name}</Text>
                        {o.id === org.id && <CheckIcon color="green" />}
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.SubContent>
                </DropdownMenu.Sub>
              )}
              {/* 
            TODO display this for super admins
            <DropdownMenu.Separator />
            <Box px="3" mb="1">
              <Text size={'2'}>
                <Strong>{t('organization.admin.title')}</Strong>
              </Text>
            </Box>
            <DropdownMenu.Item>{t('organization.admin.createOrganization')}</DropdownMenu.Item>
            */}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        )}
      </Flex>
      {isSettings ? (
        settingsNavItems.map((i) => <SideNavItem key={i.label} {...i} />)
      ) : (
        <Flex direction={'column'} gap={'6'}>
          <SideNavGroup label="Groupes" icon={<LuComponent />}>
            <SideNavItem {...group} />
          </SideNavGroup>
          <SideNavGroup label="Sites" icon={<LuBuilding />}>
            {navItems.map((i) => (
              <SideNavItem key={i.label} {...i} />
            ))}
          </SideNavGroup>
        </Flex>
      )}
      <Flex mt={'auto'} px="4" p={'4'} align={'center'} justify={'between'}>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button variant="ghost" color="gray" size={'2'}>
              <Avatar
                color="pink"
                variant="soft"
                radius="full"
                fallback={user.email?.slice(0, 2) ?? 'A'}
                src={userProfile?.picture_url ?? ''}
                size={'1'}
                mr={'1'}
              />
              <Text size={'2'}>
                <Strong>{userProfile?.full_name ?? user.email?.split('@')[0]}</Strong>
              </Text>
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content variant="soft" color="gray" side="right">
            <Link to="/profile">
              <DropdownMenu.Item>
                <PersonIcon />
                {t('user.profile')}
              </DropdownMenu.Item>
            </Link>
            <DropdownMenu.Item onClick={() => setAppearance(appearance === 'dark' ? 'light' : 'dark')}>
              {appearance === 'dark' ? <SunIcon /> : <MoonIcon />}
              {appearance === 'dark' ? t('appearance.switchToLight') : t('appearance.switchToDark')}
            </DropdownMenu.Item>
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger>{t('language.switchLanguage')}</DropdownMenu.SubTrigger>
              <DropdownMenu.SubContent>
                {LOCALES.map((lang) => (
                  <DropdownMenu.Item key={lang} onClick={() => changeLanguage(lang)}>
                    <Text>{t(`language.options.${lang}`)}</Text>
                    {lang === i18n.language && <CheckIcon color="green" />}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Sub>
            <DropdownMenu.Separator />
            <DropdownMenu.Item onClick={logout}>
              <RxExit />
              {t('user.logout')}
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        <NotificationsPopover />
      </Flex>
    </Flex>
  )
}

export { SideNav }
