import { Button, Flex, Dialog } from '@radix-ui/themes'
import { ArrowRightIcon } from '@radix-ui/react-icons'
import LinkButton from '~/components/LinkButton'
import { Tables } from '@willie/supabase'
import { useEffect, useState } from 'react'
import { useSessionStorage } from 'react-use'
import { useTranslation } from 'react-i18next'

type Props = {
  userProfile?: Tables<'user_profiles'> | null
}

const PromptChangePwd = ({ userProfile }: Props) => {
  const { t } = useTranslation('settings')
  const [isOpen, setIsOpen] = useState(false)
  const [dismissed, setDismissed] = useSessionStorage('pwd_change_dismissed', false)

  useEffect(() => {
    // Only open if user hasn't logged in and dialog hasn't been dismissed yet
    if (userProfile && !userProfile.has_logged_in && !dismissed) {
      setIsOpen(true)
    }
  }, [userProfile, dismissed])

  const handleChange = (open: boolean) => {
    setIsOpen(open)
    if (!open) {
      // Save dismissal state to session storage
      setDismissed(true)
    }
  }

  if (!userProfile) {
    return null
  }

  return (
    <Dialog.Root open={isOpen} onOpenChange={handleChange}>
      <Dialog.Content>
        <Dialog.Title>{t('passwordPrompt.title')}</Dialog.Title>
        <Dialog.Description>{t('passwordPrompt.description')}</Dialog.Description>
        <Flex mt="3" align={'center'} gap={'4'} justify={'end'}>
          <Dialog.Close>
            <Button size={'2'} color="gray" variant="ghost">
              {t('passwordPrompt.later')}
            </Button>
          </Dialog.Close>

          <Dialog.Close>
            <LinkButton
              autoFocus
              ml={'auto'}
              variant="solid"
              to="/profile"
              text={t('passwordPrompt.updateNow')}
              trailingIcon={<ArrowRightIcon />}
            />
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  )
}

export default PromptChangePwd
