import { createContext, useContext } from 'react'
import { Org } from '~/types'

// Define the context type
type OrgContextType = {
  currentOrg: Org
  setCurrentOrg: (org: Org) => void
}

// Create the context
export const OrgContext = createContext<OrgContextType | null>(null)

// Custom hook to use the organization context
export const useOrgContext = () => {
  const context = useContext(OrgContext)
  if (!context) {
    throw new Error('useOrg must be used within an OrgProvider')
  }
  return context
}
