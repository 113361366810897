import { DateTime } from 'luxon'
import { DateRange, Meter } from '~/types'
import { getWillieSupabaseClient } from '~/utils'
import _ from 'lodash'
import { CreatePhysicalMeterSchema, physicalMeterSchema, PhysicalMeterSchema } from '../mapping/schemas'

type ParsedDataPoint<T> = {
  dateTime: DateTime
  value: T
}

type RawDataPoint = {
  payload: any
  created_at: string
}

type ListDataPointsPaylaod = {
  orgID: string
  deviceID: string
  mappingQuery?: string
  limit?: number
  numbersOnly?: boolean
} & Partial<DateRange>

export async function listDatapointsPayloadByDeviceID<T = any>({
  orgID,
  deviceID,
  mappingQuery = 'payload -> decoded',
  startDate,
  endDate,
  limit,
  numbersOnly
}: ListDataPointsPaylaod): Promise<ParsedDataPoint<T>[]> {
  const supabase = getWillieSupabaseClient()
  let query = supabase
    .from('data-lake')
    .select(`payload:${mappingQuery}, created_at`)
    .neq('payload ->> decoded', null)
    .eq('device_id', deviceID)
    .eq('org_id', orgID)
    .order('created_at', { ascending: false })

  if (startDate) {
    query = query.gte('created_at', startDate.toISO())
  }
  if (endDate) {
    query = query.lte('created_at', endDate.toISO())
  }
  if (limit) {
    query = query.limit(limit)
  }

  const { data: datapoints, error } = (await query) as any as {
    data: RawDataPoint[]
    error: any
  }

  if (error) {
    const message = `Error fetching data points for device ${deviceID}`

    console.log(message, error)
    throw new Error(message)
  }

  return (datapoints ?? [])
    .filter((dp) => (numbersOnly ? _.isNumber(dp.payload) : !_.isNil(dp.payload)))
    .map((dp) => {
      return {
        dateTime: dp.payload.ts ? DateTime.fromMillis(dp.payload.ts) : DateTime.fromISO(dp.created_at),
        value: dp.payload
      } satisfies ParsedDataPoint<T>
    })
}

export const getMeterById = async ({ meterId }: { meterId: Meter['id'] }) => {
  const supabase = getWillieSupabaseClient()
  const { data, error } = await supabase.from('connected_meter').select('*').eq('id', meterId).single()

  if (error || !data) {
    const message = `Error fetching physical meter for meter ${meterId}`
    console.error(message, error)
    throw new Error(message)
  }

  return physicalMeterSchema.parse({
    id: data.id,
    orgID: data.org_id!,
    deviceID: data.device_id!,
    name: data.name,
    description: data.description,
    dataMapping: data.mapping_query ?? '',
    readingMethod: data.reading_method,
    unit: data.unit,
    maker: data.device_maker
  })
}

export const getDataPointsWithMeter = async ({ orgId }: { orgId: string }) => {
  const supabase = getWillieSupabaseClient()

  // get 100 most recent data points, we assume we get one from each device, this assumtion might not be true
  const { data: datapoints, error } = await supabase
    .from('data-lake')
    .select('device_id, payload, created_at')
    .eq('org_id', orgId)
    .neq('device_id', null)
    .neq('payload ->> decoded', null)
    .order('created_at', { ascending: false })
    .limit(100)

  // get all meters for the org
  const { data: physicalMeters, error: error2 } = await supabase
    .from('connected_meter')
    .select('*')
    .eq('org_id', orgId)

  if (!datapoints || error || error2)
    return {
      datapointsWithMeter: []
    }

  // for each device map the most recent data point and the meter together
  // we could change this for a join in the db query but this is fine for now as supabase does not support joins on columns that are not explicit foreign keys
  const datapointsWithMeter = _(datapoints)
    .uniqBy('device_id')
    .map((lastDataPoint) => {
      const meter = physicalMeters.find((meter) => meter.device_id === lastDataPoint.device_id) ?? null
      return { lastDataPoint, meter }
    })
    .value()

  return { datapointsWithMeter }
}

export const createOrUpdatePhysicalMeter = async (meterData: CreatePhysicalMeterSchema | PhysicalMeterSchema) => {
  const supabase = getWillieSupabaseClient()
  const isUpdate = (data: CreatePhysicalMeterSchema | PhysicalMeterSchema): data is PhysicalMeterSchema => {
    return (data as PhysicalMeterSchema).id !== undefined
  }

  const payload: Partial<Meter> = {
    device_id: meterData.deviceID,
    name: meterData.name,
    description: meterData.description,
    mapping_query: meterData.dataMapping,
    org_id: meterData.orgID,
    reading_method: meterData.readingMethod,
    unit: meterData.unit,
    device_maker: meterData.maker
  }

  if (isUpdate(meterData)) {
    payload.id = meterData.id
  }

  const { data, error } = await supabase.from('connected_meter').upsert(payload).select('*').single()

  if (error) {
    const message = `Error creating or updating physical meter`
    console.error(message, error)
    throw new Error(message)
  }
  return data!
}

type DataFilters = {
  meterIds: number[]
} & Partial<DateRange>

// TODO add resolution here and perform the volume computation here instead of doing it in the useStationsChartDataHook
export const listDataPointsForMeters = async ({
  startDate = DateTime.now().minus({ days: 60 }),
  endDate = DateTime.now(),
  meterIds
}: DataFilters) => {
  const supabase = getWillieSupabaseClient()
  const { data: meters, error: error1 } = await supabase
    .from('connected_meter')
    .select('*')
    .neq('device_id', null)
    .in('id', meterIds)

  if (error1) {
    const message = `Error fetching device ids for meters`
    console.error(message, error1)
    throw new Error(message)
  }

  const datapoints = await Promise.all(
    meters
      .filter((m) => m.mapping_query)
      .map((m) =>
        listDatapointsPayloadByDeviceID<number>({
          orgID: m.org_id!,
          deviceID: m.device_id!,
          mappingQuery: m.mapping_query!,
          startDate,
          endDate,
          numbersOnly: true
        })
      )
  )

  return _.zip(meters, datapoints).map(([meter, datapoints]) => ({
    ...meter,
    datapoints
  }))
}

// this is a shayp specific function
// at some point we'll have our own model we can move this to a shayp package if we want to
type ListLeakProps = {
  meter: PhysicalMeterSchema
} & DateRange
export const listLeakageDataPointsForMeter = async ({ meter, startDate, endDate }: ListLeakProps) => {
  if (meter.maker !== 'shayp') {
    throw new Error('Only shayp meters support leakage detection')
  }

  const datapoints = await listDatapointsPayloadByDeviceID<{ leak: number; consumption: number }>({
    orgID: meter.orgID,
    deviceID: meter.deviceID,
    mappingQuery: 'payload -> decoded',
    startDate,
    endDate
  })

  return datapoints.map((dp) => ({ dateTime: dp.dateTime, ..._.pick(dp.value, ['leak', 'consumption']) }))
}

/*
 *  I deliberately performed 2 queries here as supabase js doens't support sub queries in where clause, the tradeoff is fine for now
 *  other options:
 *  1. use a postgres procedure and use a .rpc() call but then it slows us down when we want to change the logic
 *  2. whenever we move this service to a backend api we can change this to a single query by using prisma or kysely typesafe query builder
 */
export const getUnassignedDevices = async ({ orgID }: { orgID: string }) => {
  const supabase = getWillieSupabaseClient()
  let alreadyMappedDevices: string[] = []
  const { data: meterWithDevices, error: error1 } = await supabase
    .from('connected_meter')
    .select('device_id')
    .eq('org_id', orgID)
    .neq('device_id', null)

  if (meterWithDevices && !error1) {
    alreadyMappedDevices = meterWithDevices.map((m) => m.device_id!) //hard cast fine becayse we are filtering out nulls in the query
  }

  const { data: devices, error } = await supabase
    .from('data-lake')
    .select(
      `
    device:device_id,
    device_id.count()
    `
    )
    .eq('org_id', orgID)
    .not('device_id', 'in', `(${alreadyMappedDevices.join(',')})`)

  if (error) {
    const message = `Error fetching orphan devices`
    console.error(message, error)
    throw new Error(message)
  }

  return devices.map((d) => d.device)
}
