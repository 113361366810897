import { Text, CheckboxCards, Flex, FlexProps } from '@radix-ui/themes'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { WaterStation, WaterStationType } from '~/features/mapping/schemas'
import { useTranslation } from 'react-i18next'

// TODO offer a popover version of this

type StationsPickerProps = {
  stations: WaterStation[]
  defaultValue: string[]
  onValueChange: (value: string[]) => void
  stationTypeFilter?: WaterStationType[]
  showLabel?: boolean
} & FlexProps

const StationsPicker = ({
  stations,
  defaultValue,
  onValueChange,
  stationTypeFilter = ['discharge', 'entry', 'usage'],
  showLabel = true,
  ...flexProps
}: StationsPickerProps) => {
  const { t } = useTranslation('sites')

  const makeStationGroup = (type: WaterStationType) =>
    _(stations)
      .filter((station) => station.station_type === type)
      .orderBy((s) => s.name.toLowerCase())
      .value()

  const groups: Record<WaterStationType, WaterStation[]> = {
    entry: makeStationGroup('entry'),
    usage: makeStationGroup('usage'),
    discharge: makeStationGroup('discharge')
  }

  const [groupSelection, setGroupSelection] = useState<Record<WaterStationType, string[]>>({
    entry: defaultValue.filter((id) => groups['entry'].find((station) => station.id === id)),
    usage: defaultValue.filter((id) => groups['usage'].find((station) => station.id === id)),
    discharge: defaultValue.filter((id) => groups['discharge'].find((station) => station.id === id))
  })

  const handleCheckboxChange = (type: WaterStationType, selected: string[]) => {
    setGroupSelection((prev) => ({
      ...prev,
      [type]: selected
    }))
  }

  useEffect(() => onValueChange(Object.values(groupSelection).flat()), [groupSelection])

  return (
    <Flex {...flexProps} gap="5" mt="4">
      {Object.entries(groups)
        .filter(([type]) => stationTypeFilter.includes(type as WaterStationType))
        .map(([type, stations]) => (
          <Flex gap="2" direction="column" flexGrow={'1'} key={type}>
            {showLabel && (
              <Text size="2" weight={'bold'} color="gray">
                {t(`stations.types.${type}`, { count: 10 })}
              </Text>
            )}
            <CheckboxCards.Root
              color="mint"
              variant="surface"
              defaultValue={groupSelection[type as WaterStationType]}
              onValueChange={handleCheckboxChange.bind(null, type as WaterStationType)}
            >
              {stations.map((station) => (
                <CheckboxCards.Item value={station.id} key={station.id}>
                  <Flex direction="column" width="100%">
                    <Text>{station.name}</Text>
                    <Text color="gray" size={'1'}>
                      {station.label}
                    </Text>
                  </Flex>
                </CheckboxCards.Item>
              ))}
            </CheckboxCards.Root>
          </Flex>
        ))}
    </Flex>
  )
}

export { StationsPicker }
