import _ from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { WaterStation } from '~/features/mapping/schemas'
import { listDataPointsForMeters } from '~/features/meters/service'
import { DateRange } from '~/types'
import { DateTime } from 'luxon'
import { getStationsWithMeters } from '../utils'
import { L_TO_M3 } from '~/constants'

export const NULL_CATEGORY = 'null'
// TODO account for resolution, currently always daily

type LabelMap = Record<string, string>

// should this be suspense or not, do we want to expose it as a choice ?
// should we expose react-query options like staleTime, onSuccess, onError, etc ?

export type StationChartDataProps = {
  title: string
  stations: WaterStation[]
  /** the property on which we group the chart data, if null everything will be combined in a single category */
  partitionKey: keyof WaterStation | null
  /** use this to customize the data query cache key */
  chartKey?: string
  partitionsOptions?: { key: string; label: string; color?: string }[]
  // resolution: 'hour' | 'day' | 'week' | 'month'
} & Partial<DateRange>

const makeEmptyDataForDateRange = ({ startDate, endDate }: DateRange): Record<string, {}> => {
  const diff = endDate.diff(startDate, 'days').days
  const dates = Array.from({ length: diff }, (_, i) => startDate.plus({ days: i }))
  return dates.reduce<Record<string, {}>>(
    (acc, dateTime) => ({
      ...acc,
      [dateTime.toISODate()!]: {}
    }),
    {}
  )
}

const getPartitionLabel = ({
  station,
  partitionKey,
  partitionLabelMap: labelMap = {}
}: Pick<StationChartDataProps, 'partitionKey'> & { station: WaterStation; partitionLabelMap: LabelMap }) => {
  if (!partitionKey) {
    return labelMap[NULL_CATEGORY] ?? NULL_CATEGORY
  }
  return `${_.get(labelMap, `${station[partitionKey]}`, station[partitionKey])}`
}

const useStationsChartData = ({
  title,
  stations,
  partitionKey: partitionKey,
  chartKey = stations.map((s) => s.id).join(','),
  startDate = DateTime.now().minus({ days: 7 }),
  endDate = DateTime.now(),
  partitionsOptions = []
}: StationChartDataProps) => {
  const stationsWithMeters = getStationsWithMeters(stations)
  const meterIds = stationsWithMeters.map((s) => s.meter_id)

  const { data: metersData, isLoading } = useQuery({
    queryKey: [chartKey, { startDate: startDate.toISODate(), endDate: endDate.toISODate() }],
    queryFn: () => listDataPointsForMeters({ meterIds, startDate, endDate })
  })

  const labelMap = _(partitionsOptions)
    .concat([{ key: NULL_CATEGORY, label: title }])
    .groupBy('key')
    .mapValues((d) => d[0].label)
    .value()

  const chartData = useMemo(() => {
    if (!metersData) {
      return null
    }

    const emptyDataSetForRange = makeEmptyDataForDateRange({ startDate, endDate })

    const stationData = metersData
      .map((m) => {
        const station = stationsWithMeters.find((s) => s.meter_id === m.id)
        if (!station) {
          return []
        }

        return _(m.datapoints)
          .groupBy((d) => d.dateTime.toISODate())
          .mapValues((points) => {
            const value =
              m.reading_method === 'incremental'
                ? _.sumBy(points, 'value')
                : (_.first(points)?.value ?? 0) - (_.last(points)?.value ?? 0)

            const conversion = m.unit === 'l' ? value * L_TO_M3 : value
            return conversion
          })
          .toPairs()
          .map(([date, value]) => ({
            date,
            value,
            label: getPartitionLabel({ station, partitionKey, partitionLabelMap: labelMap })
          }))
          .value()
      })
      .flat()

    // We neeed to return an empty array in order for the empty state to show
    if (!stationData.length) {
      return []
    }

    const d = _(stationData)
      .groupBy('date')
      .mapValues(
        (d) =>
          _(d)
            .groupBy('label')
            .mapValues((d) => _.round(_.sumBy(d, 'value'), 3))
            .value() satisfies Record<string, number>
      )
      .mergeWith(emptyDataSetForRange, (dest, empty) => dest || empty)
      .toPairs()
      .orderBy(0)
      .map(([date, value]) => ({
        isoDate: date,
        date: DateTime.fromISO(date).toLocaleString({ month: 'short', day: 'numeric' }),
        ...value
      }))
      .value()

    return d
  }, [metersData])

  const categories = useMemo(() => {
    if (partitionsOptions.length > 0) {
      return partitionsOptions.map((option) => option.label)
    } else {
      return _(stations)
        .map((station) => getPartitionLabel({ station, partitionKey: partitionKey, partitionLabelMap: labelMap }))
        .uniq()
        .value()
    }
  }, [stations, partitionKey])

  return { chartData, isLoading, categories }
}

export { useStationsChartData }
