import { Flex, Heading, Container, ScrollArea } from '@radix-ui/themes'
import cn from 'classnames'
import { useThemeAppearance } from '~/features/layout/hooks'

interface Props {
  title: string
  subtitleElement?: React.ReactNode
  primaryAction?: React.ReactNode
  children: React.ReactNode
  className?: string
}

const ActionablePageLayout = ({ title, primaryAction, children, subtitleElement = null }: Props) => {
  const [theme] = useThemeAppearance()

  return (
    <Flex direction={'column'} height={'100%'} flexGrow={'1'}>
      <Flex
        align={'center'}
        width="full"
        className={cn('bg-[var(--gray-1)] border-b-[1px] border-[var(--gray-4)] h-[90px]', {
          'bg-[var(--gray-2)]': theme === 'dark',
          'bg-[var(--gray-1)]': theme === 'light'
        })}
      >
        <Container px={'6'}>
          <Flex align={'center'} justify={'between'}>
            <Flex width={'50%'} direction={'column'} gap={'1'}>
              <Heading size={'6'}>{title}</Heading>
              {subtitleElement}
            </Flex>
            {primaryAction}
          </Flex>
        </Container>
      </Flex>
      <ScrollArea asChild>
        <Container flexGrow={'1'} p={'4'} className="h-[calc(100vh-90px)]" height={'100%'}>
          {children}
        </Container>
      </ScrollArea>
    </Flex>
  )
}

export { ActionablePageLayout }
