import { Button, ButtonProps } from '@radix-ui/themes'
import { Link, LinkProps } from '@tanstack/react-router'

export interface LinkButtonProps
  extends Pick<LinkProps, 'to' | 'params' | 'search'>,
    Omit<ButtonProps, 'children'> {
  text: string
  leadingIcon?: React.ReactNode
  trailingIcon?: React.ReactNode
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  text,
  leadingIcon,
  trailingIcon,
  to,
  params,
  search,
  className,
  // Extract remaining props for Button
  ...buttonProps
}) => {
  return (
    <Link to={to} params={params} search={search} className={className}>
      <Button variant="ghost" {...buttonProps} className="flex items-center gap-1">
        {leadingIcon && <span>{leadingIcon}</span>}
        {text}
        {trailingIcon && <span>{trailingIcon}</span>}
      </Button>
    </Link>
  )
}

export default LinkButton
