import { createFileRoute, Link } from '@tanstack/react-router'
import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { getSite } from '~/features/sites/service'
import { Box, Button, Flex, Separator, Text } from '@radix-ui/themes'
import {
  addConnection,
  listSiteConnections,
  listSiteWaterStations,
  removeConnection
} from '~/features/mapping/service'
import { MapLayoutCanvas } from '~/features/mapping/components/MapLayoutCanvas'
import { MdOutlineWifiTethering, MdOutlineWifiTetheringOff } from 'react-icons/md'
import { SectionCard } from '~/components/SectionCard'
import { EmptyState } from '~/components/EmptyState'
import { useTranslation } from 'react-i18next'

const Component = () => {
  const { t } = useTranslation(['sites'])
  const { queryClient } = Route.useRouteContext()
  const { siteID } = Route.useParams()

  const { data: site } = useSuspenseQuery({
    queryKey: ['site', siteID],
    queryFn: () => getSite({ siteId: siteID })
  })

  const { data: waterStations } = useSuspenseQuery({
    queryKey: ['stations', siteID],
    queryFn: () => listSiteWaterStations({ siteId: siteID })
  })

  const { data: mapping } = useSuspenseQuery({
    queryKey: ['mapping', siteID],
    queryFn: () => listSiteConnections({ siteId: siteID })
  })

  const { mutate: connectStations } = useMutation({
    mutationFn: (props: Pick<Parameters<typeof addConnection>['0'], 'source' | 'target'>) =>
      addConnection({ ...props, siteID }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mapping', siteID] })
    }
  })
  const { mutate: disconnectStations } = useMutation({
    mutationFn: (connectionId: string) => removeConnection({ connectionId, siteID }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mapping', siteID] })
    }
  })

  return (
    <SectionCard title={t('stations.mapping.title')} height={'100%'}>
      <Separator size="4" my="2" />
      {waterStations.length === 0 ? (
        <Flex
          justify={'center'}
          align={'center'}
          height={'100%'}
          width={'500px'}
          mx={'auto'}
          gap={'4'}
          direction={'column'}
          my={'-8'}
        >
          <EmptyState
            title={t('stations.mapping.emptyState.title')}
            description={t('stations.mapping.emptyState.description')}
            afterBody={
              <Link to={'/sites/$siteID/config/stations/new'} params={{ siteID }}>
                <Button size={'2'}>{t('stations.mapping.emptyState.addStation')}</Button>
              </Link>
            }
          />
        </Flex>
      ) : (
        <>
          <Flex justify={'between'} pt="1">
            <Box className="xl:w-[40%] lg:w-[50%]">
              <Text size={'2'} as="p" mb={'1'} color="gray">
                {t('stations.mapping.description', { siteName: site.name })}
              </Text>
            </Box>
            <Flex direction={'column'}>
              <Flex gap={'2'} align={'center'}>
                <MdOutlineWifiTetheringOff size={22} color="var(--gray-9)" />
                <Text size={'1'} color="gray">
                  {t('stations.mapping.legend.noMeter')}
                </Text>
              </Flex>
              <Flex gap={'2'} align={'center'}>
                <MdOutlineWifiTethering size={22} color="var(--amber-9)" />
                <Text size={'1'} color="gray">
                  {t('stations.mapping.legend.meterNoData')}
                </Text>
              </Flex>
              <Flex gap={'2'} align={'center'}>
                <MdOutlineWifiTethering size={22} color="var(--grass-8)" />
                <Text size={'1'} color="gray">
                  {t('stations.mapping.legend.meterWithData')}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Box flexGrow={'1'}>
            <MapLayoutCanvas
              stations={waterStations}
              handleConnect={connectStations}
              handleDisconnect={disconnectStations}
              connections={mapping || []}
            />
          </Box>
        </>
      )}
    </SectionCard>
  )
}

export const Route = createFileRoute('/sites/$siteID/config/stations/')({
  component: Component
})
