import { createFileRoute, redirect } from '@tanstack/react-router'

export const Route = createFileRoute('/sites/$siteID/')({
  beforeLoad: (ctx) => {
    throw redirect({
      to: '/sites/$siteID/dashboard',
      params: { siteID: ctx.params.siteID }
    })
  }
})
