import { useQuery } from '@tanstack/react-query'
import { Callout, Flex, Spinner, Text } from '@radix-ui/themes'
import { SectionCard } from '~/components/SectionCard'
import { getMeterById } from '~/features/meters/service'
import { EmptyState } from '~/components/EmptyState'
import { useTranslation } from 'react-i18next'
import { WaterStation } from '~/features/mapping/schemas'
import { HiOutlineInformationCircle } from 'react-icons/hi2'
import { useState } from 'react'
import { StationLeakChart } from '~/features/mapping/components/StationLeakChart'
import { StationsConsumptionChart } from '~/features/dashboarding/components/StationsConsumptionChart'
import { PeriodSelector, PeriodSelection, useDefaultDateRange } from '~/components/PeriodSelector'
import { DateRange } from '~/types'

type ConsumptionAndLeaksSectionProps = {
  station: WaterStation
  secondaryActions?: React.ReactNode
}

const ConsumptionAndLeaksSection = ({ station, secondaryActions = null }: ConsumptionAndLeaksSectionProps) => {
  const { t } = useTranslation('sites')

  // Simple local state for period and date range
  const [periodSelection, setPeriodSelection] = useState<PeriodSelection>('week')
  const [dateRange, setDateRange] = useState<DateRange>(useDefaultDateRange('week'))

  const handlePeriodChange = (newDateRange: DateRange, newPeriod: PeriodSelection) => {
    setDateRange(newDateRange)
    setPeriodSelection(newPeriod)
  }

  const { data: meter, isPending: meterPending } = useQuery({
    queryKey: ['meter', station.meter_id],
    queryFn: () => {
      if (!station.meter_id) return null
      return getMeterById({ meterId: station.meter_id })
    }
  })

  const periodSelector = (
    <PeriodSelector periodSelection={periodSelection} dateRange={dateRange} onPeriodChange={handlePeriodChange} />
  )

  if (meterPending) {
    return (
      <Flex align={'center'} justify={'center'} className="min-h-32">
        <Spinner />
      </Flex>
    )
  }

  if (!meter) {
    return (
      <Flex align={'center'} justify={'center'} className="min-h-32">
        <EmptyState
          title={t('stations.leakSection.messages.noMeter')}
          description={t('stations.leakSection.messages.noMeterDescription')}
        />
      </Flex>
    )
  }

  return (
    <SectionCard
      title={t('stations.leakSection.title')}
      secondaryActions={
        <Flex gap="3">
          {periodSelector}
          {secondaryActions}
        </Flex>
      }
    >
      <Flex gap="4" direction="column" py="4">
        {meter.maker !== 'shayp' && (
          <Callout.Root>
            <Callout.Icon>
              <HiOutlineInformationCircle size={18} />
            </Callout.Icon>
            <Callout.Text>
              <Text as="p">{t('stations.leakSection.messages.notShaypWarning')}</Text>
            </Callout.Text>
          </Callout.Root>
        )}

        {meter.maker === 'shayp' ? (
          <StationLeakChart
            station={station}
            meter={meter}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        ) : (
          <StationsConsumptionChart
            title=""
            yAxisLabel={t('stations.leakSection.chart.yAxisLabel')}
            partitionKey={null}
            showGridLines={false}
            chartType="area"
            stations={[station]}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
          />
        )}
      </Flex>
    </SectionCard>
  )
}

export { ConsumptionAndLeaksSection }
