import { Flex, Text, Tooltip } from '@radix-ui/themes'
import { Handle, Position, useConnection, Node } from '@xyflow/react'
import { MdOutlineWifiTethering, MdOutlineWifiTetheringOff } from 'react-icons/md'
import cn from 'classnames'
import { WaterStation, WaterStationType } from '../schemas'
import { StationPreviewPopover } from './StationPreviewPopover'
import { useTranslation } from 'react-i18next'

type BaseStationNodeProps = {
  data: { station: WaterStation }
  primaryColor: string
  className?: string
}

const BaseStationNode = ({ data: { station }, primaryColor, className }: BaseStationNodeProps) => {
  const connection = useConnection()
  const { t } = useTranslation('sites')

  const isConnectingOutPort = connection.inProgress && connection.fromNode.id === station.id
  const isConnectingInPort = connection.inProgress && connection.toNode?.id === station.id

  return (
    <StationPreviewPopover station={station}>
      <Flex
        py={'2'}
        px="4"
        align={'center'}
        className={cn(`border-2 border-${primaryColor}-400 rounded-full bg-white dark:bg-gray-900`, className)}
        gap={'3'}
      >
        <Flex direction={'column'}>
          <Text size={'2'}>{station.name}</Text>
          {station?.label !== '-' && <Text size={'1'}>{station.label}</Text>}
        </Flex>
        {station.meter_id ? (
          <MdOutlineWifiTethering size={13} color="var(--grass-8)" />
        ) : (
          <Tooltip content={t('stations.preview.noMeterAttached')}>
            <MdOutlineWifiTetheringOff size={13} color="var(--gray-9)" />
          </Tooltip>
        )}
        {(['usage', 'discharge'] as WaterStationType[]).includes(station.station_type) && (
          <Handle
            className={cn(`bg-${primaryColor}-500 ml-[-5px] w-2 h-2 transition-all duration-150`, {
              ['w-4 h-4']: isConnectingInPort
            })}
            type="target"
            position={Position.Left}
            id="in"
            // this means we can connect to this port but not from it
            isConnectable={connection.inProgress}
          />
        )}
        {(['usage', 'entry'] as WaterStationType[]).includes(station.station_type) && (
          <Handle
            className={cn(
              `bg-${primaryColor}-500 ml-[-5px] w-2 h-2 transition-all duration-150 hover:w-4 hover:h-4`,
              {
                ['w-4 h-4']: isConnectingOutPort
              }
            )}
            type="source"
            position={Position.Right}
            id="out"
            isConnectable={true}
          />
        )}
      </Flex>
    </StationPreviewPopover>
  )
}

type StationNodeProps = {
  station: WaterStation
}

type StationNode = Node<StationNodeProps>

const EntryNode = ({ data }: { data: StationNode['data'] }) => {
  return <BaseStationNode data={data} primaryColor="sky" className="!rounded-full" />
}

const UsageStationNode = ({ data }: { data: StationNode['data'] }) => {
  return <BaseStationNode data={data} primaryColor="gray" />
}

const DischargeNode = ({ data }: { data: StationNode['data'] }) => {
  return <BaseStationNode data={data} primaryColor="gray" />
}

export const CanvasNodes = {
  source: EntryNode,
  station: UsageStationNode,
  discharge: DischargeNode
}

export type CanvasNodeTypes = {
  source: StationNode
  station: StationNode
  discharge: StationNode
}
