import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  chartType,
  ChartType,
  createMetricSchema,
  CreateMetricSchema,
  MetricSchema,
  period,
  metricSchema as updateMetricSchema
} from '~/features/dashboarding/schemas'
import { Flex, TextField, Text, Select, Separator, Button, TextArea, Switch, Tooltip } from '@radix-ui/themes'
import { LuChartArea as LuAreaChart, LuChartColumnBig as LuBarChart, LuTable } from 'react-icons/lu'
import { ArrowLeftIcon, InfoCircledIcon } from '@radix-ui/react-icons'
import { StationsConsumptionChart } from '~/features/dashboarding/components/StationsConsumptionChart'
import { StationsPicker } from '~/features/dashboarding/components/StationPicker'
import { SectionCard } from '~/components/SectionCard'
import { SiteWithStations } from '~/features/sites/service'
import { Link } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

const ChartTypeIcons = {
  area: <LuAreaChart size={'18'} />,
  bar: <LuBarChart size={'18'} />,
  table: <LuTable size={'18'} />
}

type CommonMetricFormProps = {
  isPending: boolean
  orgID: string
  site: SiteWithStations
}

type MetricFormProps = CommonMetricFormProps &
  (CreateMetricFormProps | UpdateMetricFormProps) & {
    title: string
  }

const MetricForm = ({ metric, onSubmit, isPending, site, orgID, title }: MetricFormProps) => {
  const { t } = useTranslation('sites')
  const schema = metric ? updateMetricSchema : createMetricSchema

  const {
    register,
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    watch
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: metric || {
      siteID: site.id,
      orgID,
      name: '',
      chartType: 'area' as ChartType,
      description: '',
      stationOperations: [] as MetricSchema['stationOperations'],
      summaryCardConfig: {
        schemaVersion: 1,
        showOnSiteSummary: false,
        period: 'month'
      }
    },
    mode: 'onChange'
  })

  const stationOperations = watch('stationOperations')
  const selectedChart = watch('chartType')
  const showOnSiteSummary = watch('summaryCardConfig.showOnSiteSummary')

  return (
    <Flex gap={'4'} direction={'column'}>
      <SectionCard
        title={title}
        secondaryActions={
          <Link to={`/sites/$siteID/config/metrics`} params={{ siteID: site.id }}>
            <Button variant={'ghost'}>
              <ArrowLeftIcon /> {t('metrics.form.backToMetrics')}
            </Button>
          </Link>
        }
      >
        <Separator size={'4'} my={'2'} />
        <Text size={'4'}>{t('metrics.form.sections.settings')}</Text>
        <Text size="2" color="gray">
          {t('metrics.form.sections.settingsDescription')}
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Flex gap="6" direction="column" mt={'4'}>
            <Flex gap={'5'}>
              <Flex direction={'column'} gap="4" flexGrow={'1'}>
                <Flex direction={'column'} gap="1">
                  <Text as="label" htmlFor="name" size="2" weight={'medium'}>
                    {t('metrics.form.fields.name.label')}
                  </Text>
                  <TextField.Root
                    id="name"
                    variant="surface"
                    placeholder={t('metrics.form.fields.name.placeholder')}
                    {...register('name')}
                  />
                </Flex>
                <Flex gap="1" direction={'column'} flexGrow={'1'}>
                  <Text size="2" weight={'medium'}>
                    {t('metrics.form.fields.description.label')}
                  </Text>
                  <TextArea
                    rows={6}
                    variant="surface"
                    placeholder={t('metrics.form.fields.description.placeholder')}
                    {...register('description', { required: false })}
                  />
                </Flex>
              </Flex>

              <Flex direction={'column'} gap="4" flexGrow={'1'} maxWidth={'50%'}>
                <Flex gap="1" direction={'column'}>
                  <Text size="2" as="p" weight={'medium'}>
                    {t('metrics.form.fields.chartType.label')}
                  </Text>
                  <Controller
                    control={control}
                    name="chartType"
                    render={({ field }) => (
                      <Select.Root {...field} onValueChange={(v) => field.onChange(v)}>
                        <Select.Trigger />
                        <Select.Content variant="soft" position="item-aligned">
                          {chartType.options.map((option) => (
                            <Select.Item value={option} key={option} disabled={option === 'table'}>
                              <Flex gap="2" align={'center'}>
                                {ChartTypeIcons[option]}
                                {t(`metrics.form.fields.chartType.options.${option}`)}
                              </Flex>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </Flex>
                <Text size="2" as="label" weight={'medium'} mt={'1'}>
                  <Flex gap="2" align={'center'}>
                    <Switch
                      defaultChecked={metric?.summaryCardConfig.showOnSiteSummary ?? false}
                      onCheckedChange={(v) =>
                        setValue('summaryCardConfig.showOnSiteSummary', v, {
                          shouldValidate: true,
                          shouldDirty: true
                        })
                      }
                    />
                    {t('metrics.form.fields.showOnSiteSummary.label')}
                    <Tooltip content={t('metrics.form.fields.showOnSiteSummary.tooltip')}>
                      <InfoCircledIcon className="color-gray-7" />
                    </Tooltip>
                  </Flex>
                </Text>
                <Flex gap="1" direction={'column'}>
                  <Text size="2" as="p" weight={'medium'} color={!showOnSiteSummary ? 'gray' : undefined}>
                    {t('metrics.form.fields.period.label')}
                  </Text>
                  <Text size="2" as="p" color={'gray'}>
                    {t('metrics.form.fields.period.description')}
                  </Text>
                  <Controller
                    control={control}
                    name="summaryCardConfig.period"
                    render={({ field }) => (
                      <Select.Root
                        {...field}
                        onValueChange={(v) => field.onChange(v)}
                        disabled={!showOnSiteSummary}
                      >
                        <Select.Trigger />
                        <Select.Content variant="soft" position="item-aligned">
                          {period.options.map((option) => (
                            <Select.Item value={option} key={option}>
                              <Flex gap="2" align={'center'}>
                                {t(`metrics.form.fields.period.options.${option}`)}
                              </Flex>
                            </Select.Item>
                          ))}
                        </Select.Content>
                      </Select.Root>
                    )}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex gap="1" direction={'column'}>
              <Text size="2" weight={'medium'}>
                {t('metrics.form.fields.stations.label')}
              </Text>
              <Text size="2" color="gray">
                {t('metrics.form.fields.stations.description')}
              </Text>
              <StationsPicker
                stations={site.stations}
                defaultValue={stationOperations.map((op) => op.stationId)}
                onValueChange={(v) => {
                  setValue(
                    'stationOperations',
                    v.map((id) => ({ stationId: id, operation: 'add' })),
                    { shouldValidate: true, shouldDirty: true }
                  )
                }}
              />
            </Flex>
            <Button
              type="submit"
              size={'3'}
              loading={isPending}
              disabled={!isValid || !isDirty}
              className="ml-auto"
            >
              {metric ? t('metrics.form.buttons.update') : t('metrics.form.buttons.create')}
            </Button>
          </Flex>
        </form>
        <Separator size={'4'} my={'4'} />
        <Flex direction={'column'} gap={'4'}>
          <Text size={'4'}>{t('metrics.form.sections.preview')}</Text>
          <StationsConsumptionChart
            title=""
            partitionKey={null}
            stations={stationOperations
              .map((op) => site.stations.find((s) => s.id === op.stationId))
              .filter((s) => !!s)}
            chartType={selectedChart}
            showLegend={false}
            showYAxis
            className="h-80"
          />
        </Flex>
      </SectionCard>
    </Flex>
  )
}

type CreateMetricFormProps = {
  metric: null
  onSubmit: (data: CreateMetricSchema) => void
}

export const CreateMetricForm = (props: CreateMetricFormProps & CommonMetricFormProps) => {
  const { t } = useTranslation('sites')
  const title = t('metrics.form.title.create', { siteName: props.site.name })
  return <MetricForm title={title} {...props} />
}

type UpdateMetricFormProps = {
  metric: MetricSchema
  onSubmit: (data: MetricSchema) => void
}

export const UpdateMetricForm = (props: UpdateMetricFormProps & CommonMetricFormProps) => {
  const title = props.metric.name
  return <MetricForm title={title} {...props} />
}
