import { makeSupabaseClient, WillieSupabaseClient } from '@willie/supabase'

let client: WillieSupabaseClient | null = null

export const getWillieSupabaseClient = () => {
  if (!client) {
    client = makeSupabaseClient({
      supabaseUrl: import.meta.env.VITE_SUPABASE_URL,
      projectId: import.meta.env.VITE_SUPABASE_PROJECT,
      anonKey: import.meta.env.VITE_SUPABASE_ANON_KEY
    })
  }

  return client
}
