import { zodResolver } from '@hookform/resolvers/zod'
import { Button, Callout, Flex, Separator, Text, TextArea, TextField } from '@radix-ui/themes'
import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Suspense } from 'react'
import { useForm } from 'react-hook-form'
import { SectionCard } from '~/components/SectionCard'
import { siteSchema, SiteSchema } from '~/features/sites/schemas'
import { deleteSite, getSite, updateSite } from '~/features/sites/service'
import { toast } from 'sonner'
import { useTranslation } from 'react-i18next'

const SiteOverviewPage = () => {
  const { t } = useTranslation('sites')
  const { siteID } = Route.useParams()
  const { queryClient } = Route.useRouteContext()
  const navigate = Route.useNavigate()

  const { data: site } = useSuspenseQuery({
    queryFn: () => getSite({ siteId: siteID }),
    queryKey: ['site', siteID]
  })

  const {
    register,
    handleSubmit,
    formState: { isValid, isDirty }
  } = useForm<SiteSchema>({
    resolver: zodResolver(siteSchema),
    defaultValues: {
      id: site.id,
      name: site.name,
      description: site.description,
      address: site.address ?? '',
      orgID: site.org_id,
      cost: site.default_cost_per_m3
    },
    mode: 'onChange'
  })

  const { mutate: updateSiteMutation, isPending } = useMutation({
    mutationFn: updateSite,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return queryKey[0] === 'sites' || queryKey[1] === siteID
        }
      })
      toast.success(t('sites.form.messages.updateSuccess'))
    },
    onError: (error) => {
      console.error('Error updating site', error)
      toast.error(t('sites.form.messages.updateError'))
    }
  })

  const { mutate: deleteSiteMutation, isPending: isDeleting } = useMutation({
    mutationFn: () => deleteSite(siteID),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['sites'] })
      navigate({ to: '/sites' })
      toast.success(t('sites.form.messages.deleteSuccess'))
    },
    onError: (error) => {
      console.error('Error deleting site', error)
      toast.error(t('sites.form.messages.deleteError'))
    }
  })

  const handleDeleteClick = () => {
    if (window.confirm(t('sites.overview.dangerZone.confirmDelete'))) {
      deleteSiteMutation()
    }
  }

  const onSubmit = (data: SiteSchema) => updateSiteMutation(data)

  return (
    <Flex direction="column" gap="4">
      <SectionCard title={t('sites.overview.title')}>
        <Separator size="4" my="2" />
        <Suspense>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex gap="6" direction="column" mt={'4'}>
              <Flex gap={'5'}>
                <Flex direction={'column'} gap="4" flexGrow={'1'}>
                  <Flex direction={'column'} gap="1">
                    <Text as="label" htmlFor="name" size="2" weight={'medium'}>
                      {t('sites.form.fields.name.label')}
                    </Text>
                    <TextField.Root
                      id="name"
                      variant="surface"
                      placeholder={t('sites.form.fields.name.placeholder')}
                      {...register('name')}
                    />
                  </Flex>
                  <Flex direction={'column'} gap="1">
                    <Text as="label" htmlFor="address" size="2" weight={'medium'}>
                      {t('sites.form.fields.address.label')}
                    </Text>
                    <TextField.Root
                      id="address"
                      variant="surface"
                      placeholder={t('sites.form.fields.address.placeholder')}
                      {...register('address')}
                    />
                  </Flex>
                </Flex>

                <Flex direction={'column'} gap="4" flexGrow={'1'} maxWidth={'50%'}>
                  <Flex gap="1" direction={'column'} flexGrow={'1'}>
                    <Text size="2" weight={'medium'}>
                      {t('sites.form.fields.description.label')}
                    </Text>
                    <TextArea
                      rows={6}
                      variant="surface"
                      placeholder={t('sites.form.fields.description.placeholder')}
                      {...register('description', { required: false })}
                    />
                  </Flex>
                </Flex>
              </Flex>
              <Button
                type="submit"
                disabled={!isValid || !isDirty}
                size={'3'}
                loading={isPending}
                className="ml-auto"
              >
                {t('sites.form.buttons.update')}
              </Button>
            </Flex>
          </form>
        </Suspense>
      </SectionCard>
      <SectionCard title={t('sites.overview.dangerZone.title')}>
        <Callout.Root color="red" variant="surface" className="flex items-center justify-between">
          <Callout.Text>{t('sites.overview.dangerZone.deleteWarning')}</Callout.Text>
          <Button variant="soft" onClick={handleDeleteClick} disabled={isDeleting}>
            {t('sites.overview.dangerZone.deleteButton')}
          </Button>
        </Callout.Root>
      </SectionCard>
    </Flex>
  )
}

export const Route = createFileRoute('/sites/$siteID/config/overview')({
  component: SiteOverviewPage
})
