import { createFileRoute } from '@tanstack/react-router'
import { useMutation, useSuspenseQuery } from '@tanstack/react-query'
import { deleteMetric, getMetric, updateMetric } from '~/features/dashboarding/service'
import { Button, Callout, Flex } from '@radix-ui/themes'
import { getSite } from '~/features/sites/service'
import _ from 'lodash'
import { UpdateMetricForm } from '~/features/dashboarding/components/MetricForm'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { SectionCard } from '~/components/SectionCard'

const Component = () => {
  const { queryClient, org } = Route.useRouteContext()
  const { siteID, metricID } = Route.useParams()
  const navigate = Route.useNavigate()
  const { t } = useTranslation('sites')

  const { data: site } = useSuspenseQuery({
    queryKey: ['site', siteID],
    queryFn: () => getSite({ siteId: siteID }),
    refetchOnWindowFocus: false
  })

  const { data: metric } = useSuspenseQuery({
    queryKey: ['metric', metricID],
    queryFn: () => getMetric({ metricID }),
    refetchOnWindowFocus: false
  })

  const { mutate: updateSiteMetric, isPending } = useMutation({
    mutationFn: updateMetric,
    onSuccess: () => {
      toast.success(t('metrics.form.messages.updateSuccess'))
      navigate({
        to: '/sites/$siteID/config/metrics',
        params: { siteID }
      })
      queryClient.invalidateQueries({
        predicate({ queryKey }) {
          return (
            (queryKey[0] === 'site-metrics' && queryKey[1] === siteID) ||
            (queryKey[0] === 'metric' && queryKey[1] === metricID)
          )
        }
      })
    },
    onError: (error) => {
      console.error('Error updating metric', error)
      toast.error(t('metrics.form.messages.updateError'))
    }
  })

  const { mutate: rmMetric, isPending: isDeleting } = useMutation({
    mutationFn: deleteMetric,
    onSuccess: () => {
      // TODO toast metric created
      // Refetch the metri
      queryClient.invalidateQueries({
        queryKey: ['site-metrics', siteID]
      })
      navigate({
        to: '/sites/$siteID/config/metrics',
        params: { siteID }
      })
    }
  })

  const handleDeleteClick = () => {
    window.confirm(t('metrics.dangerZone.confirmDelete')) && rmMetric(metric.id)
  }

  return (
    <Flex gap={'4'} direction={'column'}>
      <UpdateMetricForm
        metric={metric}
        onSubmit={updateSiteMetric}
        isPending={isPending}
        site={site}
        orgID={org.id}
      />
      <SectionCard title={t('stations.dangerZone.title')}>
        <Callout.Root color="red" variant="surface" className="flex items-center justify-between">
          <Callout.Text>{t('metrics.dangerZone.deleteWarning')}</Callout.Text>
          <Button variant="soft" onClick={handleDeleteClick} disabled={isDeleting}>
            {t('metrics.dangerZone.deleteButton')}
          </Button>
        </Callout.Root>
      </SectionCard>
    </Flex>
  )
}

export const Route = createFileRoute('/sites/$siteID/config/metrics/$metricID')({
  component: Component
})
