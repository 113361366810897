import { QueryClient } from '@tanstack/react-query'

import './i18n'
import { Toaster } from 'sonner'
import { TRPCProvider } from './utils/trpc'
import { useAuth } from './features/auth/AuthProvider'
import AuthUI from './features/auth/AuthUI'
import { OrgProvider } from './features/orgnisations/components/OrgProvider'
import { Router } from './Router'

function App({ queryClient: queryClient }: { queryClient: QueryClient }) {
  const { session } = useAuth()

  if (!session) {
    return <AuthUI />
  }

  return (
    <OrgProvider userSession={session}>
      <TRPCProvider queryClient={queryClient} userSession={session}>
        <Toaster />
        <Router ctx={{ session, queryClient }} />
      </TRPCProvider>
    </OrgProvider>
  )
}

export default App
