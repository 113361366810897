import bytes from 'bytes'
import { Tooltip, Text } from '@radix-ui/themes'
import { HiOutlineArrowDownTray, HiPhoto } from 'react-icons/hi2'
import { Accept, useDropzone } from 'react-dropzone'
import { useState } from 'react'
import { getWillieSupabaseClient } from '~/utils'
import cx from 'classnames'

const MAX_UPLOAD_SIZE = bytes('1MB') as number
const ACCEPTED_FILES: Accept = {
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/svg+xml': ['.svg'],
  'image/png': ['.png'],
  'image/webp': ['.webp']
}

interface Props {
  label?: string
  imageUrl?: string | null
  uploadKey: string
  onUploadSuccess?: (url: string) => void
  onError?: (error: string) => void
  size?: number
}

const ImageUpload = ({ size = 100, ...props }: Props) => {
  const [error, setError] = useState<string>('')
  const [imgUrl, setImgUrl] = useState<string>(props.imageUrl ?? '')

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    accept: ACCEPTED_FILES,
    maxSize: MAX_UPLOAD_SIZE,
    onDropRejected: (rejectedFiles) => {
      setError(`${rejectedFiles.map((f) => f.file.name).join(', ')} not accepted.`)
    },
    onDrop: async ([file]) => {
      if (!file) {
        return
      }
      setImgUrl(URL.createObjectURL(file))
      const supabase = getWillieSupabaseClient() // TODO: move to utils ?
      const { data, error } = await supabase.storage
        .from('media')
        .upload(props.uploadKey, file, { cacheControl: '10', upsert: true })
      if (error) {
        console.error('Error uploading file', error)
        setError('Error uploading file')
        return
      }

      const {
        data: { publicUrl }
      } = supabase.storage.from('media').getPublicUrl(data.path)

      setImgUrl(publicUrl + '?version=' + Date.now())
      props.onUploadSuccess?.(publicUrl)
    }
  })

  return (
    <div
      {...getRootProps()}
      className={cx(`p-3 border-2 border-dashed rounded-md w-fit`, {
        ['border-gray-5']: isDragActive,
        ['border-transparent']: !isDragActive
      })}
    >
      <Tooltip content={"Drag 'n' drop an image, or click to select file"}>
        <div
          className="bg-gray-3 rounded-full cursor-pointer flex items-center justify-center"
          style={{ width: size, height: size }}
        >
          <input {...getInputProps()} />
          {isDragActive && <HiOutlineArrowDownTray size={size / 2} color="var(--gray-7)" />}
          {!isDragActive && !imgUrl && <HiPhoto size={size / 2} color="var(--gray-8)" />}
          {!isDragActive && imgUrl && <img width={size} src={imgUrl} alt="icon" />}
        </div>
      </Tooltip>
      {error && (
        <Text size={'1'} color="red">
          {error}
        </Text>
      )}
    </div>
  )
}

export { ImageUpload }
