import { DateRange } from '~/types'
import { PhysicalMeterSchema, WaterStation } from '../schemas'
import { listLeakageDataPointsForMeter } from '~/features/meters/service'
import { L_TO_M3 } from '~/constants'
import { DateTime } from 'luxon'
import { Box, Callout, Flex, Skeleton, Spinner, Strong, Text } from '@radix-ui/themes'
import { AreaChart } from '@tremor/react'
import _ from 'lodash'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { GoSquareFill } from 'react-icons/go'
import { ExclamationTriangleIcon } from '@radix-ui/react-icons'

type LeakChartProps = {
  station: WaterStation
  meter: PhysicalMeterSchema
} & DateRange

type LeakageChartDataPoint = {
  date: string
  consumption: number
  leak: number
}

const StationLeakChart = ({ station, ...props }: LeakChartProps) => {
  const { t } = useTranslation('sites')
  const { data: leakDataPoints = [], isPending: leakDataPointsPending } = useQuery({
    queryKey: [
      'leak-data-points',
      station.id,
      { startDate: props.startDate.toISO(), endDate: props.endDate.toISO() }
    ],
    queryFn: () => listLeakageDataPointsForMeter(props)
  })

  const data = _(leakDataPoints)
    .groupBy((d) => d.dateTime.toISODate())
    .mapValues((points) => {
      // we know we need to sum because shayp are always incremental
      // we know we need to multiply by L_TO_M3 because shayp is in liters
      const consumption = _.round(_.sumBy(points, 'consumption') * L_TO_M3, 2)
      const leak = _.round(_.sumBy(points, 'leak') * L_TO_M3, 2)
      return { consumption, leak } satisfies Omit<LeakageChartDataPoint, 'date'>
    })
    .toPairs()
    .orderBy('0')
    .value()

  const chartData = data.map(([date, { consumption, leak }]) => ({
    date: DateTime.fromISO(date).toLocaleString({ month: 'short', day: 'numeric' }),
    [t('stations.leakSection.chart.consumption')]: consumption,
    [t('stations.leakSection.chart.leak')]: leak
  }))

  const consolidatedOverPeriod = {
    consumption: _.sumBy(data, '1.consumption'),
    leak: _.sumBy(data, '1.leak')
  }

  const diffPercent = (consolidatedOverPeriod.leak / consolidatedOverPeriod.consumption) * 100

  const formatVignetteDate = (date: DateTime) =>
    date.toLocaleString({ month: 'short', day: 'numeric', year: 'numeric' })

  return (
    <Flex direction={'column'} gap="5">
      {_.isNumber(diffPercent) && diffPercent >= 7 && (
        <Callout.Root color="amber" className="mb-4">
          <Callout.Icon>
            <ExclamationTriangleIcon />
          </Callout.Icon>
          <Callout.Text>
            {t('stations.leakSection.chart.leakDetected')} <Strong>{Math.round(diffPercent)} %</Strong>{' '}
            {t('stations.leakSection.chart.lossPercentage')} . {t('stations.leakSection.chart.leakFix')}
          </Callout.Text>
        </Callout.Root>
      )}
      <Flex align={'start'} justify={'between'} py="3" px="6">
        {leakDataPointsPending ? (
          <>
            {_.range(4).map((i) => (
              <Box key={i}>
                <Skeleton>
                  <Text size={'3'} as="div">
                    Lorem comsumption
                  </Text>
                </Skeleton>
                <Skeleton>
                  <Text size={'5'} weight={'bold'}>
                    some m3
                  </Text>
                </Skeleton>
              </Box>
            ))}
          </>
        ) : (
          <>
            <Box>
              <Flex align={'end'} gap={'1'} mb="1">
                <Text size={'3'} weight={'medium'}>
                  {t('stations.leakSection.chart.consumptionHeader')}
                </Text>
                <GoSquareFill className="fill-sky-400" size={22} />
              </Flex>
              <Text size={'1'} color="gray" as="p">
                {formatVignetteDate(props.startDate)} {t('budget.chart.dateTo')}{' '}
                {formatVignetteDate(props.endDate)}
              </Text>
              <Text size={'5'} weight={'bold'}>
                {consolidatedOverPeriod.consumption.toLocaleString()} m<sup>3</sup>
              </Text>
            </Box>
            <Box>
              <Flex align={'end'} gap={'1'} mb="1">
                <Text size={'3'} weight={'medium'}>
                  {t('stations.leakSection.chart.leakOverPeriodHeader')}
                </Text>
                <GoSquareFill className="fill-red-500" size={22} />
              </Flex>
              <Text size={'1'} color="gray" as="p">
                {formatVignetteDate(props.startDate)} {t('budget.chart.dateTo')}{' '}
                {formatVignetteDate(props.endDate)}
              </Text>
              <Text size={'5'} weight={'bold'}>
                {consolidatedOverPeriod.leak.toLocaleString()} m<sup>3</sup>
              </Text>
            </Box>
            <Box>
              <Flex align={'end'} gap={'1'}>
                <Text size={'3'} weight={'medium'}>
                  {t('stations.leakSection.chart.lostWater')}
                </Text>
              </Flex>
              <Text size={'1'} color="gray" as="p">
                {formatVignetteDate(props.startDate)} {t('budget.chart.dateTo')}{' '}
                {formatVignetteDate(props.endDate)}
              </Text>
              <Flex gap={'2'} align={'center'}>
                <Text size={'5'} weight={'bold'}>
                  {diffPercent === 0 ? '-' : `${Math.round(diffPercent)} %`}
                </Text>
              </Flex>
            </Box>
          </>
        )}
      </Flex>
      {leakDataPointsPending ? (
        <Flex align={'center'} justify={'center'} className="min-h-32">
          <Spinner />
        </Flex>
      ) : (
        <AreaChart
          yAxisLabel={t('stations.leakSection.chart.yAxisLabel')}
          showGridLines={false}
          showGradient={true}
          data={chartData}
          categories={[t('stations.leakSection.chart.consumption'), t('stations.leakSection.chart.leak')]}
          colors={['sky', 'red']}
          index={'date'}
          showLegend={false}
        />
      )}
    </Flex>
  )
}

export { StationLeakChart }
