import { createTRPCContext } from '@trpc/tanstack-react-query'
import { createTRPCClient, httpBatchLink } from '@trpc/client'
import type { AppRouter } from '@willie/trpc-server'
import { QueryClient } from '@tanstack/react-query'
import { PropsWithChildren, useMemo } from 'react'
import type { Session } from '@supabase/supabase-js'
import { useCurrentOrg } from '~/features/orgnisations/hooks/useCurrentOrg'

const { TRPCProvider: TRPCContextProvider, useTRPC, useTRPCClient } = createTRPCContext<AppRouter>()

const getTrpcClient = (authToken: string, orgID: string) => {
  return createTRPCClient<AppRouter>({
    links: [
      httpBatchLink({
        url: import.meta.env.VITE_TRPC_URL ?? '/api/trpc',
        headers() {
          return {
            Authorization: `Bearer ${authToken}`,
            'X-W-OrgID': orgID
          }
        }
      })
    ]
  })
}

type Props = PropsWithChildren<{
  queryClient: QueryClient
  userSession: Session
}>

export const TRPCProvider = ({ queryClient, userSession, children }: Props) => {
  const org = useCurrentOrg()
  const trpcClient = useMemo(() => getTrpcClient(userSession?.access_token ?? '', org.id), [userSession, org])

  return (
    <TRPCContextProvider trpcClient={trpcClient} queryClient={queryClient}>
      {children}
    </TRPCContextProvider>
  )
}

export { useTRPC, useTRPCClient }
