import { Link, useMatchRoute } from '@tanstack/react-router'
import { Flex, Text } from '@radix-ui/themes'
import { VscTriangleRight } from 'react-icons/vsc'

import cn from 'classnames'
import { cloneElement, useEffect, useState } from 'react'
import * as Collapsible from '@radix-ui/react-collapsible'
import { TypedRouteParams, TypeRoutePaths } from '~/types/routes'

export type TypedNavItem<TPath extends TypeRoutePaths> = {
  route?: TPath
  label: string
  subItems?: TypedNavItem<TypeRoutePaths>[]
  isSubItem?: boolean
  params?: TypedRouteParams<TPath>
  icon?: React.ReactNode
}

export const makeSideNavItemProps = <T extends TypeRoutePaths>(
  route: TypedNavItem<T>['route'],
  rest: Omit<TypedNavItem<T>, 'route'>
) =>
  ({
    route,
    ...rest
  }) as TypedNavItem<TypeRoutePaths>

const SideNavItem = <T extends TypeRoutePaths>({
  route,
  label,
  subItems,
  isSubItem = false,
  params: itemParams = {},
  icon = null
}: TypedNavItem<T>) => {
  const matchRoute = useMatchRoute()

  const shouldHighlight = route && !!matchRoute({ to: route, params: itemParams, fuzzy: isSubItem })

  const oneOfSubItemMatch = subItems?.some((subItem) => {
    if (!subItem.route) {
      return false
    }
    return !!matchRoute({ to: subItem.route, params: subItem.params, fuzzy: true })
  })

  const canBeOpen = !!subItems
  const [isOpen, setIsOpen] = useState(oneOfSubItemMatch)

  useEffect(() => {
    if (oneOfSubItemMatch) {
      setIsOpen(true)
    }
  }, [oneOfSubItemMatch])

  const content = (
    <Flex gap={'3'} pr={'4'} maxHeight={'32px'}>
      <div
        className={cn('w-[5px] rounded-lg', {
          ['bg-[var(--willy-accent)]']: shouldHighlight,
          'bg-transparent': !shouldHighlight
        })}
      />
      <Collapsible.Trigger asChild>
        <Flex
          pr={'2'}
          pl={isSubItem ? '6' : '2'}
          py={'1'}
          align={'center'}
          className={cn(
            'cursor-pointer grow rounded-lg group transition-all duration-200 hover:text-[var(--gray-12)] hover:bg-[var(--gray-3)] max-w-[95%]',
            {
              ['bg-[var(--gray-4)] hover:bg-[var(--gray-4)]  text-[var(--gray-12)]']: shouldHighlight,
              ['text-[var(--gray-11)] border-transparent']: !shouldHighlight
            }
          )}
          gap={'2'}
        >
          {icon ? cloneElement(icon as React.ReactElement, { size: 16 }) : <div className="w-1" />}
          <Text className="truncate min-w-0 max-w-[80%]" size={'2'}>
            {label}
          </Text>
          {canBeOpen && (
            <VscTriangleRight
              size={12}
              className={cn('transition-all flex-shrink-0', { 'rotate-90': isOpen, 'rotate-0': !isOpen })}
            />
          )}
        </Flex>
      </Collapsible.Trigger>
    </Flex>
  )

  return (
    <Collapsible.Root open={canBeOpen && isOpen} onOpenChange={(isOpen) => canBeOpen && setIsOpen(isOpen)}>
      {route ? (
        // @ts-ignore
        <Link to={route} params={itemParams}>
          {content}
        </Link>
      ) : (
        content
      )}

      <Collapsible.Content>
        <Flex direction={'column'} pt={'1'} gap={'1'}>
          {subItems?.map((subItem) => <SideNavItem key={`${label}-${subItem.label}`} {...subItem} />)}
        </Flex>
      </Collapsible.Content>
    </Collapsible.Root>
  )
}

export { SideNavItem }
