import { Flex, ScrollArea, Spinner } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'
import { EmptyState } from '~/components/EmptyState'
import { ScrollablePageLayout } from '~/components/ScrollablePageLayout'
import { SectionCard } from '~/components/SectionCard'
import SiteNotification from '~/features/notifications/components/SiteNotification'
import { listSiteNotifications } from '~/features/notifications/service'

const Component = () => {
  const { t } = useTranslation(['sites', 'sidenav'])
  const { siteID } = Route.useParams()

  const { data: siteNotifications = [], isLoading } = useQuery({
    queryKey: ['siteNotifications', siteID],
    queryFn: async () => listSiteNotifications({ siteID })
  })

  return (
    <ScrollablePageLayout title={t('navigation.alerting', { ns: 'sidenav' })}>
      <ScrollArea>
        <SectionCard title="" className="h-full">
          {isLoading ? (
            <div className="flex h-full items-center justify-center">
              <Spinner />
            </div>
          ) : siteNotifications.length === 0 ? (
            <EmptyState
              title={t('alerting.emptyState.title')}
              description={t('alerting.emptyState.description')}
            />
          ) : (
            <Flex direction="column" gap="4">
              {siteNotifications.map((notification) => (
                <SiteNotification key={notification.id} siteNotification={notification} />
              ))}
            </Flex>
          )}
        </SectionCard>
      </ScrollArea>
    </ScrollablePageLayout>
  )
}

export const Route = createFileRoute('/sites/$siteID/alerting')({
  component: Component
})
