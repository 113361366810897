import {
  BudgetItemSchema,
  BudgetItemType,
  CreateBudgetItemSchema,
  historicalInvoiceSchema,
  HistoricalInvoiceSchema,
  objectiveBudgetItemSchema,
  ObjectiveBudgetItemSchema
} from './schemas'
import { getWillieSupabaseClient } from '~/utils'
import { TablesInsert } from '@willie/supabase'

type ListSiteBudgetItemsProps<T extends BudgetItemType> = {
  siteID: string
  type: T
}

type ListSiteBudgetItemReturn<T extends BudgetItemType> = T extends 'historical'
  ? HistoricalInvoiceSchema
  : ObjectiveBudgetItemSchema

export async function listSiteBudgetItems<T extends BudgetItemType>({
  siteID,
  type
}: ListSiteBudgetItemsProps<T>): Promise<ListSiteBudgetItemReturn<T>[]> {
  const supabase = getWillieSupabaseClient()

  const { data, error } = await supabase
    .from('site_budget_items')
    .select('*')
    .eq('site_id', siteID)
    .eq('type', type)

  if (error) {
    throw error
  }

  const schema = type === 'historical' ? historicalInvoiceSchema : objectiveBudgetItemSchema

  const d = data.map(
    (d) =>
      schema.parse({
        id: d.id,
        siteID: d.site_id,
        startDate: d.start_date,
        endDate: d.end_date,
        consumption: d.consumption,
        cost: d.total_cost,
        type: d.type
      }) as ListSiteBudgetItemReturn<T>
  )
  return d
}

export const createBudgetItem = async (data: CreateBudgetItemSchema) => {
  const supabase = getWillieSupabaseClient()

  const payload: TablesInsert<'site_budget_items'> = {
    site_id: data.siteID,
    start_date: data.startDate.toISOString(),
    end_date: data.endDate.toISOString(),
    consumption: data.consumption,
    total_cost: data.cost,
    type: data.type
  }

  const { error } = await supabase.from('site_budget_items').insert([payload])

  if (error) {
    throw error
  }
}

export const updateBudgetItem = async (data: BudgetItemSchema) => {
  const supabase = getWillieSupabaseClient()

  const payload: Partial<TablesInsert<'site_budget_items'>> = {
    ...(data.startDate && { start_date: data.startDate.toISOString() }),
    ...(data.endDate && { end_date: data.endDate.toISOString() }),
    ...(data.consumption && { consumption: data.consumption }),
    ...(data.cost && { total_cost: data.cost })
  }

  const { error } = await supabase.from('site_budget_items').update(payload).eq('id', data.id)

  if (error) {
    throw error
  }
}

export const deleteBudgetItem = async (id: string) => {
  const supabase = getWillieSupabaseClient()

  const { error } = await supabase.from('site_budget_items').delete().eq('id', id)

  if (error) {
    throw error
  }
}
