import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useTranslation } from 'react-i18next'
import { useThemeAppearance } from '../layout/hooks'
import { getWillieSupabaseClient } from '~/utils'

const supabase = getWillieSupabaseClient()

const AuthUI = () => {
  const { t } = useTranslation('auth')
  const [appearance] = useThemeAppearance()

  return (
    <div className="flex h-screen">
      {/* Left side - Branding */}
      <div className="hidden md:flex md:w-1/2 bg-[var(--mint-9)] dark:bg-[var(--mint-4)] text-white flex-col justify-center items-center p-10">
        <div className="flex items-center gap-3">
          <h1 className="text-4xl font-bold">{t('login.meet')}</h1>
          <img src="/logo-typo.png" className="w-28" />
        </div>
        <p className="text-xl text-center">{t('login.tagline')}</p>
        <div className="mt-16">
          <img src="/logo-icon.png" className="w-40" />
        </div>
      </div>

      <div className="w-full md:w-1/2 flex justify-center items-center p-5">
        <div className="w-full max-w-md">
          <h2 className="text-2xl font-bold mb-6 text-center">{t('login.signInTitle')}</h2>
          <Auth
            supabaseClient={supabase}
            showLinks={false}
            appearance={{
              theme: ThemeSupa,
              style: {
                container: { width: '100%' },
                button: {
                  width: '100%',
                  borderRadius: '6px',
                  backgroundColor: appearance === 'dark' ? 'var(--mint-5)' : 'var(--mint-12)',
                  borderColor: appearance === 'dark' ? 'var(--mint-5)' : 'var(--mint-12)'
                },
                input: { borderRadius: '6px' }
              }
            }}
            providers={[]}
          />
        </div>
      </div>
    </div>
  )
}

export default AuthUI
